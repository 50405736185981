import { KeyValuePair, Planung } from "@/models/PlanungModels"

export type State = {
  planung: Array<Planung>,
  palettenProStunde: Array<KeyValuePair>,
}

export const state: State = {
  planung: sessionStorage.getItem("planung") != null && sessionStorage.getItem("planung") !== undefined ? JSON.parse(sessionStorage.planung) : new Array<Planung>(),
  palettenProStunde: sessionStorage.getItem("palettenProStunde") != null && sessionStorage.getItem("palettenProStunde") !== undefined ? JSON.parse(sessionStorage.palettenProStunde) : new Array<KeyValuePair>(),
}

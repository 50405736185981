<template>
  <div class="row mb-3">
    <div class="col me-4">
      <div class="row pe-2" v-for="row in getBlockHA" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
        <div class="col">
          <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}</div>
          <div v-for="platz in row.stellplaetze.slice().reverse()" :key="platz.id">
            <LagerStellplatz :platz="platz" />
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="row" v-for="row in getBlockHC" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
        <div class="col">
          <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
          <div v-for="platz in row.stellplaetze" :key="platz.id">
            <LagerStellplatz :platz="platz" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col me-4">
      <div class="row pe-2" v-for="row in getBlockHB" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
        <div class="col">
          <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}</div>
          <div v-for="platz in row.stellplaetze" :key="platz.id">
            <LagerStellplatz :platz="platz" />
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="row" v-for="row in getBlockHD" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
        <div class="col">
          <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
          <div v-for="platz in row.stellplaetze" :key="platz.id">
            <LagerStellplatz :platz="platz" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from '@/store'
import LagerStellplatz from './LagerStellplatz.vue'
import { Lagerreihe, Listeartikel } from '@/models/LagerModels';

export default {
  name: "HerstellungLagerLayout",
  components: {
    LagerStellplatz,
  },
  
  props: {
    aktualisiertam: {
      type: String,
      required: true
    }
  },

  setup () {
    const store = useStore();
    const lager = computed(() => store.getters.lager);
    const lagerartikel = computed(() => store.getters.lagerartikel);

    const fertigwarenStellplaetze = computed(() => store.getters.fertigwarenStellplaetze)
    const lagerpuffer = computed(() => store.getters.lagerpuffer)
    const produktionspuffer = computed(() => store.getters.produktionspuffer)

    const getBlockHA = computed(() => lager.value.filter((el: Lagerreihe) => el.block == "HA"));
    const getBlockHB = computed(() => lager.value.filter((el: Lagerreihe) => el.block == "HB").reverse());
    const getBlockHC = computed(() => lager.value.filter((el: Lagerreihe) => el.block == "HC"));
    const getBlockHD = computed(() => lager.value.filter((el: Lagerreihe) => el.block == "HD"));

    return {
      store,
      lager,
      lagerartikel,
      fertigwarenStellplaetze,
      lagerpuffer,
      produktionspuffer,
      getBlockHA,
      getBlockHB,
      getBlockHC,
      getBlockHD
    }
  },

  // computed: {
  //   getBlockHA(): Lagerreihe[] {
  //     var test = this.lager.filter((el: Lagerreihe) => el.block == "HA")
  //     return this.lager.filter((el: Lagerreihe) => el.block == "HA")
  //   },
  //   getBlockHB(): Lagerreihe[] {
  //     return this.lager.filter((el: Lagerreihe) => el.block == "HB").reverse()
  //   },
  //   getBlockHC(): Lagerreihe[] {
  //     return this.lager.filter((el: Lagerreihe) => el.block == "HC")
  //   },
  //   getBlockHD(): Lagerreihe[] {
  //     return this.lager.filter((el: Lagerreihe) => el.block == "HD")
  //   },
  // },

  methods: {
    reiheNummer(row: Lagerreihe) {
      return row.reihe < 10 ? `0${row.reihe}` : row.reihe
    },
    getAnzahlPalettenInReihe(row: Lagerreihe) {
      let count = 0
      row.stellplaetze.forEach(el => {
        if (el.artikel != null) {
          count++
        }
      })

      return count < 10 ? `0${count}` : count
    },
  }
};
</script>

<style scoped>
.reihe-name {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 0.7em;
}
</style>
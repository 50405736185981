import { Bestandskontrolle, Rohstoffkontrolle } from "@/models/BestandskontrolleModels"

export type State = {
  // bestandskontrolle: Rohstoffkontrolle,
  bestandskontrolle: Array<Bestandskontrolle>,
  bestandskontrolleTage: Array<Array<Bestandskontrolle>>,
}

export const state: State = {
  // bestandskontrolle: sessionStorage.getItem("bestandskontrolle") != null && sessionStorage.getItem("bestandskontrolle") !== undefined ? JSON.parse(sessionStorage.bestandskontrolle) : new Rohstoffkontrolle(),
  bestandskontrolle: sessionStorage.getItem("bestandskontrolle") != null && sessionStorage.getItem("bestandskontrolle") !== undefined ? JSON.parse(sessionStorage.bestandskontrolle) : new Array<Bestandskontrolle>(),
  bestandskontrolleTage: sessionStorage.getItem("bestandskontrolleTage") != null && sessionStorage.getItem("bestandskontrolleTage") !== undefined ? JSON.parse(sessionStorage.bestandskontrolleTage) : new Array<Array<Bestandskontrolle>>(),
}

import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { LagerplatzResponse, Lagerplatzart, Lagerreihe, Listeartikel } from "@/models/LagerModels"
import { Listeppsid } from "@/models/PpsModels"

export type Getters = {
  lagerartikel(state: State): Array<Listeartikel>,
  lagerplaetze(state: State): Array<LagerplatzResponse>,
  lager(state: State): Array<Lagerreihe>,
  lagerplatzarten(state: State): Array<Lagerplatzart>,
  lagerpuffer(state: State): Array<Listeartikel>,
  produktionspuffer(state: State): Array<Listeppsid>,
  fertigwarenStellplaetze(state: State): number,
}

export const getters: GetterTree<State, RootState> & Getters = {
  lagerartikel(state) {
    return state.lagerartikel;
  },

  lagerplaetze(state) {
    return state.lagerplaetze;
  },

  lager(state) {
    return state.lager;
  },

  lagerplatzarten(state) {
    return state.lagerplatzarten;
  },

  lagerpuffer(state) {
    return state.lagerpuffer;
  },

  produktionspuffer(state) {
    return state.produktionspuffer;
  },

  fertigwarenStellplaetze(state) {
    return state.fertigwarenStellplaetze;
  },
}

import { Materialartikel } from "@/models/LagerMaterialModels"
import { Flasche, Karton, Kapsel, AnzahlFlaschen, AnzahlKartons, AnzahlKapseln } from "@/models/PpsMaterialModels"

export type State = {
  materialArtikelFlaschen: Array<Materialartikel>
  materialAnzahlFlaschen: Array<AnzahlFlaschen>
  materialFlaschen: Array<Flasche>
  materialArtikelKartons: Array<Materialartikel>
  materialAnzahlKartons: Array<AnzahlKartons>
  materialKartons: Array<Karton>
  materialArtikelKapseln: Array<Materialartikel>
  materialAnzahlKapseln: Array<AnzahlKapseln>
  materialKapseln: Array<Kapsel>
}

export const state: State = {
  materialArtikelFlaschen: new Array<Materialartikel>(),
  materialAnzahlFlaschen: new Array<AnzahlFlaschen>(),
  materialFlaschen: new Array<Flasche>(),
  materialArtikelKartons: new Array<Materialartikel>(),
  materialAnzahlKartons: new Array<AnzahlKartons>(),
  materialKartons: new Array<Karton>(),
  materialArtikelKapseln: new Array<Materialartikel>(),
  materialAnzahlKapseln: new Array<AnzahlKapseln>(),
  materialKapseln: new Array<Kapsel>(),
}

import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import { useStore } from '@/store'
import router from "@/router"

import { AuthUser } from "@/models/ArchivModels";
import { ErrorResponseData } from "@/models/CommonModels";
import { ActionTypes } from "@/store/modules/Archiv/actions";
import { ActionTypes as MitarbeiterActionTypes } from "@/store/modules/Lager/Mitarbeiter/actions";


let axiosInst: AxiosInstance;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // console.info(`[request] [${JSON.stringify(config)}]`);

  if (config.headers === undefined) {
    config.headers = {};
  }

  const user: AuthUser = localStorage.getItem("authUser") != null && localStorage.getItem("authUser") !== undefined ? JSON.parse(localStorage.authUser) : null;

  if (user && user.token) {
    config.headers.Authorization = `Bearer ${user.token}`;
  }

  return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
}


interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];
// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const onResponseError = async (error: AxiosError): Promise<any> => {
  const originalRequest: AxiosRequestConfig = error.config;
  const store = useStore();
  // console.error(`[response error] [${JSON.stringify(error)}]`);

  if (error.response && error.response.status == 401){
    if (!isRefreshing) {
      isRefreshing = true;

      try {
        await store.dispatch(ActionTypes.RefreshLogin, undefined)
        const newAccessToken = store.getters.authUser?.token;

        // Update the request headers with the new access token
        if (error.config.headers) {
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`
        }

        // Retry all requests in the queue with the new token
        refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
          axiosInst
          .request(config)
          .then((response: AxiosResponse) => resolve(response))
          .catch((err: AxiosError) => reject(err));
        });

        // Clear the queue
        refreshAndRetryQueue.length = 0;

        // Retry the original request
        return axiosInst(originalRequest)

      } catch (refreshError) {
        // Handle token refresh error
        // You can clear all storage and redirect the user to the login page
        store.dispatch(MitarbeiterActionTypes.LogoutLagerDB, undefined)
        .then(() => {
          router.replace({name: "logout"})
          return true
        })

      } finally {
        isRefreshing = false;
      }
    }

    // Add the original request to the queue
    return new Promise<any>((resolve, reject) => {
      refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
    });
  }

  else if (error.response?.status == 403) {
    const errorRespData = error.response.data as ErrorResponseData || null;
    if (errorRespData != null && errorRespData.detail == "Die Anmeldung ist abgelaufen") {
      store.dispatch(MitarbeiterActionTypes.LogoutLagerDB, undefined)
      .then(() => {
        router.replace({name: "logout"})
        return true
      })
    }
  }

  return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  axiosInst = axiosInstance
  return axiosInstance;
}

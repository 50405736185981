<template>
  <div id="produktionLagerLayout">
    <div class="row mb-3">
      <div class="col me-4">
        <div class="row pe-2" v-for="row in getBlockPA" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
          <div class="col">
            <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}</div>
            <div v-for="platz in row.stellplaetze.slice().reverse()" :key="platz.id">
              <LagerStellplatz :platz="platz" />
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="row" v-for="row in getBlockPC" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
          <div class="col">
            <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
            <div v-for="platz in row.stellplaetze" :key="platz.id">
              <LagerStellplatz :platz="platz" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div  class="row mb-3">
      <div class="col me-4">
        <div class="row pe-2" v-for="row in getBlockPB" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
          <div class="col">
            <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}</div>
            <div v-for="platz in row.stellplaetze" :key="platz.id">
              <LagerStellplatz :platz="platz" />
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="row" v-for="row in getBlockPD" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
          <div class="col">
            <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
            <div v-for="platz in row.stellplaetze" :key="platz.id">
              <LagerStellplatz :platz="platz" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import { Lagerreihe, Listeartikel } from '@/models/LagerModels';

import LagerStellplatz from './LagerStellplatz.vue'

export default defineComponent({
  name: "ProduktionLagerLayout",
  components: {
    LagerStellplatz,
  },
  
  props: {
    aktualisiertam: {
      type: String,
      required: true
    }
  },

  setup () {
    const store = useStore();
    const lager = computed(() => store.getters.lager);
    const lagerartikel = computed(() => store.getters.lagerartikel);

    const fertigwarenStellplaetze = computed(() => store.getters.fertigwarenStellplaetze)
    const lagerpuffer = computed(() => store.getters.lagerpuffer)
    const produktionspuffer = computed(() => store.getters.produktionspuffer)

    return {
      store,
      lager,
      lagerartikel,
      fertigwarenStellplaetze,
      lagerpuffer,
      produktionspuffer
    }
  },

  computed: {
    // ...mapGetters(['lager', 'lagerStatus']),
    getBlockPA() {
      return this.lager.filter((el: Lagerreihe) => el.block == "PA")
    },
    getBlockPB() {
      return this.lager.filter((el: Lagerreihe) => el.block == "PB").reverse()
    },
    getBlockPC() {
      return this.lager.filter((el: Lagerreihe) => el.block == "PC")
    },
    getBlockPD() {
      return this.lager.filter((el: Lagerreihe) => el.block == "PD")
    },
  },

  methods: {
    reiheNummer(row: Lagerreihe) {
      return row.reihe < 10 ? `0${row.reihe}` : row.reihe
    },

    getAnzahlPalettenInReihe(row: Lagerreihe) {
      let count = 0
      row.stellplaetze.forEach(el => {
        if (el.artikel != null) {
          count++
        }
      })

      return count < 10 ? `0${count}` : count
    },
  }
});
</script>

<style scoped>
.reihe-name {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 0.7em;
}
</style>
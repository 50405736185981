import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Bestandskontrolle, Rohstoffkontrolle } from "@/models/BestandskontrolleModels"

export type Getters = {
  // bestandskontrolle(state: State): Rohstoffkontrolle,
  bestandskontrolle(state: State): Array<Bestandskontrolle>,
  bestandskontrolleTage(state: State): Array<Array<Bestandskontrolle>>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  bestandskontrolle(state) {
    return state.bestandskontrolle
  },

  bestandskontrolleTage(state) {
    return state.bestandskontrolleTage
  },

}

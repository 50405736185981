import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row text-start" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-1" }
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "col-1" }
const _hoisted_6 = { class: "col-1" }
const _hoisted_7 = { class: "col-1" }
const _hoisted_8 = { class: "col-1" }
const _hoisted_9 = {
  key: 0,
  class: "col-1 text-success"
}
const _hoisted_10 = {
  key: 1,
  class: "col-1 text-danger"
}
const _hoisted_11 = {
  key: 2,
  class: "col-1 text-warning"
}
const _hoisted_12 = {
  key: 3,
  class: "col-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.artikel.artikel), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.artikel.herstellungid), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.artikel.maschinenfuehrer), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.artikel.landnr) + " " + _toDisplayString(_ctx.artikel.landkz), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.artikel.eas), 1),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.artikel.kw), 1),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.artikel.gesamtanzahl), 1),
    (_ctx.artikel.gesamtanzahl == _ctx.artikel.gesamtanzahlIst)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.artikel.gesamtanzahlIst), 1))
      : (_ctx.artikel.gesamtanzahl > _ctx.artikel.gesamtanzahlIst)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.artikel.gesamtanzahlIst), 1))
        : (_ctx.artikel.gesamtanzahl < _ctx.artikel.gesamtanzahlIst)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.artikel.gesamtanzahlIst), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.artikel.gesamtanzahlIst), 1))
  ]))
}
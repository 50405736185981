<template>
    <div
      class="stellplatz text-center"
      :style="{ backgroundColor: bgColor, color: textColor }"
      data-bs-toggle="modal" :data-bs-target="'#lagerplatzModal' + palette.id"
      >
      {{ palette.artikelnr }} {{ palette.landnr }}
    </div>

    <div
      class="modal fade p-0"
      :id="'lagerplatzModal' + palette.id"
      tabindex="-1"
      :aria-labelledby="'lagerplatzModal' + palette.id"
      aria-hidden="true"
      >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header py-1">
            <h5 class="modal-title">Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p class="text-size"><strong>ID:</strong> {{palette.id}}</p>
            <p class="text-size"><strong>Reihe:</strong> {{palette.lagerplatzname}}</p>
            <p class="text-size"><strong>Artikelnummer:</strong> {{palette.artikelnr}}</p>
            <p class="text-size"><strong>Artikel:</strong> {{palette.artikel}}</p>
            <p class="text-size"><strong>Landnummer:</strong> {{palette.landnr}}</p>
            <p class="text-size"><strong>Land:</strong> {{palette.land}}</p>
            <p class="text-size"><strong>Barcode:</strong> {{palette.barcode}}</p>
            <p class="text-size"><strong>Produktionsdatum:</strong> {{getProduktionsdatum}}</p>
            <p class="text-size"><strong>Produktionsplan:</strong> {{palette.produktionplanungid}}</p>
            <p class="text-size"><strong>Lagerdatum:</strong> {{getLagerdatum}}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { Listeartikel } from "@/models/LagerModels";
import moment from 'moment'

export default defineComponent({
  name: 'LagerpufferPalette',
  props: {
    palette: {
      type: Object as PropType<Listeartikel>,
      required: true
    }
  },

  computed: {
    getProduktionsdatum(): string {
      return moment(this.palette.produktiondatum).format('DD.MM.YYYY HH:mm:ss')
    },
    
    getLagerdatum(): string {
      return moment(this.palette.lagerdatum).format('DD.MM.YYYY HH:mm:ss')
    },

    bgColor(): string {
      let hex = Math.floor(((parseInt(this.palette.artikelnr) + parseInt(this.palette.landnr)) / 1000) * 0xFFFFFF).toString(16);
      return "#" + ("000000" + hex).substr(-6)
    }, 

    textColor(): string {
      let r, g, b, hsp

      // Umwandlung des Hexadezimalwerts in einen Dezimalwert
      let color = +("0x" + this.bgColor.slice(1).replace(/./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      );

      // Using the HSP value, determine whether the color is white or black
      if (hsp>127.5) {
          return 'black';
      } 
      else {
          return 'white';
      }
    }
  },

  data () {
    return {
      lagerplatz: '',
      lagerplatz_berechnen: false
    }
  }
});
</script>

<style scoped>
.stellplatz{
  float: left;
  background: white;
  width: 22px;
  height: 20px;
  margin: 1px;
  padding: 1px;
  border: 1px solid black;
  font-size: 0.55em;
  line-height: 0.95em;
  /* color: white; */
}

.stellplatz:hover{
  cursor: pointer;
}

.text-size {
  font-size: 1em !important;
}
</style>
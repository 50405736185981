<template>
  <div class="row">
    <div class="col">{{ anzahl.key }}</div>
    <div class="col">{{ anzahl.value }}</div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useStore } from '@/store'

import { KeyValuePair } from "@/models/PlanungModels"

export default defineComponent({
  name: "PlanungPalettenProStundeRow",
  props: {
    anzahl: {
      type: Object as PropType<KeyValuePair>,
      default: new KeyValuePair("", 0)
    }
  }
});
</script>
<template>
  <div class="row text-start">
    <div class="col-4">{{ artikel.artikel }}</div>
    <div class="col-1">{{ artikel.herstellungid }}</div>
    <div class="col-2">{{ artikel.maschinenfuehrer }}</div>
    <div class="col-1">{{ artikel.landnr }} {{ artikel.landkz }}</div>
    <div class="col-1">{{ artikel.eas }}</div>
    <div class="col-1">{{ artikel.kw }}</div>
    <div class="col-1">{{ artikel.gesamtanzahl }}</div>

    <div class="col-1 text-success" v-if="artikel.gesamtanzahl == artikel.gesamtanzahlIst">{{ artikel.gesamtanzahlIst }}</div>
    <div class="col-1 text-danger" v-else-if="artikel.gesamtanzahl > artikel.gesamtanzahlIst">{{ artikel.gesamtanzahlIst }}</div>
    <div class="col-1 text-warning" v-else-if="artikel.gesamtanzahl < artikel.gesamtanzahlIst">{{ artikel.gesamtanzahlIst }}</div>
    <div class="col-1" v-else>{{ artikel.gesamtanzahlIst }}</div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { Planung } from "@/models/PlanungModels"

export default defineComponent({
  name: "PlanungMaschineHeuteRow",
  props: {
    artikel: {
      type: Object as PropType<Planung>,
      default: new Planung()
    }
  }
});
</script>
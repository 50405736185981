export class LoginRequest {
  username: string
  userpassword: string

  constructor(username: string, userpassword: string) {
    this.username = username;
    this.userpassword = userpassword;
  }
}

// Login Response
export class AuthUser {
  userId: number
  benutzer: string
  username: string
  token: string
  refreshToken: string

  constructor () {
    this.userId = 0;
    this.benutzer = "";
    this.username = "";
    this.token = "";
    this.refreshToken = "";
  }
}

export interface AzureConfigEinstellung {
  clientId: string
  authority: string
  redirectUri: string
}

export class LoginAzureRequest {
  idToken: string
  username: string

  constructor (idToken: string, username: string) {
    this.idToken = idToken
    this.username = username
  }
}

export class LogoutRequest {
  refreshtoken: string

  constructor (token: string) {
    this.refreshtoken = token
  }
}

export class RefreshLoginRequest {
  refreshtoken: string

  constructor (token: string) {
    this.refreshtoken = token
  }
}
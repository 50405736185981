import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { S } from "@/models/EnergiezahlerModels";

export type Getters = {
  maschinenLeistungsWerte(state: State): Array<S>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  maschinenLeistungsWerte(state) {
    return state.maschinenLeistungsWerte;
  },
}

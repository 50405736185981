import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { AuthUser } from "@/models/ArchivModels";
import { Configuration } from "@azure/msal-browser"

export type Getters = {
  authLoading(state: State): boolean
  authUser(state: State): AuthUser | null,
  loggedIn(state: State): boolean,
  authError(state: State): string,

  azureUsername(state: State): string
  msalConfig(state: State): Configuration | null,
  azureLogin(state: State): boolean,
  azureLoginAvailable(state: State): boolean,
}

export const getters: GetterTree<State, RootState> & Getters = {
  authLoading(state) {
    return state.authLoading;
  },

  authUser(state) {
    return state.authUser;
  },

  loggedIn(state) {
    return state.loggedIn;
  },

  authError(state) {
    return state.authError;
  },
  

  msalConfig(state) {
    return state.msalConfig
  },
  
  azureUsername(state) {
    return state.azureUsername;
  },

  azureLogin(state) {
    return state.azureLogin
  },

  azureLoginAvailable(state) {
    return state.azureLoginAvailable
  }
}

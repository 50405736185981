<template>
  <div id="mitarbeiter">

    <div v-if="loginlagerLoading" class="text-center m-5">
      <LoadingSpinner />
    </div>

    <div v-else-if="loggedIn && lagerUser && lagerUser.lagerUser.verwaltung == 1" class="w-100">
      <!-- <VerwaltungSidebar @setContent="setContentArea"/> -->

      <!-- <MitarbeiterContent v-if="content == 'mitarbeiter'" />
      <div v-else class="m-4 float-center w-100">
        <div class="alert alert-info" role="alert">Bitte wählen Sie eine Seite aus.</div>
      </div>  -->

      <div class="bg-white p-3 border-0 shadow-sm rounded-0">
        <div class="d-flex justify-content-between">
          <p class="text-secondary">Mitarbeiter (Angemeldet: {{ mitarbeiterArbeitszeiten.length }})</p>

          <div>
            <button class="btn btn-sm btn-secondary me-3" @click="generatePDF()"><font-awesome-icon icon="file-arrow-down" /></button>
            <button class="btn btn-sm text-muted" @click="loadMitarbeiterArbeitszeiten()"><font-awesome-icon icon="sync" /></button>
          </div>
        </div>

        <LoadingSpinner v-if="loading" :text="'...Daten werden geladen...'"/>

        <div v-else>
          <table class="table table-striped">
            <thead>
              <th scope="col">Name</th>
              <th scope="col">GUID</th>
              <th scope="col">Org.-Einheit</th>
              <th scope="col">Anmeldung</th>
            </thead>

            <tbody>
              <tr v-for="arbeitszeit in mitarbeiterArbeitszeiten" :key="arbeitszeit.personalguid">
                <td>{{ arbeitszeit.name }}, {{ arbeitszeit.vorname }}</td>
                <td>{{ arbeitszeit.personalguid }}</td>
                <td>{{ arbeitszeit.orgeinheit }}</td>
                <td>{{ vonConerter(arbeitszeit.von.toString()) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <div v-else class="text-center m-5">
      <div class="alert alert-danger" role="alert">Sie haben keinen Zugriff auf diese Daten</div>
    </div> 
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'
import { ActionTypes as PersonaldatenActionTypes } from '@/store/modules/Personaldaten/actions'


import MitarbeiterContent from '@/components/Mitarbeiter/Content/MitarbeiterContent.vue'
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: 'Mitarbeiter',
  components: {
    LoadingSpinner,
    // VerwaltungSidebar,
    // VerwaltungDownloadContent,
    MitarbeiterContent,
  },

  setup () {
    const store = useStore();

    const lagerUser = computed(() => store.getters.lagerUser)
    const loginlagerLoading = computed(() => store.getters.status.loginlagerdbLoading)

    const loading = computed(() => store.getters.status.mitarbeiterArbeitszeitenLoading)
    const errorMsg = computed(() => store.getters.status.mitarbeiterArbeitszeitenErrorMsg)

    const mitarbeiterArbeitszeiten = computed(() => store.getters.mitarbeiterArbeitszeiten.sort((a, b) => a.name.localeCompare(b.name)))

    function vonConverter(von: string) {
      const date = new Date(von);

      return ("0" + date.getHours()).slice(-2) + ":" +("0" + (date.getMinutes() + 1)).slice(-2);
    }

    function dateConverter(date: Date) {
      // const date = new Date(printDate);

      return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + (date.getMinutes() + 1)).slice(-2);
    }

    function loadMitarbeiterArbeitszeiten() {
      store.dispatch(PersonaldatenActionTypes.GetMitarbeiterArbeitszeiten, undefined)
    }

    function generatePDF() {
      const doc = new jsPDF();

      const titel = 'Mitarbeiterliste - ' + dateConverter(new Date())

      // Add a document heading
      doc.setFontSize(18);
      doc.text(titel, 14, 22); // Text, x, y coordinates

      // Custom column headings
      const tableColumnHeadings = ["Name", "GUID", "Org.-Einheit", "Anmeldung"];

      // Map the data for the table rows
      const tableRows = mitarbeiterArbeitszeiten.value.map(item => [item.name + ", " + item.vorname, item.personalguid, item.orgeinheit, vonConverter(item.von.toString())]);

      // Generate the table with custom headings and data
      autoTable(doc, {
        head: [tableColumnHeadings],
        body: tableRows,
        startY: 30, // To prevent overlapping with the heading
      });

      // Save the PDF
      doc.save(titel + '.pdf');
    }

    return {
      store,
      lagerUser,
      loginlagerLoading,

      loading,
      errorMsg,
      mitarbeiterArbeitszeiten,
      vonConerter: vonConverter,
      loadMitarbeiterArbeitszeiten,

      generatePDF
    }
  },

  methods: {
    loginLagerDB () {
      this.store.dispatch(MitarbeiterActionTypes.LoginLagerDB, undefined)
        .then(() => {
          this.loggedIn = true
        })
    },

    setContentArea(value: string) {
      this.content = value
    }
  },

  data () {
    return {
      loggedIn: false,
      content: 'mitarbeiter'
    }
  },

  mounted () {
    if(!localStorage.getItem('lagerUser')){
      this.loginLagerDB()
    }
    else {
      this.loggedIn = true
    }

    this.loadMitarbeiterArbeitszeiten()
  }
})
</script>

<style lang="scss" scoped>
#verwaltung,
.contentArea {
  height: 100vh;
  /* overflow: hidden; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#verwaltung::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#verwaltung {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.backgroundColor {
  background: rgb(242, 243, 248);
}

.list-striped:nth-child(odd) {
  background: #eaeaeb;
}
</style>
<template>
  <div class="d-flex flex-column text-center">
  <!-- <vue-gauge :refid="'gauge-chart-' + id" :options="options"></vue-gauge> -->
    <div ref="gaugeChartContainer" class="vue-gauge-item"></div>
    <h4 class="ist-leistung-label mb-0">{{ istLeistung }}</h4>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType, watch } from 'vue';
import * as GaugeChart from "gauge-chart";

export default defineComponent({
  name: 'GaugeChart',

  props: {
    id: {
      type: String as PropType<string>,
      required: true
    },
    maxLeistung: {
      type: Number as PropType<number>,
      default: 5000
    },
    sollLeistung: {
      type: Number as PropType<number>,
      default: 2000
    },
    istLeistung: {
      type: Number as PropType<number>,
      default: 0
    }
  },

  setup(props) {
    const gaugeChartContainer = ref<HTMLDivElement>()
    const chart = ref<GaugeChart.GaugeInterface | undefined>()
    
    const options = ref({
        hasNeedle:true,
        needleColor: "black",
        needleUpdateSpeed: 1000,
        arcColors: ["#dc3545", "#ffc107", "#198754"],
        arcDelimiters: [75, 85],
        arcOverEffect: false,
        // arcLabels: [Math.floor(props.sollLeistung * 0.9).toString(), props.sollLeistung.toString()],
        arcPadding: 0,
        arcPaddingColor: "",
        rangeLabel: ['0', props.maxLeistung.toString()],
        // centralLabel: "0",
        labelsFont: 'sans-serif',
        chartWidth: 250,
        needleValue: 0, 
    })



    function setOptionsValues() {
      const sollLeistungProzent = Math.floor((sollLeistung.value / maxLeistung.value) * 100)
      const sollLeistung90Prozent = Math.floor((sollLeistung.value * 0.9) / maxLeistung.value * 100)

      if (sollLeistung.value > maxLeistung.value) {
        options.value.arcDelimiters = [99]
        options.value.arcColors = ["#dc3545", "#198754"]
      }
      else {
        options.value.arcDelimiters = [sollLeistung90Prozent, sollLeistungProzent]
        options.value.arcColors = ["#dc3545", "#ffc107", "#198754"]
      }

      // options.value.arcDelimiters = [sollLeistung90Prozent, sollLeistungProzent]
      options.value.rangeLabel = ['0', props.maxLeistung.toString()]
      options.value.needleValue = istLeistungProzent.value
    }

    const istLeistung = computed(() => {
      return props.istLeistung
    })

    const istLeistungProzent = computed(() => {
      const value = Math.floor((istLeistung.value / props.maxLeistung) * 100)
      return value <= 100 ? value : 100
    })

    watch(istLeistung, (newValue, oldValue) => {
      chart.value?.updateNeedle(istLeistungProzent.value)
    })

    const sollLeistung = computed(() => {
      return props.sollLeistung
    })

    watch(sollLeistung, (newValue, oldValue) => {
      if (newValue != oldValue) {
        initChart()
      }
    })

    const maxLeistung = computed(() => {
      return props.maxLeistung
    })

    watch(maxLeistung, (newValue, oldValue) => {
      if (newValue != oldValue) {
        initChart()
      }
    })

    function initChart() {
      chart.value?.removeGauge()
      setOptionsValues()
      if (gaugeChartContainer.value != null) {
        chart.value = GaugeChart.gaugeChart(gaugeChartContainer.value, options.value.chartWidth, options.value)
        chart.value.updateNeedle(options.value.needleValue)
      }
    }

    

    return {
      gaugeChartContainer,
      initChart
    };
  },

  mounted() {
    this.initChart()
  }
});
</script>

<style scoped>
.ist-leistung-label {
  margin-top: -35px;
}
</style>
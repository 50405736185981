import { Maschine } from "@/models/MaschinenModels"
import { Listeppsid } from "@/models/PpsModels"

export type State = {
  maschinen: Array<Maschine>,
}

export const state: State = {
  maschinen: sessionStorage.getItem("maschinen") != null && sessionStorage.getItem("maschinen") !== undefined ? JSON.parse(sessionStorage.maschinen) : new Array<Maschine>(),
}

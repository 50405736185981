export class Listeppsid {
  id: number;
  artikelnr: string;
  artikel: string;
  landnr: string;
  land: string;
  barcode: string;
  mhd: string;
  charge: string;
  produktionsplanid: number;
  herstellungid: number;
  maschineid: number;
  maschinename: string;
  kw: number;
  eas: number;
  lagerplatzid: number;
  lagerplatzname: string;
  adate: Date;
  mdate: Date;
  status: number;
  statustext: string;
  landkz: string;
  stationsnummer: string;
  etiketttype: number;

  constructor () {
    this.id = 0;
    this.artikelnr = "";
    this.artikel = "";
    this.landnr = "";
    this.land = "";
    this.barcode = "";
    this.mhd = "";
    this.charge = "";
    this.produktionsplanid = 0;
    this.herstellungid = 0;
    this.maschineid = 0;
    this.maschinename = "";
    this.kw = 0;
    this.eas = 0;
    this.lagerplatzid = 0;
    this.lagerplatzname = "";
    this.adate = new Date();
    this.mdate = new Date();
    this.status = 0;
    this.statustext = "";
    this.landkz = "";
    this.stationsnummer = "";
    this.etiketttype = 0;
  }
}
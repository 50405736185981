export class Bestandskontrolle{
  id: number;
  datum: Date;
  rohstoffid: number;
  rohstoffname: string;
  alkoholprozent: number;
  litersoll: number;
  literist: number;
  lasoll: number;
  laist: number;
  datumsoll: Date;
  datumist: Date;

  constructor () {
    this.id = 0;
    this.datum = new Date();
    this.rohstoffid = 0;
    this.rohstoffname = "";
    this.alkoholprozent = 0;
    this.litersoll = 0;
    this.literist = 0;
    this.lasoll = 0;
    this.laist = 0;
    this.datumsoll = new Date();
    this.datumist = new Date();
  }
}

export class Rohstoffkontrolle {
  d1: Array<Bestandskontrolle>;
  d2: Array<Bestandskontrolle>;

  constructor () {
    this.d1 = new Array<Bestandskontrolle>();
    this.d2 = new Array<Bestandskontrolle>();
  }
}
import { LagerplatzResponse, Lagerplatzart, Lagerreihe, Listeartikel } from "@/models/LagerModels"
import { Listeppsid } from "@/models/PpsModels"

export type State = {
  lagerartikel: Array<Listeartikel>,
  lagerplaetze: Array<LagerplatzResponse>,
  lager: Array<Lagerreihe>,
  lagerplatzarten: Array<Lagerplatzart>,
  lagerpuffer: Array<Listeartikel>,
  produktionspuffer: Array<Listeppsid>,
  fertigwarenStellplaetze: number,
}

export const state: State = {
  lagerartikel: sessionStorage.getItem("lagerartikel") != null && sessionStorage.getItem("lagerartikel") !== undefined ? JSON.parse(sessionStorage.lagerartikel) : new Array<Listeartikel>(),
  lagerplaetze: sessionStorage.getItem("lagerplaetze") != null && sessionStorage.getItem("lagerplaetze") !== undefined ? JSON.parse(sessionStorage.lagerplaetze) : new Array<LagerplatzResponse>(),
  lager: sessionStorage.getItem("lager") != null && sessionStorage.getItem("lager") !== undefined ? JSON.parse(sessionStorage.lager) : new Array<Lagerreihe>(),
  lagerplatzarten: sessionStorage.getItem("lagerplatzarten") != null && sessionStorage.getItem("lagerplatzarten") !== undefined ? JSON.parse(sessionStorage.lagerplatzarten) : new Array<Lagerplatzart>(),
  lagerpuffer: sessionStorage.getItem("lagerpuffer") != null && sessionStorage.getItem("lagerpuffer") !== undefined ? JSON.parse(sessionStorage.lagerpuffer) : new Array<Listeartikel>(),
  produktionspuffer: sessionStorage.getItem("produktionspuffer") != null && sessionStorage.getItem("produktionspuffer") !== undefined ? JSON.parse(sessionStorage.produktionspuffer) : new Array<Listeppsid>(),
  fertigwarenStellplaetze: sessionStorage.getItem("fertigwarenStellplaetze") != null && sessionStorage.getItem("fertigwarenStellplaetze") !== undefined ? JSON.parse(sessionStorage.fertigwarenStellplaetze) : 0,
}

<template>
  <div class="statusAnzeige">
    Aktualisiert um: {{ updateDatetimeConverter }}
  </div>

  <div v-if="errorMsg" class="alert alert-danger">
    {{ errorMsg }}
  </div>

  <LoadingSpinner v-if="loading" :text="'...Daten werden geladen...'" />

  <div class="container-fluid" v-else>
    <div class="row">
      <div class="col-7">
        <div class="row">
          <div v-if="maschinenPlanungHeuteM1 != null" class="col-3 maschine-col mb-5 bg-white shadow border p-0">
            <div class="p-2 mb-1 d-flex justify-content-between" :class="getHeaderBg(maschinenPlanungHeuteM1.maschinenzustand)">
              <strong>{{ maschinenPlanungHeuteM1.maschinename }}</strong><span class="text-muted small">{{ maschinenPlanungHeuteM1.maschinenzustand }}</span>
            </div>
  
            <MaschinePlanung :planung="maschinenPlanungHeuteM1.planung" />
          </div>

          <div v-if="maschinenPlanungHeuteM10 != null" class="col-3 maschine-col mb-5 bg-white shadow border p-0">
            <div class="p-2 mb-1 d-flex justify-content-between" :class="getHeaderBg(maschinenPlanungHeuteM10.maschinenzustand)">
              <strong>{{ maschinenPlanungHeuteM10.maschinename }}</strong><span class="text-muted small">{{ maschinenPlanungHeuteM10.maschinenzustand }}</span>
            </div>

            <MaschinePlanung :planung="maschinenPlanungHeuteM10.planung" />
          </div>

          <div v-if="maschinenPlanungHeuteM12 != null" class="col-3 maschine-col mb-5 bg-white shadow border p-0">
            <div class="p-2 mb-1 d-flex justify-content-between" :class="getHeaderBg(maschinenPlanungHeuteM12.maschinenzustand)">
              <strong>{{ maschinenPlanungHeuteM12.maschinename }}</strong><span class="text-muted small">{{ maschinenPlanungHeuteM12.maschinenzustand }}</span>
            </div>

            <MaschinePlanung :planung="maschinenPlanungHeuteM12.planung" />
          </div>

          <div v-if="maschinenPlanungHeuteM6 != null" class="col-3 maschine-col mb-5 bg-white shadow border p-0">
            <div class="p-2 mb-1 d-flex justify-content-between" :class="getHeaderBg(maschinenPlanungHeuteM6.maschinenzustand)">
              <strong>{{ maschinenPlanungHeuteM6.maschinename }}</strong><span class="text-muted small">{{ maschinenPlanungHeuteM6.maschinenzustand }}</span>
            </div>

            <MaschinePlanung :planung="maschinenPlanungHeuteM6.planung" />
          </div>
        </div>
      </div>

      <div class="col-5">
        <div class="row">
          <div v-if="maschinenPlanungHeuteM8 != null" class="col-4 maschine-col mb-5 bg-white shadow border p-0">
            <div class="p-2 mb-1 d-flex justify-content-between" :class="getHeaderBg(maschinenPlanungHeuteM8.maschinenzustand)">
              <strong>{{ maschinenPlanungHeuteM8.maschinename }}</strong><span class="text-muted small">{{ maschinenPlanungHeuteM8.maschinenzustand }}</span>
            </div>

            <MaschinePlanung :planung="maschinenPlanungHeuteM8.planung" />
          </div>

          <div v-if="maschinenPlanungHeuteM24 != null" class="col-4 maschine-col mb-5 bg-white shadow border p-0">
            <div class="p-2 mb-1 d-flex justify-content-between" :class="getHeaderBg(maschinenPlanungHeuteM24.maschinenzustand)">
              <strong>{{ maschinenPlanungHeuteM24.maschinename }}</strong><span class="text-muted small">{{ maschinenPlanungHeuteM24.maschinenzustand }}</span>
            </div>

            <MaschinePlanung :planung="maschinenPlanungHeuteM24.planung" />
          </div>

          <div v-if="maschinenPlanungHeuteM25 != null" class="col-4 maschine-col mb-5 bg-white shadow border p-0">
            <div class="p-2 mb-1 d-flex justify-content-between" :class="getHeaderBg(maschinenPlanungHeuteM25.maschinenzustand)">
              <strong>{{ maschinenPlanungHeuteM25.maschinename }}</strong><span class="text-muted small">{{ maschinenPlanungHeuteM25.maschinenzustand }}</span>
            </div>

            <MaschinePlanung :planung="maschinenPlanungHeuteM25.planung" />
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import MaschinePlanung from "./MaschinenPlanung.vue";
import { ActionTypes as ProduktionsstatusActionTypes } from '@/store/modules/Pps/Produktionsstatus/actions';

export default defineComponent({
  name: 'MaschinenContent',
  components: {
    LoadingSpinner,
    MaschinePlanung
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.maschinenstatusLoading)
    const errorMsg = computed(() => store.getters.status.maschinenstatusErrorMsg)
    const maschinenPlanungHeute = computed(() => store.getters.maschinenPlanungHeute)

    console.log(maschinenPlanungHeute.value);

    const maschinenPlanungHeuteM1 = computed(() => maschinenPlanungHeute.value.find(m => m.maschinenid == 1))
    const maschinenPlanungHeuteM6 = computed(() => maschinenPlanungHeute.value.find(m => m.maschinenid == 6))
    const maschinenPlanungHeuteM8 = computed(() => maschinenPlanungHeute.value.find(m => m.maschinenid == 8))
    const maschinenPlanungHeuteM10 = computed(() => maschinenPlanungHeute.value.find(m => m.maschinenid == 10))
    const maschinenPlanungHeuteM12 = computed(() => maschinenPlanungHeute.value.find(m => m.maschinenid == 12))
    const maschinenPlanungHeuteM24 = computed(() => maschinenPlanungHeute.value.find(m => m.maschinenid == 24))
    const maschinenPlanungHeuteM25 = computed(() => maschinenPlanungHeute.value.find(m => m.maschinenid == 25))


    const updateStatusInterval = ref();
    const updateDatetime = ref<Date>(new Date());
    const updateDatetimeConverter = computed(() => {
      return ("0" + (updateDatetime.value.getHours())).slice(-2) + ":" + ("0" + (updateDatetime.value.getMinutes())).slice(-2)
    })

    return {
      store,
      loading,
      errorMsg,
      maschinenPlanungHeuteM1,
      maschinenPlanungHeuteM6,
      maschinenPlanungHeuteM8,
      maschinenPlanungHeuteM10,
      maschinenPlanungHeuteM12,
      maschinenPlanungHeuteM24,
      maschinenPlanungHeuteM25,
      updateStatusInterval,
      updateDatetime,
      updateDatetimeConverter,
    }
  },

  methods: {
    getMaschinenPlanungHeute() {
      this.store.dispatch(ProduktionsstatusActionTypes.getMaschinenPlanungHeute, undefined)
      .then(() => {
        if (this.errorMsg != "") {
          this.updateDatetime = new Date();
        }
      })
    },
    
    updateMaschinenPlanungHeute() {
      this.store.dispatch(ProduktionsstatusActionTypes.updateMaschinenPlanungHeute, undefined)
      .then(() => {
        if (this.errorMsg == "") {
          this.updateDatetime = new Date();
        }
      })
    },

    getHeaderBg(zustand: string) {
      if(zustand.toLowerCase() == "produktion") {
        return "bg-success text-white"
      }
      else if(zustand.toLowerCase() == "fehler") {
        return "bg-danger text-white"
      }
      if(zustand.toLowerCase() == "muster") {
        return "bg-warning text-white"
      }
      else {
        return "bg-secondary text-white"
      }
    },
  },

  mounted () {
    this.getMaschinenPlanungHeute();

    this.updateStatusInterval = setInterval(() => {
      this.updateMaschinenPlanungHeute();
    }, 1 * 60 * 1000);
  },
  
  beforeUnmount () {
    clearInterval(this.updateStatusInterval)
  }
})
</script>

<style lang="scss" scoped>
#produktionContent {
  background: rgb(242, 243, 248);
}

.statusAnzeige {
  // z-index: 100;
  position: relative;
  margin-top: -15px;
  font-size: .7em;
  text-align: end;
  // top: 0;
  // left: 0;
}

.maschine-col {
  min-height: 90vh;
}
</style>
import { ActionContext, ActionTree } from "vuex";
import ApiService from "@/service/LagerService";

import { S } from "@/models/EnergiezahlerModels";

import { RootState } from '@/store';
import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";

import { ActionTypes as CommonActionTypes } from "../Common/actions";

export enum ActionTypes {
  GetMaschineLeistung = "GETMASCHINELEISTUNG",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetMaschineLeistung](context: ActionArguments, maschinenr: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [ActionTypes.GetMaschineLeistung]({commit, dispatch, rootGetters}, maschinenr) {
    const status = rootGetters.status;
    status.energiezaehlerLoading = true;
    status.energiezaehlerErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getMaschineLeistungWerte(maschinenr)
    .then(res => {
      const data: S = res.data;
      commit(MutationTypes.AddOrUpdatemaschinenLeistungsWerte, data);
    })
    .catch(err => {
      // console.log(err.response);
      
      if (err.response.status == 404) {
        commit(MutationTypes.RemoveMaschinenLeistungsWerte, maschinenr);
      }

      status.energiezaehlerErrorMsg = err.response.data;
      dispatch(CommonActionTypes.SetStatus, status);
    })
    .finally(() => {
      status.energiezaehlerLoading = false;
      dispatch(CommonActionTypes.SetStatus, status);
    });
  },
}
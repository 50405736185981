import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Personalstatus } from "@/models/PersonalModels";

export type Getters = {
  personal(state: State): Array<Personalstatus>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  personal(state) {
    return state.personal;
  }
}

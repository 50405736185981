import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dfca885"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row text-left" }
const _hoisted_2 = { class: "col-2" }
const _hoisted_3 = { class: "col-2" }
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "col-1" }
const _hoisted_6 = { class: "col-1" }
const _hoisted_7 = { class: "col-1" }
const _hoisted_8 = { class: "col-1" }
const _hoisted_9 = { class: "col-1" }
const _hoisted_10 = { class: "col-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.rohstoff.rohstoffname), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.solldatum) + " - 24 Uhr", 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.istdatum), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(Math.trunc(_ctx.rohstoff.litersoll)), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(Math.trunc(_ctx.rohstoff.literist)), 1),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.literDifferenz), 1),
    _createElementVNode("div", _hoisted_8, _toDisplayString(Math.trunc(_ctx.rohstoff.lasoll)), 1),
    _createElementVNode("div", _hoisted_9, _toDisplayString(Math.trunc(_ctx.rohstoff.laist)), 1),
    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.laDifferenz), 1)
  ]))
}
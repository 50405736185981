import { MutationTree } from "vuex";
import { State } from "./state";
import { S } from "@/models/EnergiezahlerModels";

export enum MutationTypes {
  AddOrUpdatemaschinenLeistungsWerte = "ADDORUPDATEMASCHINENLEISTUNGSWERTE",
  RemoveMaschinenLeistungsWerte = "REMOVEMASCHINENLEISTUNGSWERTE",
}


export type Mutations = {
  [MutationTypes.AddOrUpdatemaschinenLeistungsWerte](state: State, data: S): void
  [MutationTypes.RemoveMaschinenLeistungsWerte](state: State, maschinenr: number): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.AddOrUpdatemaschinenLeistungsWerte](state, data) {
    const oldIndex = state.maschinenLeistungsWerte.findIndex(x => x.s == data.s);
    if (oldIndex != -1) {
      state.maschinenLeistungsWerte.splice(oldIndex, 1, data);
    }
    else {
      state.maschinenLeistungsWerte.push(data);
    }

    
    sessionStorage.setItem("maschinenLeistungsWerte", JSON.stringify(state.maschinenLeistungsWerte));
  },

  [MutationTypes.RemoveMaschinenLeistungsWerte](state, maschinenr) {
    const index = state.maschinenLeistungsWerte.findIndex(x => x.s == maschinenr);
    if (index != -1) {
      state.maschinenLeistungsWerte.splice(index, 1);
    }
    sessionStorage.setItem("maschinenLeistungsWerte", JSON.stringify(state.maschinenLeistungsWerte));
  },
}
<template>
  <div>
    <div class="card">

      
      <div class="card-header">
        <div class="row">
          <div class="col text-start">
            <strong>Download Links</strong>
          </div>
        </div>
      </div>
      
      <!-- <div class="row m-3">
        <div class="col">
          <button class="btn btn-success float-end" data-bs-toggle="modal" data-bs-target="#dateiHinzufuegen">
            <font-awesome-icon icon="plus" class="me-2 fa-lg"/> Datei hinzufügen
          </button>
        </div>
      </div> -->

      <div class="card-text">
        </div>

        <table class="table table-striped table-small table-responsive table-outlined text-start">
          <thead>
            <tr>
              <th scope="col">Datum</th>
              <th scope="col">Name</th>
              <th scope="col">Download Link</th>
              <th scope="col"> Bearbeiten</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="print in printdata" :key="print.name">
              <td>{{ dateConverter(print.datum) }}</td>
              <td> {{ print.name }}</td>
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" disabled :value="getDownloadLink(print.name)" aria-describedby="dwnld-link-btn">
                  <button class="btn btn-outline-primary" id="dwnld-link-btn"  @click="copyLinkToClipboard(print.name)">
                    <font-awesome-icon icon="copy" v-b-tooltip.hover title="Link kopieren" />
                  </button>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <div class="btn-group" role="group">
                    <!-- <button class="btn btn-primary" @click="openDownloadLinkVersenden(print.name)"><font-awesome-icon icon="envelope" v-b-tooltip.hover title="Datei versenden" /></button> -->
                    <button class="btn btn-secondary" @click="downloadPrintdataFile(print.name)"><font-awesome-icon icon="download" v-b-tooltip.hover title="Datei herunterladen" /></button>
                    <!-- <button class="btn btn-danger" @click="dateiLoeschen(print.name)"><font-awesome-icon icon="trash" v-b-tooltip.hover title="Datei löschen" /></button> -->
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <b-modal id="emailVersenden" title="Download Link versenden" size="xl" header-bg-variant="primary" header-text-variant="white" hide-footer>
      <b-form @submit.prevent="downloadLinkVersenden()">
        <b-form-row class="mb-4">
          <b-col>
            <label for="fromName">Absender Name</label>
            <b-form-input id="fromName" v-model="email.fromName" size="sm"></b-form-input>
          </b-col>
          <b-col>
            <label for="fromAdresse">Absender E-Mail Adresse</label>
            <b-form-input id="fromAdresse" v-model="email.fromAdresse" size="sm"></b-form-input>
          </b-col>
        </b-form-row>

        <b-form-row class="mb-3">
          <b-col cols="1" md="2" sm="3">
            <b-button type="submit" variant="primary" class="py-3 mt-1">
              <font-awesome-icon icon="paper-plane" v-b-tooltip.hover title="E-Mail versenden" />
              Senden
            </b-button>
          </b-col>

          <b-col>
            <b-form-row class="mb-2">
              <b-col cols="2">
                <label for="toAdresse">Empfänger E-Mail Adresse</label>
              </b-col>
              <b-col>
                <b-form-input id="toAdresse" v-model="email.toAdresse" required></b-form-input>
              </b-col>
            </b-form-row>
                
            <b-form-row>
              <b-col cols="2">
                <label for="subject">Betreff</label>
              </b-col>
              <b-col>
                <b-form-input id="subject" v-model="email.subject" required></b-form-input>
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col> -->
            <!-- <label for="content">E-Mail</label> -->
            <!-- <b-form-textarea id="content" v-model="email.content" rows="6" max-row="8" required></b-form-textarea>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal id="dateiHinzufuegen" title="Datei hinzufügen" size="xl" header-bg-variant="success" header-text-variant="white" hide-footer>
      <b-row class="mb-3">
        <b-col>
          <b-form @submit.prevent="uploadFile">
            <b-form-file v-model="file" placeholder="Datei auswählen" class="mb-2" :disabled="printdataStatus.uploadFile" required ></b-form-file>
            <b-button type="submit" variant="success" class="float-right" :disabled="printdataStatus.uploadFile" >Speichern</b-button>
          </b-form>
        </b-col>
      </b-row>
      <b-row v-if="printdataStatus.uploadFile">
        <b-col>
          <b-alert variant="info" show>Die Datei wird jetzt hochgeladen, dies kann einige Minuten dauern.<br>Sie können dieses Fenster schließen aber keine weiteren Dateien hochladen bis der Vorgang abgeschlossen ist.</b-alert>
          <b-progress id="uploadProgress" variant="success" :max="100" class="my-2"></b-progress>
          <b-button variant="outline-success" class="float-right" @click="closeDateHinzufuegenModal()">Fenster schließen</b-button>
        </b-col>
      </b-row>
    </b-modal> -->

    <!-- <div
      class="modal fade p-0"
      id="dateiHinzufuegen"
      aria-labelledby="dateiHinzufuegen"
      ref="dateiHinzufuegenModalRef"
    >

    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-titel">Datei hinzufügen</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <div class="row mb-3">
            <div class="col">
              <div class="form-floating" @submit.prevent="uploadFile()">
                <b-form-file v-model="file" placeholder="Datei auswählen" class="mb-2" :disabled="printdataLoading" required ></b-form-file>
                <button type="submit" class="btn btn-success float-end" :disabled="printdataLoading" >Speichern</button>
              </div>
            </div>
          </div>
          <div class="row" v-if="printdataStatus.uploadFile">
            <div class="col">
              <b-alert variant="info" show>Die Datei wird jetzt hochgeladen, dies kann einige Minuten dauern.<br>Sie können dieses Fenster schließen aber keine weiteren Dateien hochladen bis der Vorgang abgeschlossen ist.</b-alert>
              <b-progress id="uploadProgress" variant="success" :max="100" class="my-2"></b-progress>
              <button variant="btn btn-outline-success" class="float-end" data-bs-dismiss="modal">Fenster schließen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div> -->
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent } from "vue";
import { useStore } from '@/store'
import { ActionTypes as PrintdataActionTypes } from '@/store/modules/Lager/Printdata/actions'

import { Printdata } from "@/models/PrintdataModels"
import { Email } from "@/models/CommonModels";

import { mapGetters, mapActions } from "vuex";
import moment from 'moment'

export default defineComponent({
  name: "printdataCard",
  computed: mapGetters(['printdata', 'printdataStatus']),

  setup () {
    const store = useStore();

    const printdata = computed(() => store.getters.printdata)
    const printdataLoading = computed(() => store.getters.status.printdataLoading)

    return {
      store,
      printdata,
      printdataLoading
    }
  },

  methods: {
    // ...mapActions(['getPrintdataFileList', 'downloadPrintdataFile', 'uploadPrintdataFile', 'sendPrintdataEmail', 'deletePrintdataFile']),
    dateConverter(dateString: string): string {
      const date = new Date(dateString)
      return ("0" + date.getDate()).slice(-2) +
      "." +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "." +
      date.getFullYear();
    },
    
    getPrintdataFileList () {
      this.store.dispatch(PrintdataActionTypes.GetPrintdataFileList, undefined)
    },
    
    downloadPrintdataFile (filename: string) {
      this.store.dispatch(PrintdataActionTypes.DownloadPrintdataFile, filename)
    },
    
    uploadPrintdataFile (file: FormData) {
      this.store.dispatch(PrintdataActionTypes.UploadPrintdataFile, file)
    },
    
    sendPrintdataEmail (email: Email) {
      this.store.dispatch(PrintdataActionTypes.SendPrintdataEmail, email)
    },
    
    deletePrintdataFile (filename: string) {
      this.store.dispatch(PrintdataActionTypes.DeletePrintdataFile, filename)
    },

    getDownloadLink(name: string) {
      // return `http://localhost:60898/v1/download/${name}`
      return `https://erpapi.eckerts.de/v1/download/${name}`
    },

    dateiLoeschen(name: string) {
      if (confirm("Die Datei wird unwiderruflich gelöscht!")) {
        this.deletePrintdataFile(name)
      }
    },


    // openDownloadLinkVersenden (name: string) {
    //   this.downloadName = name
    //   this.email.content = this.getDownloadLink(name)
    //   this.$bvModal.show(`emailVersenden`)
    // },

    // downloadLinkVersenden() {
    //   this.email.toName = this.email.toAdresse
    //   this.sendPrintdataEmail(this.email)
    //     .then(() => {
    //       this.downloadName = ""
    //       this.email.toAdresse = ""
    //       this.email.toName = ""
    //       this.email.subject = ""
    //       this.email.content = ""
    //       this.$bvModal.hide(`emailVersenden`)
    //     })
    // },

    // uploadFile() {
    //   var fd = new FormData()
    //   fd.append("file", this.file)
    //   this.uploadPrintdataFile(fd)
    //     .then(() => {
    //       this.file = null
    //       this.$bvModal.hide(`dateiHinzufuegen`)

    //       const toastData = {
    //         title: "Die Datei wurde gespeichert.",
    //         msg: "Die Datei wurde erfolgreich hochgeladen",
    //         variant: "success"
    //       }

    //       if (this.printdataStatus.errMsg) {
    //         toastData.title = this.printdataStatus.errMsg
    //         toastData.msg = "Es ist etwas schief gelaufen."
    //         toastData.variant = "danger"
    //       }

    //       this.makeToast(toastData)
    //     })
    // },

    copyLinkToClipboard(name: string) {
      let link = this.getDownloadLink(name)
      const el = document.createElement('textarea')
      el.value = link
      document.body.appendChild(el)
      el.select()
      document.execCommand("copy")
      document.body.removeChild(el)
    },

    // closeDateHinzufuegenModal() {
    //   this.$bvModal.hide(`dateiHinzufuegen`)
    // },

    // makeToast (toast) {
    //   this.$bvToast.toast(toast.msg, {
    //     title: toast.title,
    //     toaster: 'b-toaster-bottom-full',
    //     variant: toast.variant,
    //     autoHideDelay: 7000,
    //     appendToast: true,
    //     solid: true
    //   })
    // }
  },

  data () {
    return {
      // fields: [
      //   { key: "datum", sortable: true, label: "Datum", formatter: value => { return moment(String(value)).format('DD.MM.YYYY')} },
      //   { key: "name", sortable: true, label: "Name" },
      //   { key: "link", label: "Download Link"},
      //   { key: "buttons", label: "Bearbeiten" }
      // ],
      // email: {
      //     fromAdresse: "no-reply@eckerts.de",
      //     fromName: "Eckerts Wacholder Brennerei GmbH",
      //     toAdresse: "",
      //     toName: "",
      //     subject: "",
      //     content: ""
      // },
      downloadName: "",
      file: null
    }
  },
  mounted () {
    this.getPrintdataFileList()
  }
})
</script>

<style>

</style>
import { Hersteller, Herstellerartikel, Lieferant, Lieferantartikel, Lieferherstartikel, Materialartikel, Materiallieferartikel } from "@/models/LagerMaterialModels"

export type State = {
  materialartikel: Array<Materialartikel>,
  materiallieferantartikel: Array<Materiallieferartikel>,
  lieferantartikel: Array<Lieferantartikel>,
  lieferanten: Array<Lieferant>,
  lieferherstartikel: Array<Lieferherstartikel>,
  herstellerartikel: Array<Herstellerartikel>,
  hersteller: Array<Hersteller>,
}

export const state: State = {
  materialartikel: new Array<Materialartikel>(),
  materiallieferantartikel: new Array<Materiallieferartikel>(),
  lieferantartikel: new Array<Lieferantartikel>(),
  lieferanten: new Array<Lieferant>(),
  lieferherstartikel: new Array<Lieferherstartikel>(),
  herstellerartikel: new Array<Herstellerartikel>(),
  hersteller: new Array<Hersteller>(),
}

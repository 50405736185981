import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ad3aeb54"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "produktionContent",
  class: "mainContent"
}
const _hoisted_2 = { class: "row mb-4" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "rwo mb-2" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = {
  key: 0,
  class: "row mb-4"
}
const _hoisted_7 = { class: "col col-sm-5 col-md-4 col-lg-3" }
const _hoisted_8 = {
  class: "col col-sm-7 col-md-8 col-lg-9",
  sm: "7",
  md: "8",
  lg: "9"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Produktionsstatus = _resolveComponent("Produktionsstatus")!
  const _component_PlanungMaschineHeute = _resolveComponent("PlanungMaschineHeute")!
  const _component_PlanungPalettenProStunde = _resolveComponent("PlanungPalettenProStunde")!
  const _component_PlanungPalettenProStundeChartCard = _resolveComponent("PlanungPalettenProStundeChartCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Produktionsstatus)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.produktionsstatusFetched)
          ? (_openBlock(), _createBlock(_component_PlanungMaschineHeute, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.produktionsstatusFetched && _ctx.palettenProStunde != null && _ctx.palettenProStunde.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_PlanungPalettenProStunde)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_PlanungPalettenProStundeChartCard)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
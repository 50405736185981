import { MutationTree } from "vuex";
import { State } from "./state";
import { Produktionsstatus, Maschinenplanung } from "@/models/ProduktionsstatusModels";


export enum MutationTypes {
  GetProduktionsstatusSuccess = "GETPRODUKTIONSSTATUSUCCESS",
  SetMaschinenplanungHeute = "SETMASCHINENPLANUNGHEUTE"
}


export type Mutations = {
  [MutationTypes.GetProduktionsstatusSuccess](state: State, data: Array<Produktionsstatus>): void
  [MutationTypes.SetMaschinenplanungHeute](state: State, data: Array<Maschinenplanung>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.GetProduktionsstatusSuccess](state, data) {
    state.produktionsstatus = data
  },
  
  [MutationTypes.SetMaschinenplanungHeute](state, data) {
    state.maschinenPlanungHeute = data
  }
}
import { MutationTree } from "vuex";
import { State } from "./state";
import { Mitarbeiter, LagerUser } from "@/models/MitarbeiterModels";


export enum MutationTypes {
  LoginLagerSuccess = "LOGINLAGERSUCCESS",
  RefreshLoginLagerSuccess = "REFRESHLOGINLAGERSUCCESS",
  LogoutLager = "LOGOUTLAGER",
  GetMitarbeiterSuccess = "GETMIRARBEITERSUCCESS"
}


export type Mutations = {
  [MutationTypes.LoginLagerSuccess](state: State, data: LagerUser): void
  [MutationTypes.RefreshLoginLagerSuccess](state: State, data: LagerUser): void
  [MutationTypes.LogoutLager](state: State): void
  [MutationTypes.GetMitarbeiterSuccess](state: State, data: Mitarbeiter): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.LoginLagerSuccess](state, data) {
    state.lagerUser = data;
  },

  [MutationTypes.RefreshLoginLagerSuccess](state, data) {
    state.lagerUser = data;
  },

  [MutationTypes.LogoutLager](state) {
    state.lagerUser = new LagerUser();
  },
  
  [MutationTypes.GetMitarbeiterSuccess](state, data) {
    state.mitarbeiter = data;
  },
  
}
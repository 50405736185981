import { MutationTree } from "vuex";
import { State } from "./state";
import { KeyValuePair, Planung } from "@/models/PlanungModels";


export enum MutationTypes {
  GetPlanungSuccess = "GETPLANUNGSUCCESS",
  GetPalettenProStunde = "GETPALETTENPROSTUNDE",
  ClearPlanung = "CLEARPLANUNG"
}


export type Mutations = {
  [MutationTypes.GetPlanungSuccess](state: State, data: Array<Planung>): void
  [MutationTypes.GetPalettenProStunde](state: State, data: Array<KeyValuePair>): void
  [MutationTypes.ClearPlanung](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.GetPlanungSuccess] (state, data) {
    state.planung = data
  },

  [MutationTypes.GetPalettenProStunde] (state, data) {
    state.palettenProStunde = data
  },
  
  [MutationTypes.ClearPlanung] (state) {
    state.planung = new Array<Planung>();
    state.palettenProStunde = new Array<KeyValuePair>();
  }
}
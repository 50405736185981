<template>
  <div id="planungPalettenProStundeChartCard" class="card status-text-size p-3 border-0 shadow-sm rounded-0">
    <div class="card-header bg-white border-0">
      <p class="text-secondary mb-0">Maschinen Effizienz</p>
    </div>
    <div v-if="planungLoading" class="text-center">
      <LoadingSpinner variant="danger"></LoadingSpinner>
    </div>
    <div v-else class="px-3 py-2">
      <div>
        <PlanungPalettenProStundeChart :labels="labels" :values="values"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

import PlanungPalettenProStundeChart from './PlanungPalettenProStundeChart.vue'

import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "planungPalettenProStundeChartCard",
  components: {
    PlanungPalettenProStundeChart,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const palettenProStunde = computed(() => store.getters.palettenProStunde)
    const planungLoading = computed(() => store.getters.status.planungLoading)

    const labels = computed(() => {
      let l = new Array<string>()
      palettenProStunde.value.forEach(p => {
        l.push(p.key.slice(8, 13))
      })

      let first = palettenProStunde.value[0]
      let firstZeit = parseInt(first.key.slice(0, 5))
      let keyZeit = ('0' + firstZeit).slice(-2) + ':00'
      l.unshift(keyZeit)
      return l
    })
    
    const values = computed(() => {
      let l = new Array<number>()
      palettenProStunde.value.forEach(p => {
        l.push(p.value)
      })

      let first = palettenProStunde.value[0]
      l.unshift(first.value)
      return l
    })

    return {
      store,
      palettenProStunde,
      planungLoading,
      labels,
      values
    }
  },
});
</script>

<style lang="scss" scoped>
#planungPalettenProStundeChartCard {
  height: 100%;
}
</style>
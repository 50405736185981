<template>
  <div id="lagerLayout">
    <div class="row">
      <!-- <b-col cols="2">
        <b-row v-for="row in getBlockA" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
          <b-col>
            <div class="float-left reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
            <div v-for="platz in row.stellplaetze" :key="platz.id">
              <LagerStellplatz :platz="platz" />
            </div>
          </b-col>
        </b-row>
      </b-col> -->

      <div class="col-7">
        <div class="mb-3">
          <div class="row" v-for="row in getBlockG" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
            <div class="col">
              <div class="float-left reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="row" v-for="row in getBlockD" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
            <div class="col">
              <div class="float-left reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="row" v-for="row in getBlockC" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
            <div class="col">
              <div class="float-left reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <!-- <b-row v-for="row in getBlockB" :key="row.id" :class="{ 'inActive': row.aktiv == 0}"> -->
          <div class="row" v-for="row in getBlockB" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
            <div class="col">
              <div class="float-left reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
              <div v-for="platz in row.stellplaetze" :key="platz.id">
                <LagerStellplatz :platz="platz" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col">
                <div class="float-left reihe-name">000 | {{getAnzahlPalettenInPuffer}}: </div>
                <div v-for="palette in lagerpuffer" :key="palette.id">
                  <LagerpufferPalette :palette="palette" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="float-left reihe-name">PPS | {{getAnzahlPalettenInProduktionspuffer}}: </div>
                <div v-for="palette in produktionspuffer" :key="palette.id">
                  <ProduktionspufferPalette :palette="palette" />
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="alert alert-primary">
              <div class="row">
                <div class="col-10 text-left">
                  <strong>Lagerbestand {{lagerartikel.length}} Paletten / {{fertigwarenStellplaetze}} Stellplätze ({{getLagerAuslastung}})</strong><br>
                </div>
                <div class="col-2 text-right">
                  <!-- <div v-if="lagerStatus.getLager || lagerStatus.getArtikelImLager || lagerStatus.getLagerplaetzeDate" class="m-2">
                    <LoadingSpinner />
                  </div>
                  <button v-else class="btn btn-outline-dark m-1 sync-button"  @click="$emit('updateLagerstatus')">
                    <font-awesome-icon size="lg" icon="sync" />
                  </button>                   -->
                </div>
              </div>

              <div class="row">
                <div class="col-10 text-left">
                  A: {{getAnzahlPalettenBlockA}} | B: {{getAnzahlPalettenBlockB}} | C: {{getAnzahlPalettenBlockC}} | D: {{getAnzahlPalettenBlockD}} | E: {{getAnzahlPalettenBlockE}} | F: {{getAnzahlPalettenBlockF}} | G: {{getAnzahlPalettenBlockG}} | TST: {{getAnzahlPalettenBlockTST}}
                </div>
                <div class="col-2 text-right">
                  {{aktualisiertam}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-5">
        <div class="row" v-for="row in getBlockE" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
          <div class="col">
            <div class="float-left reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
            <div v-for="platz in row.stellplaetze" :key="platz.id">
              <LagerStellplatz :platz="platz" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from '@/store'

import { Lagerreihe, Listeartikel } from '@/models/LagerModels';
import { mapGetters } from 'vuex'
import LagerStellplatz from './LagerStellplatz.vue'
import LagerpufferPalette from './LagerpufferPalette.vue'
import ProduktionspufferPalette from './ProduktionspufferPalette.vue'
import LoadingSpinner from "@/components/LoadingSpinner.vue";


export default defineComponent({
  name: "LagerLayout",
  components: {
    LagerStellplatz,
    LagerpufferPalette,
    ProduktionspufferPalette,
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const lager = computed(() => store.getters.lager);
    const lagerartikel = computed(() => store.getters.lagerartikel);

    const fertigwarenStellplaetze = computed(() => store.getters.fertigwarenStellplaetze)
    const lagerpuffer = computed(() => store.getters.lagerpuffer)
    const produktionspuffer = computed(() => store.getters.produktionspuffer)

    return {
      store,
      lager,
      lagerartikel,
      fertigwarenStellplaetze,
      lagerpuffer,
      produktionspuffer
    }
  },

  props: {
    aktualisiertam: {
      type: String,
      required: true
    }
  },
  computed: {
    getBlockA() {
      return this.lager.filter((el: Lagerreihe) => el.block == "A").reverse()
    },
    getBlockB() {
      return this.lager.filter((el: Lagerreihe) => el.block == "B").reverse()
    },
    getBlockC() {
      return this.lager.filter((el: Lagerreihe) => el.block == "C").reverse()
    },
    getBlockD() {
      return this.lager.filter((el: Lagerreihe) => el.block == "D").reverse()
    },
    getBlockE() {
      return this.lager.filter((el: Lagerreihe) => el.block == "E").reverse()
    },
    getBlockG() {
      return this.lager.filter((el: Lagerreihe) => el.block == "G").reverse()
    },
    getAnzahlPalettenBlockA() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('A')).length
    },
    getAnzahlPalettenBlockB() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('B')).length
    },
    getAnzahlPalettenBlockC() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('C')).length
    },
    getAnzahlPalettenBlockD() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('D')).length
    },
    getAnzahlPalettenBlockE() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('E')).length
    },
    getAnzahlPalettenBlockF() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('F')).length
    },
    getAnzahlPalettenBlockG() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('G')).length
    },
    getAnzahlPalettenBlockTST() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('TST')).length
    },
    getLagerAuslastung() {
      return `${Math.floor(this.lagerartikel.length / this.fertigwarenStellplaetze * 100)}%`
    },
    getAnzahlPalettenInPuffer() {
      return this.lagerpuffer.length < 10 ? `0${this.lagerpuffer.length}` : this.lagerpuffer.length
    },
    getAnzahlPalettenInProduktionspuffer() {
      return this.produktionspuffer.length < 10 ? `0${this.produktionspuffer.length}` : this.produktionspuffer.length
    }
  },

  methods: {
    reiheNummer(row: Lagerreihe) {
      return row.reihe < 10 ? `0${row.reihe}` : row.reihe
    },
    getAnzahlPalettenInReihe(row: Lagerreihe) {
      let count = 0
      row.stellplaetze.forEach(el => {
        if (el.artikel != null) {
          count++
        }
      })

      return count < 10 ? `0${count}` : count
    },
  }
});
</script>

<style scoped>
.reihe-name {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 0.7em;
}
</style>
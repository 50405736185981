<template>
  <div class="row text-start">
    <div class="col-1">{{ maschine.maschinename }}</div>
    
    <div class="col-1 text-danger" v-if="maschine.maschinenzustand == 'Fehler'">{{ maschine.maschinenzustand }}</div>
    <div class="col-1 text-warning" v-else-if="maschine.maschinenzustand == 'Muster'">{{ maschine.maschinenzustand }}</div>
    <div class="col-1 text-success" v-else-if="maschine.maschinenzustand == 'Produktion'">{{ maschine.maschinenzustand }}</div>
    <div class="col-1" v-else>{{ maschine.maschinenzustand }}</div>
    
    <div class="col-2">{{ maschine.herstellungid }}</div>
    <div class="col-5">{{ maschine.artikel }}</div>
    <div class="col-1">{{ maschine.landnr }}</div>
    <div class="col-2">{{ mdateConverter }}</div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { Produktionsstatus } from "@/models/ProduktionsstatusModels"
// import { useStore } from '@/store'

export default defineComponent({
  name: 'ProduktionsstatusRow',
  props: {
    maschine: {
      type: Object as PropType<Produktionsstatus>,
      default: new Produktionsstatus()
    }
  },

  setup (props) {
    const mdateConverter = computed(() => {
      const mdate = new Date(props.maschine.mdate);
      return ("0" + mdate.getDate()).slice(-2) + "." + ("0" + (mdate.getMonth() + 1)).slice(-2) + "." + mdate.getFullYear() + " " + ("0" + mdate.getHours()).slice(-2) + "." + ("0" + (mdate.getMinutes() + 1)).slice(-2);
    });

    const uhrzeit = "";

    return {
      mdateConverter,
      uhrzeit
    }
  }, 
})
</script>
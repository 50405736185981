import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Maschine } from "@/models/MaschinenModels"

export type Getters = {
  maschinen(state: State): Array<Maschine>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  maschinen(state) {
    return state.maschinen;
  }
}

import { ActionContext, ActionTree } from "vuex";
import LagerService from '@/service/LagerService'

import { RootState } from '@/store';

import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";

import { Actions as CommonActions, ActionTypes as CommonActionTypes } from "../../Common/actions";

import { PlanungMaschinenHeute, Planung } from "@/models/PlanungModels"



export enum ActionTypes {
  GetPlanung = "GETPLANUNG",
  UpdateGetPlanung = "UPDATEGETPLANUNG",
  ClearPlanung = "CLEARPLANUNG"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetPlanung](context: ActionArguments, maschinename: string): Promise<void>,
  [ActionTypes.UpdateGetPlanung](context: ActionArguments, maschinename: string): Promise<void>,
  [ActionTypes.ClearPlanung](context: ActionArguments): void,
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetPlanung]({ commit, dispatch, rootGetters }, maschinename) {
    const status = rootGetters.status;
    status.planungLoading = true
    dispatch(CommonActionTypes.SetStatus, status);
    dispatch(ActionTypes.ClearPlanung, undefined);


    const listeppsidmaschinen = ["Vodka", "Gin", "Kirsch", "Putinoff", "Rum"];

    if (listeppsidmaschinen.includes(maschinename)) {
      return LagerService.getMaschinenPlanungHeute(maschinename)
        .then(res => {
          status.planungLoading = false
          dispatch(CommonActionTypes.SetStatus, status);

          const result: PlanungMaschinenHeute = res.data
          commit(MutationTypes.GetPlanungSuccess, result.listePlanungResource)
          commit(MutationTypes.GetPalettenProStunde, result.anzahlListeartikelProStunde)
        })
        .catch(error => {
          status.planungLoading = false
          // console.log(error);
          if (error.message === 'Network Error') {
            status.planungErrorMsg = error.message
          } else if (error.response?.status == 401){
            status.planungErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
          } else {
            status.planungErrorMsg = error
          }
          
          dispatch(CommonActionTypes.SetStatus, status);
        })
    }
    else {
      return LagerService.getMaschinenPlanungHeuteListeartikel(maschinename)
        .then(res => {
          status.planungLoading = false
          dispatch(CommonActionTypes.SetStatus, status);

          const result: PlanungMaschinenHeute = res.data
          commit(MutationTypes.GetPlanungSuccess, result.listePlanungResource)
          commit(MutationTypes.GetPalettenProStunde, result.anzahlListeartikelProStunde)
        })
        .catch(error => {
          status.planungLoading = false
          // console.log(error);
          if (error.message === 'Network Error') {
            status.planungErrorMsg = error.message
          } else if (error.response?.status == 401){
            status.planungErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
          } else {
            status.planungErrorMsg = error
          }
          
          dispatch(CommonActionTypes.SetStatus, status);
        })
    }
  },

  async [ActionTypes.UpdateGetPlanung]({ commit, dispatch, rootGetters }, maschinename) {
    const status = rootGetters.status;

    const listeppsidmaschinen = ["Vodka", "Gin", "Kirsch", "Putinoff", "Rum"];

    if (listeppsidmaschinen.includes(maschinename)) {
      return LagerService.getMaschinenPlanungHeute(maschinename)
        .then(res => {
          const result: PlanungMaschinenHeute = res.data
          commit(MutationTypes.GetPlanungSuccess, result.listePlanungResource)
          commit(MutationTypes.GetPalettenProStunde, result.anzahlListeartikelProStunde)
        })
        .catch(error => {
          // console.log(error);
          if (error.message === 'Network Error') {
            status.planungErrorMsg = error.message
          } else if (error.response?.status == 401){
            status.planungErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
          } else {
            status.planungErrorMsg = error
          }
          
          dispatch(CommonActionTypes.SetStatus, status);
        })
    }
    else {
      return LagerService.getMaschinenPlanungHeuteListeartikel(maschinename)
        .then(res => {
          const result: PlanungMaschinenHeute = res.data
          commit(MutationTypes.GetPlanungSuccess, result.listePlanungResource)
          commit(MutationTypes.GetPalettenProStunde, result.anzahlListeartikelProStunde)
        })
        .catch(error => {
          // console.log(error);
          if (error.message === 'Network Error') {
            status.planungErrorMsg = error.message
          } else if (error.response?.status == 401){
            status.planungErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
          } else {
            status.planungErrorMsg = error
          }
          
          dispatch(CommonActionTypes.SetStatus, status);
        })
    }
  },

  [ActionTypes.ClearPlanung]({ commit }) {
    commit(MutationTypes.ClearPlanung, undefined)
  }
}

import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Mitarbeiter, LagerUser } from "@/models/MitarbeiterModels";

export type Getters = {
  lagerUser(state: State): LagerUser,
  mitarbeiter(state: State): Mitarbeiter,
}

export const getters: GetterTree<State, RootState> & Getters = {
  lagerUser(state) {
    return state.lagerUser;
  },

  mitarbeiter(state) {
    return state.mitarbeiter;
  }
}

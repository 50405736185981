import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dcbbd3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col me-4" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "reihe-name" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "reihe-name" }
const _hoisted_8 = { class: "row mb-3" }
const _hoisted_9 = { class: "col me-4" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "reihe-name" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "reihe-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LagerStellplatz = _resolveComponent("LagerStellplatz")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getBlockHA, (row) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row pe-2",
            key: row.id,
            style: _normalizeStyle({'background': row.bgColor, 'color': row.textColor})
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(row.block) + _toDisplayString($options.reiheNummer(row)) + " | " + _toDisplayString($options.getAnzahlPalettenInReihe(row)), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.stellplaetze.slice().reverse(), (platz) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: platz.id
                }, [
                  _createVNode(_component_LagerStellplatz, { platz: platz }, null, 8, ["platz"])
                ]))
              }), 128))
            ])
          ], 4))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getBlockHC, (row) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row",
            key: row.id,
            style: _normalizeStyle({'background': row.bgColor, 'color': row.textColor})
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(row.block) + _toDisplayString($options.reiheNummer(row)) + " | " + _toDisplayString($options.getAnzahlPalettenInReihe(row)) + ": ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.stellplaetze, (platz) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: platz.id
                }, [
                  _createVNode(_component_LagerStellplatz, { platz: platz }, null, 8, ["platz"])
                ]))
              }), 128))
            ])
          ], 4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getBlockHB, (row) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row pe-2",
            key: row.id,
            style: _normalizeStyle({'background': row.bgColor, 'color': row.textColor})
          }, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(row.block) + _toDisplayString($options.reiheNummer(row)) + " | " + _toDisplayString($options.getAnzahlPalettenInReihe(row)), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.stellplaetze, (platz) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: platz.id
                }, [
                  _createVNode(_component_LagerStellplatz, { platz: platz }, null, 8, ["platz"])
                ]))
              }), 128))
            ])
          ], 4))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getBlockHD, (row) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row",
            key: row.id,
            style: _normalizeStyle({'background': row.bgColor, 'color': row.textColor})
          }, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(row.block) + _toDisplayString($options.reiheNummer(row)) + " | " + _toDisplayString($options.getAnzahlPalettenInReihe(row)) + ": ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.stellplaetze, (platz) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: platz.id
                }, [
                  _createVNode(_component_LagerStellplatz, { platz: platz }, null, 8, ["platz"])
                ]))
              }), 128))
            ])
          ], 4))
        }), 128))
      ])
    ])
  ], 64))
}
import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Materialartikel } from "@/models/LagerMaterialModels"
import { Flasche, Karton, Kapsel, AnzahlFlaschen, AnzahlKartons, AnzahlKapseln } from "@/models/PpsMaterialModels"

export type Getters = {
  materialArtikelFlaschen(state: State): Array<Materialartikel>
  materialAnzahlFlaschen(state: State): Array<AnzahlFlaschen>
  materialFlaschen(state: State): Array<Flasche>
  materialArtikelKartons(state: State): Array<Materialartikel>
  materialAnzahlKartons(state: State): Array<AnzahlKartons>
  materialKartons(state: State): Array<Karton>
  materialArtikelKapseln(state: State): Array<Materialartikel>
  materialAnzahlKapseln(state: State): Array<AnzahlKapseln>
  materialKapseln(state: State): Array<Kapsel>
}

export const getters: GetterTree<State, RootState> & Getters = {
  materialArtikelFlaschen(state) {
    return state.materialArtikelFlaschen;
  },
  
  materialAnzahlFlaschen(state) {
    return state.materialAnzahlFlaschen;
  },
  
  materialFlaschen(state) {
    return state.materialFlaschen;
  },

  materialArtikelKartons(state) {
    // return state.mat
    return state.materialArtikelKartons;
  },
  
  materialAnzahlKartons(state) {
    return state.materialAnzahlKartons;
  },
  
  materialKartons(state) {
    return state.materialKartons;
  },
  

  materialArtikelKapseln(state) {
    // return state.mat
    return state.materialArtikelKapseln;
  },
  
  materialAnzahlKapseln(state) {
    return state.materialAnzahlKapseln;
  },

  materialKapseln(state) {
    return state.materialKapseln;
  },
}

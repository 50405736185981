<template>
  <div id="verwaltungDownloadContent" class="p-4 mainContent">
    <PrintdataCard />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import PrintdataCard from './PrintdataCard.vue'

export default defineComponent({
  name: 'VerwaltungDownloadContent',
  components: {PrintdataCard}
})
</script>

<style scoped>
#verwaltungDownloadContent {
  background: rgb(242, 243, 248);
}
</style>
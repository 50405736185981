<template>
  <div id="material">
    <div v-if="loginlagerLoading" class="text-center m-5">
      <LoadingSpinner />
    </div>

    <div v-else-if="loggedIn && lagerUser && lagerUser.lagerUser.verwaltung == 1" class="w-100">
      <!-- <MaterialSidebar @setContent="setContentArea"/> -->

      <MaterialFlaschenContent v-if="content == 'flaschen'" />
      <!-- <MaterialartikelFlaschenContent v-else-if="content == 'materialartikel-flaschen'" />

      <MaterialKartonsContent v-else-if="content == 'kartons'"/>
      <MaterialartikelKartonsContent v-else-if="content == 'materialartikel-kartons'" />

      <MaterialKapselnContent v-else-if="content == 'kapseln'"/>
      <MaterialartikelKapselnContent v-else-if="content == 'materialartikel-kapseln'" />

      <MaterialartikelContent v-else-if="content == 'materialartikel'" />
      <LieferantenContent v-else-if="content == 'lieferanten'" />
      <HerstellerContent v-else-if="content == 'hersteller'" /> -->
      <div v-else class="m-4 float-center w-100">
        <div class="alert alert-info" role="alert">Bitte wählen Sie eine Seite aus.</div>
      </div> 
    </div>

    <div v-else class="m-4 float-center w-100">
      <div class="alert alert-danger" role="alert">Sie haben keinen Zugriff auf diese Daten</div>
    </div> 
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'
import { ActionTypes as MaterialartikelActionTypes } from '@/store/modules/Lager/LagerMaterial/actions'

// import MaterialSidebar from '@/components/Materialbestellung/Content/MaterialSidebar.vue'
import MaterialFlaschenContent from '@/components/Materialbestellung/Content/MaterialFlaschenContent.vue'
// import MaterialartikelFlaschenContent from '@/components/Materialbestellung/Content/MaterialartikelFlaschenContent.vue'
// import MaterialartikelKartonsContent from '@/components/Materialbestellung/Content/MaterialartikelKartonsContent.vue'
// import MaterialartikelKapselnContent from '@/components/Materialbestellung/Content/MaterialartikelKapselnContent.vue'
// import MaterialKartonsContent from '@/components/Materialbestellung/Content/MaterialKartonsContent.vue'
// import MaterialKapselnContent from '@/components/Materialbestellung/Content/MaterialKapselnContent.vue'
// import MaterialartikelContent from '@/components/Materialbestellung/Content/MaterialartikelContent.vue'
// import LieferantenContent from '@/components/Materialbestellung/Content/LieferantenContent.vue'
// import HerstellerContent from '@/components/Materialbestellung/Content/HerstellerContent.vue'

export default defineComponent({
  name: 'Material',
  components: {
    LoadingSpinner,
    // MaterialSidebar,
    MaterialFlaschenContent,
    // MaterialartikelFlaschenContent,
    // MaterialartikelKartonsContent,
    // MaterialartikelKapselnContent,
    // MaterialKartonsContent,
    // MaterialKapselnContent,
    // MaterialartikelContent,
    // LieferantenContent,
    // HerstellerContent
  },

  setup () {
    const store = useStore();

    const lagerUser = computed(() => store.getters.lagerUser)
    const loginlagerLoading = computed(() => store.getters.status.loginlagerdbLoading)
    const materialartikel = computed(() => store.getters.materialartikel)
    const materialArtikelFlaschen = computed(() => store.getters.materialArtikelFlaschen)

    return {
      store,
      lagerUser,
      loginlagerLoading,
      materialartikel,
      materialArtikelFlaschen
    }
  },

  methods: {
    loginLagerDB () {
      this.store.dispatch(MitarbeiterActionTypes.LoginLagerDB, undefined)
        .then(() => {
          this.loggedIn = true
        })
    },

    fetchMaterialartikel () {
      this.store.dispatch(MaterialartikelActionTypes.fetchMaterialartikel, undefined)
    },

    setContentArea(value: string) {
      this.content = value
    }
  },

  data () {
    return {
      loggedIn: false,
      content: 'flaschen'
    }
  },

  mounted () {
    if(!localStorage.getItem('lagerUser')) {
      this.loginLagerDB()
    }
    else {
      this.loggedIn = true
    }

    if(this.materialartikel.length === 0) {
      this.fetchMaterialartikel()
    }
  }
})
</script>

<style lang="scss">
// #material,
// .contentArea {
//   height: 100vh;
// }

// .backgroundColor {
//   background: rgb(242, 243, 248);
// }

</style>
<template>
  <div class="row text-left">
    <div class="col-2">{{ rohstoff.rohstoffname }}</div>
    <div class="col-2">{{ solldatum }} - 24 Uhr</div>
    <div class="col-2">{{ istdatum }}</div>
    <div class="col-1">{{ Math.trunc(rohstoff.litersoll) }}</div>
    <div class="col-1">{{ Math.trunc(rohstoff.literist) }}</div>
    <div class="col-1">{{ literDifferenz }}</div>
    <div class="col-1">{{ Math.trunc(rohstoff.lasoll) }}</div>
    <div class="col-1">{{ Math.trunc(rohstoff.laist) }}</div>
    <div class="col-1">{{ laDifferenz }}</div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";

import {Bestandskontrolle} from "@/models/BestandskontrolleModels"

export default defineComponent({
  name: "ProduktionsstatusRow",
  props: {
    rohstoff: {
      type: Object as PropType<Bestandskontrolle>,
      default: new Bestandskontrolle()
    }
  },

  data() {
    return {
      solldatum: "",
      istdatum: "",
      literDifferenz: 0,
      laDifferenz: 0
    };
  },

  mounted() {
    this.literDifferenz = Math.trunc(
      this.rohstoff.literist - this.rohstoff.litersoll
    );

    this.laDifferenz = Math.trunc(this.rohstoff.laist - this.rohstoff.lasoll);

    const solldatum = new Date(this.rohstoff.datumsoll);
    this.solldatum =
      ("0" + solldatum.getDate()).slice(-2) +
      "." +
      ("0" + (solldatum.getMonth() + 1)).slice(-2) +
      "." +
      solldatum.getFullYear();

    const istdatum = new Date(this.rohstoff.datumist);
    this.istdatum =
      ("0" + istdatum.getDate()).slice(-2) +
      "." +
      ("0" + (istdatum.getMonth() + 1)).slice(-2) +
      "." +
      istdatum.getFullYear() +
      " " +
      ("0" + istdatum.getHours()).slice(-2) +
      ":" +
      ("0" + istdatum.getMinutes()).slice(-2);
  }
});
</script>

<style scoped>
.striped:nth-of-type(even) {
  background: rgba(0, 0, 0, 0.05);
}

.status-text-size {
  font-size: 12px;
}

@media screen and (max-width: 1200px) {
  .status-text-size {
    font-size: 11px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 11px;
  }
}

@media screen and (max-width: 930px) {
  .status-text-size {
    font-size: 11px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 8px;
  }
}

@media screen and (max-width: 730px) {
  .status-text-size {
    font-size: 9px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 5px;
  }
}

@media screen and (max-width: 430px) {
  .status-text-size {
    font-size: 7px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 3px;
  }
}
</style>
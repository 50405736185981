<template>
  <div id="produktion">
    <div v-if="loginlagerLoading" class="text-center">
      <LoadingSpinner />
    </div>

    <div v-else class="w-100">

      <LoadingSpinner v-if="loading" :text="'...Daten werden geladen...'" />

      <div v-else class="row">
        <div v-for="maschine in maschinen" :key="maschine.id" class="col-3 mb-3">
          <MaschineLeistungCard :maschine="maschine" :maschinenplanung="maschinenPlanungHeute.find(m => m.maschinennr == maschine.maschinenr)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'

import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'
import { ActionTypes as MaschinenActionTypes } from "@/store/modules/Pps/Maschinen/actions";
import { ActionTypes as ProduktionsstatusActionTypes } from '@/store/modules/Pps/Produktionsstatus/actions';

import ProduktionContent from '../Content/ProduktionContent.vue'
import MaschineLeistungCard from "../Produktionsleistung/MaschineLeistungCard.vue";

export default defineComponent({
  name: 'Produktionsleistung',

  components: {
    ProduktionContent,
    MaschineLeistungCard,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const lagerUser = computed(() => store.getters.lagerUser)
    const loginlagerLoading = computed(() => store.getters.status.loginlagerdbLoading)

    const loading = computed(() => store.getters.status.maschinenstatusLoading || store.getters.status.maschinenLoading)
    const errorMsg = computed(() => store.getters.status.maschinenstatusErrorMsg)

    const maschinen = computed(() => store.getters.maschinen.filter(m => m.maschinenr != null && m.maschinenr >= 1 && m.maschinenr <= 7).sort((a, b) => Number(a.maschinenr) - Number(b.maschinenr)))

    function loadMaschinen() {
      store.dispatch(MaschinenActionTypes.GetMaschinen, undefined)
    }

    const maschinenPlanungHeute = computed(() => store.getters.maschinenPlanungHeute)


    const updateStatusInterval = ref();
    const updateDatetime = ref<Date>(new Date());
    const updateDatetimeConverter = computed(() => {
      return ("0" + (updateDatetime.value.getHours())).slice(-2) + ":" + ("0" + (updateDatetime.value.getMinutes())).slice(-2)
    })

    function getMaschinenPlanungHeute() {
      store.dispatch(ProduktionsstatusActionTypes.getMaschinenPlanungHeute, undefined)
      .then(() => {
        if (errorMsg.value != "") {
          updateDatetime.value = new Date();
        }
      })
    }
    
    function updateMaschinenPlanungHeute() {
      store.dispatch(ProduktionsstatusActionTypes.updateMaschinenPlanungHeute, undefined)
      .then(() => {
        if (errorMsg.value == "") {
          updateDatetime.value = new Date();
        }
      })
    }

    return {
      store,

      loading,

      lagerUser,
      loginlagerLoading,

      maschinen,
      loadMaschinen,

      maschinenPlanungHeute,

      updateStatusInterval,
      updateDatetime,
      updateDatetimeConverter,
      getMaschinenPlanungHeute,
      updateMaschinenPlanungHeute,
    }
  },

  methods: {
    loginLagerDB () {
      this.store.dispatch(MitarbeiterActionTypes.LoginLagerDB, undefined)
        .then(() => {
          this.loggedIn = true
        })
    },
  },


  data () {
    return {
      loggedIn: false
    }
  },

  mounted () {
    if(!localStorage.getItem('lagerUser')){
      this.loginLagerDB()
    }
    else {
      this.loggedIn = true
    }

    this.loadMaschinen()
    this.getMaschinenPlanungHeute();

    this.updateStatusInterval = setInterval(() => {
      this.updateMaschinenPlanungHeute();
    }, 1 * 60 * 1000);
  },
  
  beforeUnmount () {
    clearInterval(this.updateStatusInterval)
  }
})
</script>

<style lang="scss" scoped>
// #produktion {
//   height: 100vh;
//   min-height: 100vh;
// }

</style>
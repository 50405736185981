export class Flasche {
  id: number
  materialbestellungid: number
  materiallieferungid: number
  materiallieferposid: number
  liefernr: string
  adate: Date
  mdate: Date
  mitarbeiter: string
  barcode: string
  artikelnr: string
  artikel: string
  anzahlstueck: number
  lieferdatum: Date
  produktiondatum: Date
  scanndatum: Date
  maschineid: number
  produktionsplanid: number
  maschinenr: number
  entfernt: number
  lagerdatum: Date
  materialartikelid: number
  lagerabgangdatum: Date
  lagerabgangmaschineid: number
  inventurdatum: Date
  lagerplatzid: number
  lagerplatzname: string

  constructor () {
    this.id = 0
    this.materialbestellungid = 0
    this.materiallieferungid = 0
    this.materiallieferposid = 0
    this.liefernr = ""
    this.adate = new Date()
    this.mdate = new Date()
    this.mitarbeiter = ""
    this.barcode = ""
    this.artikelnr = ""
    this.artikel = ""
    this.anzahlstueck = 0
    this.lieferdatum = new Date()
    this.produktiondatum = new Date()
    this.scanndatum = new Date()
    this.maschineid = 0
    this.produktionsplanid = 0
    this.maschinenr = 0
    this.entfernt = 0
    this.lagerdatum = new Date()
    this.materialartikelid = 0
    this.lagerabgangdatum = new Date()
    this.lagerabgangmaschineid = 0
    this.inventurdatum = new Date()
    this.lagerplatzid = 0
    this.lagerplatzname = ""
  }
}

export class Karton {
  id: number
  materialbestellungid: number
  materiallieferungid: number
  materiallieferposid: number
  liefernr: string
  adate: Date
  mdate: Date
  mitarbeiter: string
  barcode: string
  artikelnr: string
  artikel: string
  anzahlstueck: number
  lieferdatum: Date
  produktiondatum: Date
  scanndatum: Date
  maschinenr: number
  entfernt: number
  maschineid: number
  produktionsplanid: number
  lagerdatum: Date
  materialartikelid: number
  lagerabgangdatum: Date
  lagerabgangmaschineid: number
  inventurdatum: Date
  lagerplatzid: number
  lagerplatzname: string

  constructor () {
    this.id = 0
    this.materialbestellungid = 0
    this.materiallieferungid = 0
    this.materiallieferposid = 0
    this.liefernr = ""
    this.adate = new Date()
    this.mdate = new Date()
    this.mitarbeiter = ""
    this.barcode = ""
    this.artikelnr = ""
    this.artikel = ""
    this.anzahlstueck = 0
    this.lieferdatum = new Date()
    this.produktiondatum = new Date()
    this.scanndatum = new Date()
    this.maschinenr = 0
    this.entfernt = 0
    this.maschineid = 0
    this.produktionsplanid = 0
    this.lagerdatum = new Date()
    this.materialartikelid = 0
    this.lagerabgangdatum = new Date()
    this.lagerabgangmaschineid = 0
    this.inventurdatum = new Date()
    this.lagerplatzid = 0
    this.lagerplatzname = ""
  }
}

export class Kapsel {
  id: number
  materialbestellungid: number
  materiallieferungid: number
  materiallieferposid: number
  liefernr: string
  adate: Date
  mdate: Date
  mitarbeiter: string
  barcode: string
  artikelnr: string
  artikel: string
  anzahlstueck: number
  lieferdatum: Date
  produktiondatum: Date
  scanndatum: Date
  maschinenr: number
  entfernt: number
  maschineid: number
  produktionsplanid: number
  lagerdatum: Date
  materialartikelid: number
  lagerabgangdatum: Date
  lagerabgangmaschineid: number
  inventurdatum: Date
  lagerplatzid: number
  lagerplatzname: string

  constructor () {
    this.id = 0
    this.materialbestellungid = 0
    this.materiallieferungid = 0
    this.materiallieferposid = 0
    this.liefernr = ""
    this.adate = new Date()
    this.mdate = new Date()
    this.mitarbeiter = ""
    this.barcode = ""
    this.artikelnr = ""
    this.artikel = ""
    this.anzahlstueck = 0
    this.lieferdatum = new Date()
    this.produktiondatum = new Date()
    this.scanndatum = new Date()
    this.maschinenr = 0
    this.entfernt = 0
    this.maschineid = 0
    this.produktionsplanid = 0
    this.lagerdatum = new Date()
    this.materialartikelid = 0
    this.lagerabgangdatum = new Date()
    this.lagerabgangmaschineid = 0
    this.inventurdatum = new Date()
    this.lagerplatzid = 0
    this.lagerplatzname = ""
  }
}


export class AnzahlFlaschen {
  materialartikelid: string;
  artikel: string;
  anzahlPaletten: number;
  anzahlFlaschen: number;

  constructor(materialartikelid: string, artikel: string, anzahlPaletten: number, anzahlFlaschen: number) {
    this.materialartikelid = materialartikelid
    this.artikel = artikel
    this.anzahlPaletten = anzahlPaletten
    this.anzahlFlaschen = anzahlFlaschen
  }
}


export class AnzahlKartons {
  materialartikelid: string;
  artikel: string;
  anzahlPaletten: number;
  anzahlKartons: number;

  constructor(materialartikelid: string, artikel: string, anzahlPaletten: number, anzahlKartons: number) {
    this.materialartikelid = materialartikelid
    this.artikel = artikel
    this.anzahlPaletten = anzahlPaletten
    this.anzahlKartons = anzahlKartons
  }
}

export class AnzahlKapseln {
  materialartikelid: string;
  artikel: string;
  anzahlPaletten: number;
  anzahlKapseln: number;

  constructor(materialartikelid: string, artikel: string, anzahlPaletten: number, anzahlKapseln: number) {
    this.materialartikelid = materialartikelid
    this.artikel = artikel
    this.anzahlPaletten = anzahlPaletten
    this.anzahlKapseln = anzahlKapseln
  }
}
<template>
  <div id="herstellungContent" class="mainContent">
    <div class="row">
      <div class="col">
        <Bestandskontrolle />
      </div>
    </div>
    <div v-if="bestandskontrolleLoading && bestandskontrolleTage !== null" class="text-center">
      <LoadingSpinner />
    </div>
    <div v-else>
      <div class="row" v-for="(alkohol, index) in bestandskontrolleTage" :key="index">
        <!-- <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8"> -->
        <div class="col">
          <BestandskontrolleTageChartCard :alkohol="alkohol" class="mb-3"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

import { ActionTypes as BestandskontrolleActionTypes } from '@/store/modules/Lager/Bestandskontrolle/actions'

import Bestandskontrolle from './Bestandskontrolle.vue'
import BestandskontrolleTageChartCard from './BestandskontrolleTageChartCard.vue'

export default defineComponent({
  name: 'HerstellungContent',
  components: {
    Bestandskontrolle,
    BestandskontrolleTageChartCard,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const bestandskontrolleTage = computed(() => store.getters.bestandskontrolleTage)
    const bestandskontrolleLoading = computed(() => store.getters.status.bestandskontrolleLoading)

    return {
      store,
      bestandskontrolleTage,
      bestandskontrolleLoading
    }
  },

  methods: {
    getBestandTage (tage: number) {
      this.store.dispatch(BestandskontrolleActionTypes.GetBestandskontrolleTage, tage)
    }
  },
  mounted () {
    this.getBestandTage(30)
  }

})
</script>

<style lang="scss" scoped>
#herstellungContent {
  background: rgb(242, 243, 248);
}
</style>
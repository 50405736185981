export class KeyValuePair {
  key: string
  value: number
  
  constructor (key: string, value: number) {
    this.key = key
    this.value = value
  }
}

export class Planung {
  id: number
  datum: Date
  artikelnr: string
  artikel: string
  landnr: string
  landkz: string
  anzahlkartons: number
  anzahlflaschen: number
  text: string
  gesamtanzahl: number
  vorratanzahl: number
  maschine: string
  palettenfaktor: number
  arbeitsstunden: number
  anzahlmitarbeiter: number
  priority: number
  adate: Date
  mdate: Date
  mitarbeitername: string
  einheit: string
  herstellungid: number
  maschinenfuehrer: string
  tankid: number
  status: number
  charge: string
  eas: number
  kw: number
  etiketttype: number
  gesamtanzahlIst: number
  banderole: number
  kommissionid: number
  banderolenr: string
  startzeit: Date | null
  endzeit: Date | null
  prodDauerInMinuten: number

  constructor() {
    this.id = 0
    this.datum = new Date()
    this.artikelnr = ""
    this.artikel = ""
    this.landnr = ""
    this.landkz = ""
    this.anzahlkartons = 0
    this.anzahlflaschen = 0
    this.text = ""
    this.gesamtanzahl = 0
    this.vorratanzahl = 0
    this.maschine = ""
    this.palettenfaktor = 0
    this.arbeitsstunden = 0
    this.anzahlmitarbeiter = 0
    this.priority = 0
    this.adate = new Date()
    this.mdate = new Date()
    this.mitarbeitername = ""
    this.einheit = ""
    this.herstellungid = 0
    this.maschinenfuehrer = ""
    this.tankid = 0
    this.status = 0
    this.charge = ""
    this.eas = 0
    this.kw = 0
    this.etiketttype = 0
    this.gesamtanzahlIst = 0
    this.banderole = 0
    this.kommissionid = 0
    this.banderolenr = ""
    this.startzeit = null
    this.endzeit = null
    this.prodDauerInMinuten = 0
  }
}

export interface PlanungMaschinenHeute
{
  listePlanungResource: Array<Planung>
  anzahlListeartikelProStunde: Array<KeyValuePair>
}

export class MaschineSelect {
  value: string | null
  text: string

  constructor (value: string | null, text: string) {
    this.value = value
    this.text = text
  }
}
<template>
  <div class="d-flex justify-content-center align-times-center mt-5">
    <LoadingSpinner v-if="loading" :text="'... Anmeldedaten werden überpürft ...'" />

    <div v-else class="d-flex-col">

      <form v-if="displayBenutzerLogin || isdevmode" class="form-signin" @submit.prevent="login">
        <div class="mb-5 text-center">
          <!-- <font-awesome-icon icon="users-line" class="me-3 mb-2 fa-3x" /> -->
          <img
            src="@/assets/icons/ew.png"
            alt="Eckerts Logo"
            width="64"
          />
          <h1>EW Dashboard</h1>
        </div>

        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="Anmeldename"
            v-model="username"
          />
          <label for="floatingInput">Benutzername</label>
        </div>

        <div class="form-floating mb-3">
          <input
            type="password"
            class="form-control"
            id="floatingPassword"
            placeholder="Password"
            v-model="userpassword"
          />
          <label for="floatingPassword">Passwort</label>
        </div>

        <button type="submit" class="w-100 btn btn-lg btn-primary">
          <font-awesome-icon icon="arrow-right-to-bracket" class="me-3" />
          Anmelden
        </button>

        <hr class="my-4">
        <button type="button" v-if="azureLoginAvailable" class="btn btn-link text-body-tertiary text-decoration-none w-100" @click="switchToAzureLogin()">SSO Login</button>
      </form>

      <div v-if="azureLoginAvailable && displayAzureLogin">
        <button class="btn btn-outline-primary btn-lg w-100" @click="signIn">
          <font-awesome-icon :icon="['fab', 'windows']" class="me-3" />
          Weiter mit Mircrosoft
        </button>

        <hr class="my-4">
        <button class="btn btn-link text-body-tertiary text-decoration-none w-100" @click="switchToBenutzerLoginForm()">Benutzer Login</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watchEffect } from "vue";
import { useStore } from '@/store'

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions"
import { LoginRequest, LoginAzureRequest } from "@/models/ArchivModels"
import { PublicClientApplication, AccountInfo, AuthenticationResult } from '@azure/msal-browser';

export default defineComponent({
  name: 'Login',

  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore()

    const loading = computed(() => store.getters.status.archivLoading)
    const errorMsg = computed(() => store.getters.status.archivErrorMsg)

    const username = ref("")
    const userpassword = ref("")

    const msalConfig = computed(() => store.getters.msalConfig);
    const msalInstance = computed(() => {
      // let config = msalConfig.value
      // if (config != null) {
      //   config.auth.redirectUri = "http://localhost:8080"
      // }
      // return config != null ?  new PublicClientApplication(config) : null
      return msalConfig.value != null ?  new PublicClientApplication(msalConfig.value) : null
    })

    const azureLoginAvailable = computed(() => {
      if (store.getters.msalConfig != null) {
        displayBenutzerLogin.value = false;
        displayAzureLogin.value = true;
        return true
      }
      else {
        return false
      }
      // return store.getters.status.azureLoginAvailable
    });

    const account = ref<AccountInfo>()
    const loginRequest = {
      scopes: ["User.Read"]
    };

    function signIn() {
      // console.log("signIn");
      // console.log(this.msalConfig);
      // console.log(this.msalInstance);
      /**
       * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
       */

      if (msalInstance.value != null) {
        msalInstance.value.loginPopup()
        .then((res: any) => {
          handleResponse(res)
        })
        .catch((error: any) => {
          console.error(error);
        });
      }
    }

    function handleResponse(response: AuthenticationResult) {

      /**
       * To see the full list of response object properties, visit:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
       */

      if (response != null) {
        let identyToken = response.idToken
        let username = response.account?.username != undefined ? response.account?.username : ""

        const azureLogin: LoginAzureRequest = new LoginAzureRequest(identyToken, username)
        store.dispatch(ArchivActionTypes.LoginAzure, azureLogin)
      }
      // else {
      //   this.selectAccount();
      // }
    }

    const displayBenutzerLogin = ref(true)
    const displayAzureLogin = ref(false)
    
    const isdevmode = computed(() => process.env.NODE_ENV == "development")
    const mounted = ref(false)
    watchEffect(() => {
      if (azureLoginAvailable.value && mounted.value && !isdevmode.value) {
        signIn()
      }
    })

    return {
      store,
      loading,
      username,
      userpassword,

      msalConfig,
      displayBenutzerLogin,
      displayAzureLogin,
      signIn,
      mounted,
      isdevmode,
      azureLoginAvailable
    }
  },

  methods: {
    login () {
      const data = new LoginRequest(this.username, this.userpassword)
      this.store.dispatch(ArchivActionTypes.Login, data);
    },

    switchToBenutzerLoginForm () {
      this.displayBenutzerLogin = true;
      this.displayAzureLogin = false;
    },

    switchToAzureLogin () {
      this.displayBenutzerLogin = false;
      this.displayAzureLogin = true;
    },
  },

  mounted () {
    this.mounted = true
    if (this.msalConfig != null) {
      this.store.dispatch(ArchivActionTypes.SetAzureLoginAvailable, undefined)
      // this.signIn()
    }
  }
})
</script>
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row text-start" }
const _hoisted_2 = { class: "col-1" }
const _hoisted_3 = {
  key: 0,
  class: "col-1 text-danger"
}
const _hoisted_4 = {
  key: 1,
  class: "col-1 text-warning"
}
const _hoisted_5 = {
  key: 2,
  class: "col-1 text-success"
}
const _hoisted_6 = {
  key: 3,
  class: "col-1"
}
const _hoisted_7 = { class: "col-2" }
const _hoisted_8 = { class: "col-5" }
const _hoisted_9 = { class: "col-1" }
const _hoisted_10 = { class: "col-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.maschine.maschinename), 1),
    (_ctx.maschine.maschinenzustand == 'Fehler')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.maschine.maschinenzustand), 1))
      : (_ctx.maschine.maschinenzustand == 'Muster')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.maschine.maschinenzustand), 1))
        : (_ctx.maschine.maschinenzustand == 'Produktion')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.maschine.maschinenzustand), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.maschine.maschinenzustand), 1)),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.maschine.herstellungid), 1),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.maschine.artikel), 1),
    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.maschine.landnr), 1),
    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.mdateConverter), 1)
  ]))
}
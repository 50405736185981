<template>
  <div id="herstellung">
    <div v-if="loginlagerLoading" class="text-center m-5">
      <LoadingSpinner />
    </div>
    <div v-else-if="loggedIn && lagerUser && lagerUser.lagerUser.herstellung == 1" class="w-100">
      <HerstellungContent />
    </div>
    <div v-else class="text-center m-5">
      <div class="alert alert-danger" role="alert">Sie haben keinen Zugriff auf diese Daten</div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'


import HerstellungContent from '@/components/Herstellung/Content/HerstellungContent.vue'

export default defineComponent({
  name: 'Herstellung',
  components: {
    HerstellungContent,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const lagerUser = computed(() => store.getters.lagerUser)
    const loginlagerLoading = computed(() => store.getters.status.loginlagerdbLoading)

    return {
      store,
      lagerUser,
      loginlagerLoading
    }
  },

  methods: {
    loginLagerDB () {
      this.store.dispatch(MitarbeiterActionTypes.LoginLagerDB, undefined)
        .then(() => {
          this.loggedIn = true
        })
    },
  },
  data () {
    return {
      loggedIn: false
    }
  },
  mounted () {
    if(!localStorage.getItem('lagerUser')){
      this.loginLagerDB()
    }
    else {
      this.loggedIn = true
    }
  }
})
</script>

<style lang="scss" scoped>
#herstellung {
  min-height: 100vh;
}
</style>
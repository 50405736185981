export class Listeartikel {
  id: number;
  artikelnr: string;
  artikel: string;
  landnr: string;
  land: string;
  barcode: string;
  produktiondatum: Date;
  lagerortid: string;
  lagerdatum: Date;
  lagerplatzid: number;
  lagerplatzname: string;
  dokumentnr: string;
  kundebereitstellungdatum: Date;
  produktionplanungid: number;
  ppsid: number;

  constructor () {
    this.id = 0;
    this.artikelnr = "";
    this.artikel = "";
    this.landnr = "";
    this.land = "";
    this.barcode = "";
    this.produktiondatum = new Date();
    this.lagerortid = "";
    this.lagerdatum = new Date();
    this.lagerplatzid = 0;
    this.lagerplatzname = "";
    this.dokumentnr = "";
    this.kundebereitstellungdatum = new Date();
    this.produktionplanungid = 0;
    this.ppsid = 0;
  }
}

export class Lagerplatz {
  id: number
  platz: number
  artikel: any

  constructor () {
    this.id = 0;
    this.platz = 0;
    this.artikel = new Listeartikel();
  }
}

export interface LagerplatzResponse {
  id: number;
  block: string;
  reihe: number;
  maxstellplatz: number;
  gesperrt: number;
  aktiv: number;
  score: number;
  lagerplatzartid: number;
  lagerplatzartFarbe: number;
  lagerplatzartTitel: string;
  lagerplatzartText: string;
  bgColor: string;
  lagerplatzart: string;
}

export class Lagerreihe {
  id: number;
  block: string;
  reihe: number;
  aktiv: number;
  gesperrt: number;
  lagerplatzartid: number;

  lagerplatzartTitel: string;
  lagerplatzartText: string;
  lagerplatzartFarbe: string;
  // lagerplatzart: Lagerplatzart;
  lagerplatzart: string;
  bgColor: string;
  textColor: string;
  stellplaetze: Array<Lagerplatz>;

  constructor () {
    this.id = 0;
    this.block = "";
    this.reihe = 0;
    this.aktiv = 0;
    this.gesperrt = 0;
    this.lagerplatzartid = 0;
    
    this.lagerplatzartTitel = "";
    this.lagerplatzartText = "";
    this.lagerplatzartFarbe = "";
    // this.lagerplatzart = new Lagerplatzart();
    this.lagerplatzart = "";
    this.bgColor = "";
    this.textColor = "";
    this.stellplaetze = new Array<Lagerplatz>();
  }
}

export class Lagerplatzart
{
  id: number;
  titel: string;
  text: string;
  farbe: number;
  bgColor: string;

  constructor () {
    this.id = 0;
    this.titel = "";
    this.text = "";
    this.farbe = 0;
    this.bgColor = "";
  }
}
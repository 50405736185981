import { MutationTree } from "vuex";
import { State } from "./state";
import { AnzahlFlaschen, Flasche, Kapsel, Karton } from "@/models/PpsMaterialModels";
import { Materialartikel } from "@/models/LagerMaterialModels";
import _ from 'lodash'



export enum MutationTypes {
  GetFlaschenbestandSuccess = "GETFLASCHENBESTANDSUCCESS",
  GetKartonsbestandSuccess = "GETKARTONSBESTANDSUCCESS",
  GetKapselnbestandSuccess= "GETKAPSELNBESTANDSUCCESS",
  GetMaterialartikelFlaschenSuccss = "GETMATERIALARTIKELFLASCHENSUCCESS",
  SetAnzahlFlaschen = "SETANAZAHLFLASCHEN"
}


export type Mutations = {
  [MutationTypes.GetFlaschenbestandSuccess](state: State, data: Array<Flasche>): void
  [MutationTypes.GetKartonsbestandSuccess](state: State, data: Array<Karton>): void
  [MutationTypes.GetKapselnbestandSuccess](state: State, data: Array<Kapsel>): void
  [MutationTypes.GetMaterialartikelFlaschenSuccss](state: State, data: Array<Materialartikel>): void
  [MutationTypes.SetAnzahlFlaschen](state: State): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.GetFlaschenbestandSuccess](state, data) {
    state.materialFlaschen = data
  },

  [MutationTypes.GetKartonsbestandSuccess](state, data) {
    state.materialKartons = data
  },

  [MutationTypes.GetKapselnbestandSuccess](state, data) {
    state.materialKapseln = data
  },

  [MutationTypes.GetMaterialartikelFlaschenSuccss](state, data) {
    state.materialArtikelFlaschen = data
  },

  [MutationTypes.SetAnzahlFlaschen](state) {
    state.materialAnzahlFlaschen = getFlaschenBestandTabelle(state)
  },

}


function groupedByMaterialArtikelId (data: Array<Flasche>) {
  return _.mapValues(_.groupBy(data, 'materialartikelid'), (plist: any[]) => plist.map((pers: any) => _.omit(pers, 'materialartikelid')))
}

function getFlaschenBestandTabelle (state: State): Array<AnzahlFlaschen> {
  let tableItems = new Array<AnzahlFlaschen>()

  const data = groupedByMaterialArtikelId(state.materialFlaschen)
  const materialartikelFlaschen = state.materialArtikelFlaschen

  for (let materialartikelid in data) {
    let count = 0
    let materialartikel = materialartikelFlaschen.find(x => { return x.id == Number(materialartikelid)})
    let artikel = (materialartikel) ? materialartikel.artikel : 'unbekannt'

    for (let palette in data[materialartikelid]) {
      count += data[materialartikelid][palette].anzahlstueck
    }
    
    tableItems.push(new AnzahlFlaschen(materialartikelid, artikel || 'unbekannt', data[materialartikelid].length, count))
  }

  return tableItems
}
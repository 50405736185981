import { MutationTree } from "vuex";
import { State } from "./state";
import { MitarbeiterArbeitszeit } from "@/models/PersonaldatenModels";

export enum MutationTypes {
  SetMitarbeiterArbeitszeit = "SET_MITARBEITER_ARBEITSZEIT",
}


export type Mutations = {
  [MutationTypes.SetMitarbeiterArbeitszeit](state: State, data: Array<MitarbeiterArbeitszeit>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetMitarbeiterArbeitszeit](state, data) {
    state.mitarbeiterArbeitszeiten = data;
    localStorage.setItem("mitarbeiterArbeitszeiten", JSON.stringify(state.mitarbeiterArbeitszeiten));
  },
}
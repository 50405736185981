<template>
  <div class="card status-text-size p-3 border-0 shadow-sm rounded-0">
    <div class="card-header bg-white border-0">
      <div class="row">
        <div class="col text-start">
          <p class="text-secondary mb-0">Maschinenstatus</p>
        </div>
        <div class="col text-end text-secondary">
          <span class="me-3">{{ updateDatetimeConverter }}</span>
          <span id="sync" v-on:click="getProduktionsstatus()">
            <font-awesome-icon icon="sync" />
          </span>
        </div>
      </div>
    </div>

    <div class="card-text">
      <div class="row" v-if="produktionsstatusLoading">
        <div class="col">
          <LoadingSpinner variant="danger"></LoadingSpinner>
        </div>
      </div>
      <div v-else class="text-start px-3 py-1">
        <div v-if="produktionsstatus != null" class="row py-2">
          <div class="col-1">
            <strong>Name</strong>
          </div>
          <div class="col-1">
            <strong>Zustand</strong>
          </div>
          <div class="col-2">
            <strong>Herstellung</strong>
          </div>
          <div class="col-5">
            <strong>Artikel</strong>
          </div>
          <div class="col-1">
            <strong>Land</strong>
          </div>
          <div class="col-2">
            <strong>Zeit</strong>
          </div>
        </div>
        <ProduktionsstatusRow
          v-for="maschine in produktionsstatus"
          :key="maschine.id"
          :maschine="maschine"
          class="py-1 striped"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'

import { ActionTypes as ProduktionsstatusActionTypes } from '@/store/modules/Pps/Produktionsstatus/actions'

import ProduktionsstatusRow from "./ProduktionsstatusRow.vue";

import LoadingSpinner from "@/components/LoadingSpinner.vue";



export default defineComponent({
  name: "produktionsstatus",
  components: {
    ProduktionsstatusRow,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const produktionsstatus = computed(() => store.getters.produktionsstatus)
    const produktionsstatusLoading = computed(() => store.getters.status.produktionsstatusLoading)

    const updateStatusInterval = ref();
    const updateDatetime = ref<Date>(new Date());
    const updateDatetimeConverter = computed(() => {
      return ("0" + (updateDatetime.value.getHours())).slice(-2) + ":" + ("0" + (updateDatetime.value.getMinutes())).slice(-2)
    })

    return {
      store,
      produktionsstatus,
      produktionsstatusLoading,
      updateStatusInterval,
      updateDatetime,
      updateDatetimeConverter
    }
  },

  methods: {
    getProduktionsstatus() {
      this.store.dispatch(ProduktionsstatusActionTypes.GetProduktionsstatus, undefined)
      .then(() => {
        this.updateDatetime = new Date();
      })
    },

    updateGetProduktionsstatus() {
      this.store.dispatch(ProduktionsstatusActionTypes.UpdateGetProduktionsstatus, undefined)
      .then(() => {
        this.updateDatetime = new Date();
      })
    }
  },

  
  mounted() {
    this.getProduktionsstatus();

    this.updateStatusInterval = setInterval(() => {
      this.updateGetProduktionsstatus();
    }, 1 * 60 * 1000);
  },
  
  beforeUnmount () {
    clearInterval(this.updateStatusInterval)
  }
});
</script>

<style lang="scss" scoped>
#sync:hover {
  color: #dc3545;
  cursor: pointer;
}
</style>
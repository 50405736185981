<template>
  <div id="material">
    <div v-if="loginlagerLoading" class="text-center m-5">
      <LoadingSpinner />
    </div>

    <div v-else-if="loggedIn && lagerUser && lagerUser.lagerUser.verwaltung == 1" class="w-100">
      <MaterialKapselnContent />
    </div>

    <div v-else class="m-4 float-center w-100">
      <div class="alert alert-danger" role="alert">Sie haben keinen Zugriff auf diese Daten</div>
    </div> 
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'
import { ActionTypes as MaterialartikelActionTypes } from '@/store/modules/Lager/LagerMaterial/actions'

import MaterialKapselnContent from '@/components/Materialbestellung/Content/MaterialKapselnContent.vue'

export default defineComponent({
  name: 'Material',
  components: {
    LoadingSpinner,
    MaterialKapselnContent,
  },

  setup () {
    const store = useStore();

    const lagerUser = computed(() => store.getters.lagerUser)
    const loginlagerLoading = computed(() => store.getters.status.loginlagerdbLoading)
    const materialartikel = computed(() => store.getters.materialartikel)
    const materialArtikelFlaschen = computed(() => store.getters.materialArtikelFlaschen)

    return {
      store,
      lagerUser,
      loginlagerLoading,
      materialartikel,
      materialArtikelFlaschen
    }
  },

  methods: {
    loginLagerDB () {
      this.store.dispatch(MitarbeiterActionTypes.LoginLagerDB, undefined)
        .then(() => {
          this.loggedIn = true
        })
    },

    fetchMaterialartikel () {
      this.store.dispatch(MaterialartikelActionTypes.fetchMaterialartikel, undefined)
    },

    setContentArea(value: string) {
      this.content = value
    }
  },

  data () {
    return {
      loggedIn: false,
      content: 'flaschen'
    }
  },

  mounted () {
    if(!localStorage.getItem('lagerUser')) {
      this.loginLagerDB()
    }
    else {
      this.loggedIn = true
    }

    if(this.materialartikel.length === 0) {
      this.fetchMaterialartikel()
    }
  }
})
</script>

<style lang="scss">
// #material,
// .contentArea {
//   height: 100vh;
// }

// .backgroundColor {
//   background: rgb(242, 243, 248);
// }

</style>
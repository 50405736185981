import { ActionContext, ActionTree } from "vuex";
import PersonalApiService from "@/service/PersonalApiService";

import { MitarbeiterArbeitszeit } from "@/models/PersonaldatenModels";

import { RootState } from '@/store';
import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";

import { ActionTypes as CommonActionTypes } from "../Common/actions";

export enum ActionTypes {
  GetMitarbeiterArbeitszeiten = "GET_MITARBEITER_ARBEITSZEITEN",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetMitarbeiterArbeitszeiten](context: ActionArguments): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [ActionTypes.GetMitarbeiterArbeitszeiten]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.mitarbeiterArbeitszeitenLoading = true;
    status.mitarbeiterArbeitszeitenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return PersonalApiService.getCurrentlyWorkingMitarbeiter()
    .then(res => {
      const data: Array<MitarbeiterArbeitszeit> = res.data;
      commit(MutationTypes.SetMitarbeiterArbeitszeit, data);
    })
    .catch(err => {
      status.mitarbeiterArbeitszeitenErrorMsg = err.response.data;
      dispatch(CommonActionTypes.SetStatus, status);
    })
    .finally(() => {
      status.mitarbeiterArbeitszeitenLoading = false;
      dispatch(CommonActionTypes.SetStatus, status);
    });
  },
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40f18dab"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "herstellungContent",
  class: "mainContent"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = {
  key: 0,
  class: "text-center"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bestandskontrolle = _resolveComponent("Bestandskontrolle")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_BestandskontrolleTageChartCard = _resolveComponent("BestandskontrolleTageChartCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Bestandskontrolle)
      ])
    ]),
    (_ctx.bestandskontrolleLoading && _ctx.bestandskontrolleTage !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_LoadingSpinner)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bestandskontrolleTage, (alkohol, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: index
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_BestandskontrolleTageChartCard, {
                  alkohol: alkohol,
                  class: "mb-3"
                }, null, 8, ["alkohol"])
              ])
            ]))
          }), 128))
        ]))
  ]))
}
<template>
  <div data-bs-toggle="modal" :data-bs-target="'#lagerplatzModal' + platz.id">
    <div v-if="platz.artikel == null" class="stellplatz"></div>
    <div
      v-else
      class="stellplatz text-center"
      v-bind:style="{ background: platz.artikel.bgColor, color: platz.artikel.textColor }"
    >
      {{ platz.artikel.artikelnr }} {{ platz.artikel.landnr }}
    </div>
  </div>

  <div
    class="modal text-dark fade p-0"
    :id="'lagerplatzModal' + platz.id"
    :aria-labelledby="'lagerplatzModal' + platz.id"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <div class="modal-header py-1">
          <h5 class="modal-title">Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div v-if="platz.artikel != null">
            <p class="text-size"><strong>ID:</strong> {{platz.artikel.id}}</p>
            <p class="text-size"><strong>Reihe:</strong> {{platz.artikel.lagerplatzname}}</p>
            <p class="text-size"><strong>Artikelnummer:</strong> {{platz.artikel.artikelnr}}</p>
            <p class="text-size"><strong>Artikel:</strong> {{platz.artikel.artikel}}</p>
            <p class="text-size"><strong>Landnummer:</strong> {{platz.artikel.landnr}}</p>
            <p class="text-size"><strong>Land:</strong> {{platz.artikel.land}}</p>
            <p class="text-size"><strong>Barcode:</strong> {{platz.artikel.barcode}}</p>
            <p class="text-size"><strong>Produktionsdatum:</strong> {{getProduktionsdatum}}</p>
            <p class="text-size"><strong>Produktionsplan:</strong> {{platz.artikel.produktionplanungid}}</p>
            <p class="text-size"><strong>Lagerdatum:</strong> {{getLagerdatum}}</p>
          </div>
          
          <div v-else><strong>Lagerplatz frei.</strong> | Platz: {{platz.platz + 1}} | ID: {{platz.id}}</div>
        </div>

      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import moment from 'moment'
import LagerService from '@/service/LagerService'

import { Lagerplatz } from "@/models/LagerModels";

export default defineComponent({
  name: 'LagerStellplatz',
  props: {
    platz: {
      type: Object as PropType<Lagerplatz>,
      default: new Lagerplatz()
    }
  },
  computed: {
    getProduktionsdatum() {
      return moment(new Date(this.platz.artikel.produktiondatum)).format('DD.MM.YYYY HH:mm:ss')
    },
    getLagerdatum() {
      return moment(this.platz.artikel.lagerdatum).format('DD.MM.YYYY HH:mm:ss')
    }
  },
  // methods: {
  //   paletteGetLagerplatz() {
  //     this.lagerplatz_berechnen = true
  //     LagerService.getEinlagernEinzelnListeartikel(this.platz.artikel.id)
  //       .then(res => {
  //         this.lagerplatz = res.data[1][this.platz.artikel.id].position
  //         this.lagerplatz_berechnen = false
  //       })
  //       .catch(() => {
  //         this.lagerplatz = "Es ist ein Fehler aufgetreten, bitte probieren Sie es später erneut."
  //         this.lagerplatz_berechnen = false
  //       })
  //   }
  // },

  data () {
    return {
      lagerplatz: '',
      lagerplatz_berechnen: false
    }
  }
});
</script>

<style scoped>
.stellplatz{
  float: left;
  background: white;
  width: 22px;
  height: 20px;
  margin: 1px;
  padding: 1px;
  border: 1px solid black;
  font-size: 0.55em;
  line-height: 0.95em;
  /* color: white; */
}

.stellplatz:hover{
  cursor: pointer;
}

.text-size {
  font-size: 1em !important;
}
</style>
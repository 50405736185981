<template>
  <div id="produktionContent" class="mainContent">
    <div class="row mb-4">
      <div class="col">
        <Produktionsstatus />   
      </div>
    </div>
    <div class="rwo mb-2">
      <div class="col">
        <PlanungMaschineHeute v-if="produktionsstatusFetched"/>
      </div>
    </div>
    <div v-if="produktionsstatusFetched && palettenProStunde != null && palettenProStunde.length > 0" class="row mb-4">
      <div class="col col-sm-5 col-md-4 col-lg-3">
        <PlanungPalettenProStunde />
      </div>
      <div class="col col-sm-7 col-md-8 col-lg-9" sm="7" md="8" lg="9">
        <PlanungPalettenProStundeChartCard />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

// import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'

import Produktionsstatus from './Produktionsstatus.vue'
import PlanungMaschineHeute from './PlanungMaschineHeute.vue'
import PlanungPalettenProStunde from './PlanungPalettenProStunde.vue'
import PlanungPalettenProStundeChartCard from './PlanungPalettenProStundeChartCard.vue'

export default defineComponent({
  name: 'ProduktionContent',
  components: {
    Produktionsstatus,
    PlanungMaschineHeute,
    PlanungPalettenProStunde,
    PlanungPalettenProStundeChartCard
  },

  setup () {
    const store = useStore();

    const palettenProStunde = computed(() => store.getters.palettenProStunde)
    const produktionsstatusFetched = computed(() => store.getters.status.produktionsstatusFetched)

    return {
      store,
      palettenProStunde,
      produktionsstatusFetched
    }
  },
})
</script>

<style lang="scss" scoped>
#produktionContent {
  background: rgb(242, 243, 248);
}
</style>
import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Hersteller, Herstellerartikel, Lieferant, Lieferantartikel, Lieferherstartikel, Materialartikel, Materiallieferartikel } from "@/models/LagerMaterialModels"

export type Getters = {
  materialartikel (state: State): Array<Materialartikel>,
  materialartikelFlaschen (state: State): Array<Materialartikel>,
  materialartikelKartons (state: State): Array<Materialartikel>,
  materialartikelKapseln (state: State): Array<Materialartikel>,
  materiallieferantartikel (state: State): Array<Materiallieferartikel>,
  lieferantartikel (state: State): Array<Lieferantartikel>,
  lieferanten (state: State): Array<Lieferant>,
  lieferherstartikel (state: State): Array<Lieferherstartikel>,
  herstellerartikel (state: State): Array<Herstellerartikel>,
  hersteller (state: State): Array<Hersteller>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  materialartikel(state) {
    return state.materialartikel;
  },

  materialartikelFlaschen(state) {
    return state.materialartikel.filter(m => m.einheit == "Flaschen");
  },

  materialartikelKartons(state) {
    return state.materialartikel.filter(m => m.einheit == "Karton");
  },

  materialartikelKapseln(state) {
    return state.materialartikel.filter(m => m.einheit == "Kapseln");
  },

  materiallieferantartikel(state) {
    return state.materiallieferantartikel;
  },

  lieferantartikel(state) {
    return state.lieferantartikel;
  },

  lieferanten(state) {
    return state.lieferanten;
  },

  lieferherstartikel(state) {
    return state.lieferherstartikel;
  },

  herstellerartikel(state) {
    return state.herstellerartikel;
  },

  hersteller(state) {
    return state.hersteller;
  },
}

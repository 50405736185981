<template>
  <Line :data="chartdata" :options="options" :height="300" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Point
} from 'chart.js'

import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default defineComponent({
  name: "Chart",
  components: { Line },
  props: {
    titel: {
      type: String,
      default: ""
    },
    labels: {
      type: Object as PropType<Array<string>>,
      required: true
    },
    values: {
      type: Object as PropType<Array<number>>,
      required: true
    }
  },

  setup (props) {
    const options = {
      options: { 
        responsive: false,
        maintainAspectRatio: false
      },
      scales: {
        y: {
          beginAtZero: true
        },
      },
      elements: {
        point: {
          radius: 4
        }
      }
    }

    const chartdata: ChartData<"line", (number | Point | null)[], unknown> = {
        labels: props.labels,
        datasets: [
          {
            label: props.titel,
            borderColor: '#162b4a',
            data: props.values,
            stepped: 'after',
          }
        ]
    }

    return {
      options,
      chartdata
    }
  }
})
</script>
import { PlanungMaschinenHeute } from "./PlanungModels"

export class Produktionsstatus {
  id: number
  maschinenid: number
  maschinename: string
  maschinenzustand: string
  maschinenfuehrer: string
  herstellungid: number
  tankid: number
  tankinhalt: number
  flaschecharge: string
  flaschechargecounter: number
  flaschencount: number
  artikelnr: string
  artikel: string
  landnr: string
  tanknr: number
  mdate: Date
  maschinennr: number

  constructor() {
    this.id = 0
    this.maschinenid = 0
    this.maschinename = ""
    this.maschinenzustand = ""
    this.maschinenfuehrer = ""
    this.herstellungid = 0
    this.tankid = 0
    this.tankinhalt = 0
    this.flaschecharge = ""
    this.flaschechargecounter = 0
    this.flaschencount = 0
    this.artikelnr = ""
    this.artikel = ""
    this.landnr = ""
    this.tanknr = 0
    this.mdate = new Date()
    this.maschinennr = 0
  }
}


export interface Maschinenplanung {
  id: number
  maschinenid: number
  maschinename: string
  maschinenzustand: string
  maschinenfuehrer: string
  herstellungid: number
  tankid: number
  tankinhalt: number
  flaschecharge: string
  flaschechargecounter: number
  flaschencount: number
  artikelnr: string
  artikel: string
  landnr: string
  tanknr: number
  mdate: Date
  maschinennr: number
  planung: PlanungMaschinenHeute
}
<template>
  <nav id="mainNav" class="navbar navbar-expand-md bg-white navbar-light shadow-sm pt-2 ps-1 pe-3">
    <div class="container-fluid" v-if="user != null">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <button class="btn" @click="toggleSidebar()"><font-awesome-icon icon="bars" /></button>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown my-auto">
          <img src="@/assets/img/profilbild.png" alt="Profil Bild Rainer Zufall" class="avatar me-2" />

          <a
            class="nav-link dropdown-toggle d-inline pe-0"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >{{user?.benutzer}}</a>

          <ul class="dropdown-menu m-0">
            <li>
              <button class="dropdown-item" @click="logoutUser()">
                <font-awesome-icon icon="arrow-right-from-bracket" class="me-3" />
                Abmelden
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script lang="ts">
// import { mapGetters, mapActions } from 'vuex';

import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router';

import { ActionTypes as ArchivActionTypes } from "@/store/modules/Archiv/actions"
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions"
import { ActionTypes as MitarbeiterActionTypes } from "@/store/modules/Lager/Mitarbeiter/actions"

export default defineComponent({ 
  name: "Navbar",

  setup () {
    const store = useStore();
    const router = useRouter();
    
    const user = computed(() => store.getters.authUser)
    const lagerUser = computed(() => store.getters.lagerUser)
    const status = computed(() => store.getters.status)

    return {
      store,
      router,
      user,
      lagerUser,
      status
    }
  },

  // computed: {
  //   ...mapGetters(['user', 'lagerUser']),
  // },
  methods: {
    // ...mapActions(['logoutUser'])
    logoutUser () {
      // this.store.dispatch(ArchivActionTypes.Logout, undefined);
      this.store.dispatch(MitarbeiterActionTypes.LogoutLagerDB, undefined)
      .then(() => {
        this.router.replace({name: "logout"})
      });
      // location.reload();
    },

    toggleSidebar () {
      this.store.dispatch(CommonActionTypes.ToggleSidebar, undefined);
    },
  },

  data () {
    return {
      screenWidth: 0,
    }
  },
  
  created () {
    this.screenWidth = window.innerWidth;
  }
})
</script>

<style  lang="scss" scoped>
img.avatar {
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #162b4a;
  height: 40px;
  width: 40px;
}

#mainNav {
  // z-index: 2;
  background: rgb(242, 243, 248);
}
</style>
import { MutationTree } from "vuex";
import { State } from "./state";
import { Printdata } from "@/models/PrintdataModels";


export enum MutationTypes {
  GetPrintdataFilelistSuccess = "GETPRINTDATAFILELISTSUCCESS",
  DeletePrintdataFileSuccess ="DELETEPRINTDATAFILESUCCESS"
}


export type Mutations = {
  [MutationTypes.GetPrintdataFilelistSuccess](state: State, data: Array<Printdata>): void
  [MutationTypes.DeletePrintdataFileSuccess](state: State, fileName: string): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.GetPrintdataFilelistSuccess](state, data) {
    state.printdata = data
  },

  [MutationTypes.DeletePrintdataFileSuccess](state, fileName) {
    let index = state.printdata.findIndex(x => x.name == fileName)
    state.printdata.splice(index, 1)
  },
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7641d6bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column text-center" }
const _hoisted_2 = {
  ref: "gaugeChartContainer",
  class: "vue-gauge-item"
}
const _hoisted_3 = { class: "ist-leistung-label mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.istLeistung), 1)
  ]))
}
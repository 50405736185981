<template>
  <div id="MitarbeiterContent" class="text-left mainContent">
    <div class="card status-text-size p-3 border-0 shadow-sm rounded-0">
      <div class="card-header  bg-white border-0">
        <div class="row">
          <div class="col ps-0">
            <p class="text-secondary mb-0">Mitarbeiter</p>
          </div>
          <div class="col text-end text-secondary pe-0">
            <span class="me-3">{{ datum }}</span>
            <span id="sync" v-on:click="fetchMitarbeiterPersonalstatus()">
              <font-awesome-icon icon="sync" />
            </span>
          </div>
        </div>
      </div>

      <div class="card-text">
        <div v-if="personalLoading" class="row mt-5">
          <div class="col w-100 text-center">
            <LoadingSpinner :text="'...Daten werden geladen...'"/>
          </div>
        </div>
        
        <div v-else-if="personal.length > 0">
          <div class="d-flex justify-content-end">
            <div class="btn-group float-end" role="group">
              <button class="btn btn-outline-dark btn-sm" @click="setView('liste')" :disabled="selectView == 'liste'"><font-awesome-icon title="Personal Liste" icon="list" /></button>
              <button class="btn btn-outline-dark btn-sm" @click="setView('kostenstelle')" :disabled="selectView == 'kostenstelle'"><font-awesome-icon title="Gruppiert Kostenstelle"  icon="object-group" /></button>
              <button class="btn btn-outline-dark btn-sm" @click="setView('projekt')" :disabled="selectView == 'projekt'"><font-awesome-icon title="Gruppiert Projekte" icon="layer-group" /></button>
              <button class="btn btn-outline-dark btn-sm" @click="setView('personal')" :disabled="selectView == 'personal'"><font-awesome-icon title="Personal Übersicht" icon="address-card" /></button>
            </div>
          </div>

          <div class="row text-start px-3 py-2" v-if="selectView == 'kostenstelle'">
            <div class="col">
              <div class="row mb-4" v-for="(personal, key) in groupByKostenstellen" :key="key">
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <strong>Kostenstelle: {{key}}</strong>
                      <hr>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-2 mb-3" v-for="mitarbeiter in personal" :key="mitarbeiter.id" cols="4" md="3" xl="2">
                      <div class="card border border-secondary rounded-0 shadow">
                        <div class="card-header bg-secondary text-white rounded-0">
                          {{ mitarbeiter.vorname }} {{ mitarbeiter.name }}
                        </div>

                        <div class="card-text px-3 py-2">
                          <strong>Personal Nr.:</strong> {{mitarbeiter.personalguid}}<br> 
                          <strong>Projekt:</strong> {{mitarbeiter.projekttitel}}<br> 
                          <strong>Kostenstelle:</strong> {{key}}<br> 
                          <strong>Beginn:</strong> {{zeitFormatieren(mitarbeiter.von)}}<br> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row text-start px-3 py-2" v-else-if="selectView == 'projekt'">
            <div class="col">
              <div class="row mb-4" v-for="(personal, key) in groupByProjekttitel" :key="key">
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <strong>Projekt: {{key}}</strong>
                      <hr>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-2 mb-3" v-for="mitarbeiter in personal" :key="mitarbeiter.id" cols="4" md="3">
                      <div class="card border border-secondary rounded-0 shadow">
                        <div class="card-header bg-secondary text-white rounded-0">
                          {{ mitarbeiter.vorname }} {{ mitarbeiter.name }}
                        </div>

                        <div class="card-text px-3 py-2">
                          <strong>Personal Nr.:</strong> {{mitarbeiter.personalguid}}<br> 
                          <strong>Projekt:</strong> {{key}}<br>
                          <strong>Kostenstelle:</strong> {{mitarbeiter.kostenst}}<br>
                          <strong>Beginn:</strong> {{zeitFormatieren(mitarbeiter.von)}}<br>  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row text-start px-3 py-2" v-else-if="selectView == 'personal'">
            <div class="col">
              <div class="row mb-2">
                <div class="col">
                  <input type="text" class="form-control" v-model="selectVal.name" placeholder="Name" />
                </div>
                <div class="col">
                  <input type="text" class="form-control" v-model="selectVal.projekttitel" placeholder="Projekt" />
                </div>
                <div class="col">
                  <input type="text" class="form-control" v-model="selectVal.kostenstelle" placeholder="Kostenstelle" />
                </div>
              </div>
              <hr>
              
              <div class="row">
                <div class="col-2 mb-3" v-for="mitarbeiter in filteredPersonal" :key="mitarbeiter.id" cols="4" md="3">
                  <div class="card border border-secondary rounded-0 shadow">
                    <div class="card-header bg-secondary text-white rounded-0">
                      {{ mitarbeiter.vorname }} {{ mitarbeiter.name }}
                    </div>

                    <div class="card-text px-3 py-2">
                      <strong>Personal Nr.:</strong> {{mitarbeiter.personalguid}}<br> 
                      <strong>Projekt:</strong> {{mitarbeiter.projekttitel}}<br>
                      <strong>Kostenstelle:</strong> {{mitarbeiter.kostenst}}<br>
                      <strong>Beginn:</strong> {{zeitFormatieren(mitarbeiter.von)}}<br>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row text-start px-3 py-2" v-else-if="selectView == 'liste'">
            <div class="col">
              <div class="row mb-2">
                <div class="col">
                  <input type="text" class="form-control" v-model="selectVal.name" placeholder="Name" />
                </div>
                <div class="col">
                  <input type="text" class="form-control" v-model="selectVal.projekttitel" placeholder="Projekt" />
                </div>
                <div class="col">
                  <input type="text" class="form-control" v-model="selectVal.kostenstelle" placeholder="Kostenstelle" />
                </div>
              </div>
              <hr>

              <div class="row">
                <div class="col">
                  <table class="table table-striped table-small table-responsive table-outlined">
                    <thead>
                      <tr>
                        <th scope="col">Personal Nr.</th>
                        <th scope="col">Nachname</th>
                        <th scope="col">Vorname</th>
                        <th scope="col">Projekt</th>
                        <th scope="col">Kostenstell</th>
                        <th scope="col">Beginn</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="pers in filteredPersonal" :key="pers.id">
                        <td>{{ pers.personalguid }}</td>
                        <td>{{ pers.name }}</td>
                        <td>{{ pers.vorname }}</td>
                        <td>{{ pers.projekttitel }}</td>
                        <td>{{ pers.kostenst }}</td>
                        <td>{{ zeitFormatieren(pers.von) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-else>
            <div class="col w-100">
              <div class="alert alert-info" role="alert">Bitte wählen Sie eine Ansicht.</div>
            </div>
          </div>
        </div>
        
        <div class="row" v-else>
          <div class="col w-100">
            <div class="alert alert-warning" role="alert">Keine Mitarbeiter gefunden</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'

import { ActionTypes as PersonalActionTypes } from '@/store/modules/Pps/Personal/actions'
import { Personalstatus } from '@/models/PersonalModels'

import _ from 'lodash'
import moment from 'moment'

export default defineComponent({
  name: 'MitarbeiterContent',

  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const personal = computed(() => store.getters.personal)
    const personalLoading = computed(() => store.getters.status.personalLoading)

    const groupByKostenstellen = computed(() => {
      let grouped = _.mapValues(_.groupBy(personal.value, 'kostenst'), (plist: any[]) => plist.map((pers: any) => _.omit(pers, 'kostenst')))
      return grouped
    })

    const groupByProjekttitel = computed(() => {
      let grouped = _.mapValues(_.groupBy(personal.value, 'projekttitel'), (plist: any[]) => plist.map((pers: any) => _.omit(pers, 'projekttitel')))
      return grouped
    })

    const updatePlaungInterval: any = 0
    const selectView = ref('')
    const selectVal = ref(
      {
        kostenstelle: '',
        projekttitel: '',
        name: ''
      })
    const datum = ref('')

    return {
      store,
      personal,
      personalLoading,
      updatePlaungInterval,
      selectView,
      datum,
      selectVal,
      groupByKostenstellen,
      groupByProjekttitel
    }
  },

  computed: {
    filteredPersonal(): Array<Personalstatus> {
      let kostenstelleFilter = this.personal
      if (this.selectVal.kostenstelle !== '') {
        kostenstelleFilter = this.personal.filter(item => {
          if (!item.kostenst) {
            item.kostenst = 'unbekannt'
          }
          return (item.kostenst.toString().match(this.selectVal.kostenstelle.toString()))
        })
      }

      let projekttitelFilter = kostenstelleFilter
      if (this.selectVal.projekttitel !== '') {
        projekttitelFilter = kostenstelleFilter.filter(item => {
          if (!item.projekttitel) {
            item.projekttitel = 'unbekannt'
          }
          return (item.projekttitel.toLowerCase().match(this.selectVal.projekttitel.toLowerCase()))
        })
      }

      let nameFilter = projekttitelFilter
      if (this.selectVal.name !== '') {
        nameFilter = projekttitelFilter.filter(item => {
          if (!item.vorname) {
            item.vorname = 'unbekannt'
          }
          if (!item.name) {
            item.name = 'unbekannt'
          }

          let name = item.vorname + ' ' + item.name
          return (name.toLowerCase()).match(this.selectVal.name.toLowerCase())
        })
      }

      return nameFilter
    },
  },

  methods: {
    getAlleMitarbeiterPeronsalstatus () {
      this.store.dispatch(PersonalActionTypes.GetPersonal, undefined)
      .then(() => {
          this.selectView = localStorage.getItem('view') || 'liste'
          this.datum = this.zeitFormatieren(new Date())
        })
    },

    zeitFormatieren(zeit: Date) {
      return moment(String(zeit)).format('DD.MM.YYYY HH:mm')
    },

    setView(view: string) {
      this.selectView = view
      localStorage.setItem('view', view)
    },

    fetchMitarbeiterPersonalstatus() {
      clearInterval(this.updatePlaungInterval)
      this.getAlleMitarbeiterPeronsalstatus()
      this.updatePlaungInterval = setInterval(() => {
        this.getAlleMitarbeiterPeronsalstatus();
      }, 5 * 60 * 1000);
    }
  },

  mounted () {
    this.fetchMitarbeiterPersonalstatus()
  },

  beforeUnmount () {
    clearInterval(this.updatePlaungInterval)
  }

})

</script>

<style>
#sync:hover {
  color: #dc3545;
  cursor: pointer;
}
</style>
import { ActionContext, ActionTree } from "vuex";
import LagerService from '@/service/LagerService'

import { RootState } from '@/store';

import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";

import { Actions as ArchivActions, ActionTypes as ArchivActionTypes } from "../../Archiv/actions";
import { Actions as CommonActions, ActionTypes as CommonActionTypes } from "../../Common/actions";
import { LagerUser, Mitarbeiter, LagerLogoutRequest, RefreshLoginRequest } from "@/models/MitarbeiterModels";


export enum ActionTypes {
  LoginLagerDB = "LOGINLAGERDB",
  LogoutLagerDB = "LOGOUTLAGERDB",
  RefreshLoginLagerDB = "REFRESHLOGINLAGERDB",
  GetMitarbeiter = "GETMITARBEITER"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.LoginLagerDB](context: ActionArguments): Promise<void>,
  [ActionTypes.LogoutLagerDB](context: ActionArguments): Promise<void>,
  [ActionTypes.RefreshLoginLagerDB](context: ActionArguments): Promise<void>,
  [ActionTypes.GetMitarbeiter](context: ActionArguments): Promise<void>,
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.LoginLagerDB]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.loginlagerdbLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.loginLager()
      .then(res => {
        status.loginlagerdbLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: LagerUser = res.data
        commit(MutationTypes.LoginLagerSuccess, result)

        localStorage.setItem("lagerUser", JSON.stringify(result));
      })
      .catch(error => {
        status.loginlagerdbLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.loginlagerdbErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.loginlagerdbErrorMsg = "Login fehlgeschlagen"
        } else {
          status.loginlagerdbErrorMsg = error
        }
        
        localStorage.removeItem("lagerUser");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.LogoutLagerDB]({ commit, dispatch, rootGetters }) {
    await LagerService.logoutLager(new LagerLogoutRequest(rootGetters.lagerUser.refreshToken));
    await dispatch(ArchivActionTypes.Logout, undefined)
    
    sessionStorage.clear();
    let azureconfig = rootGetters.azureconfig;
    let msalConfig = rootGetters.msalConfig;
    localStorage.clear();
    localStorage.setItem("azureconfig", JSON.stringify(azureconfig))
    localStorage.setItem("msalconfig", JSON.stringify(msalConfig))
    
    commit(MutationTypes.LogoutLager, undefined)
    return;
  },

  async [ActionTypes.RefreshLoginLagerDB]({ commit, dispatch, rootGetters }) {
    const request = new RefreshLoginRequest(rootGetters.lagerUser.refreshToken)
    const res = await LagerService.refreshLoginLager(request);

    const result: LagerUser = res.data
    commit(MutationTypes.RefreshLoginLagerSuccess, result)

    localStorage.setItem("lagerUser", JSON.stringify(result));
    // status.loginlagerdbLoading = false
    // dispatch(CommonActionTypes.SetStatus, status);

    return;
  },
  
  async [ActionTypes.GetMitarbeiter]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.mitarbeiterLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getMitarbeiter()
      .then(res => {
        status.mitarbeiterLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Mitarbeiter = res.data
        commit(MutationTypes.GetMitarbeiterSuccess, result)
      })
      .catch(error => {
        status.mitarbeiterLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.mitarbeiterErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.mitarbeiterErrorMsg = "Login fehlgeschlagen"
        } else {
          status.mitarbeiterErrorMsg = error
        }
        
        sessionStorage.removeItem("mitarbeiter");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  }
}

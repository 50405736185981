import { ref } from "vue"

export class Mitarbeiter {
  id: number
  username: string
  mitarbeitername: string
  barcode: string
  abteilung: string
  bild: Array<number>
  tel: string
  mobil: string
  email: string
  funktion: number
  prodplan: number
  herstellung: number
  administrator: number
  verwaltung: number
  materialbestellung: number

  constructor() {
    this.id = 0
    this.username = ""
    this.mitarbeitername = ""
    this.barcode = ""
    this.abteilung = ""
    this.bild = new Array<number>()
    this.tel = ""
    this.mobil = ""
    this.email = ""
    this.funktion = 0
    this.prodplan = 0
    this.herstellung = 0
    this.administrator = 0
    this.verwaltung = 0
    this.materialbestellung = 0
  }
}

export class LagerUser {
  lagerUser: Mitarbeiter
  lagerToken: string
  refreshToken: string

  constructor() {
    this.lagerUser = new Mitarbeiter()
    this.lagerToken = ""
    this.refreshToken = ""
  }
}

export class LagerLogoutRequest {
  refreshtoken: string

  constructor (token: string) {
    this.refreshtoken = token
  }
}

export class RefreshLoginRequest {
  refreshtoken: string

  constructor (token: string) {
    this.refreshtoken = token
  }
}
import { MutationTree } from "vuex";
import { State } from "./state";
import { Maschine } from "@/models/MaschinenModels";


export enum MutationTypes {
  GetMaschinenSuccess = "GETMASCHINENSUCCESS",
}


export type Mutations = {
  [MutationTypes.GetMaschinenSuccess](state: State, data: Array<Maschine>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.GetMaschinenSuccess](state, data) {
    state.maschinen = data;
  },
  
}
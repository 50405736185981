<template>
  <div v-if="loginlagerLoading" class="text-center">
    <LoadingSpinner :text="'...Berechtigungen werden überprüft...'" />
  </div>
  
  <div v-else class="w-100">
    <MaschinenContent />
  </div>

  <!-- <div v-else class="text-center m-5">
    <div class="alert alert-danger" role="alert">Sie haben keinen Zugriff auf diese Daten</div>
  </div>     -->
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'

import MaschinenContent from '../Content/MaschinenContent.vue'

export default defineComponent({
  name: 'Produktion',

  components: {
    MaschinenContent,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const lagerUser = computed(() => store.getters.lagerUser)
    const loginlagerLoading = computed(() => store.getters.status.loginlagerdbLoading)

    return {
      store,
      lagerUser,
      loginlagerLoading
    }
  },

  methods: {
    loginLagerDB () {
      this.store.dispatch(MitarbeiterActionTypes.LoginLagerDB, undefined)
        .then(() => {
          this.loggedIn = true
        })
    },
  },


  data () {
    return {
      loggedIn: false
    }
  },

  mounted () {
    if(!localStorage.getItem('lagerUser')){
      this.loginLagerDB()
    }
    else {
      this.loggedIn = true
    }
  },
})
</script>

<style lang="scss" scoped>
// #produktion {
//   height: 100vh;
//   min-height: 100vh;
// }

</style>
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a079aa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-3 me-4" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "reihe-name" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "reihe-name" }
const _hoisted_9 = { class: "row mb-4" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "reihe-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LagerStellplatz = _resolveComponent("LagerStellplatz")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getBlockA, (row) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row",
            key: row.id,
            style: _normalizeStyle({'background': row.bgColor, 'color': row.textColor})
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, "M" + _toDisplayString(row.block) + _toDisplayString(_ctx.reiheNummer(row)) + " | " + _toDisplayString(_ctx.getAnzahlPalettenInReihe(row)) + ": ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.stellplaetze, (platz) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: platz.id
                }, [
                  _createVNode(_component_LagerStellplatz, { platz: platz }, null, 8, ["platz"])
                ]))
              }), 128))
            ])
          ], 4))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getBlockMB, (row) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: row.id,
              style: _normalizeStyle({'background': row.bgColor, 'color': row.textColor})
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(row.block) + _toDisplayString(_ctx.reiheNummer(row)) + " | " + _toDisplayString(_ctx.getAnzahlPalettenInReihe(row)) + ": ", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.stellplaetze, (platz) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: platz.id
                  }, [
                    _createVNode(_component_LagerStellplatz, { platz: platz }, null, 8, ["platz"])
                  ]))
                }), 128))
              ])
            ], 4))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getBlockMC, (row) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row",
            key: row.id,
            style: _normalizeStyle({'background': row.bgColor, 'color': row.textColor})
          }, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(row.block) + _toDisplayString(_ctx.reiheNummer(row)) + " | " + _toDisplayString(_ctx.getAnzahlPalettenInReihe(row)), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.stellplaetze, (platz) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: platz.id
                }, [
                  _createVNode(_component_LagerStellplatz, { platz: platz }, null, 8, ["platz"])
                ]))
              }), 128))
            ])
          ], 4))
        }), 128))
      ])
    ])
  ], 64))
}
import { AuthUser } from "@/models/ArchivModels";
import { MutationTree } from "vuex";
import { State } from "./state";
import { Bestandskontrolle, Rohstoffkontrolle } from "@/models/BestandskontrolleModels";

export enum MutationTypes {
  BestandskontrolleSuccess = "BESTANDSKONTROLLESUCCESS",
  BestandskontrolleTageSuccess = "BESTANDSKONTROLLETAGESUCCESS",
}


export type Mutations = {
  // [MutationTypes.BestandskontrolleSuccess](state: State, data: Rohstoffkontrolle): void
  [MutationTypes.BestandskontrolleSuccess](state: State, data: Array<Bestandskontrolle>): void
  [MutationTypes.BestandskontrolleTageSuccess](state: State, data: Array<Array<Bestandskontrolle>>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.BestandskontrolleSuccess](state, data) {
    state.bestandskontrolle = data;
  },
  
  [MutationTypes.BestandskontrolleTageSuccess](state, data) {
    state.bestandskontrolleTage = data;
  }
}
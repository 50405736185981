import { Mitarbeiter, LagerUser } from "@/models/MitarbeiterModels"
import { Listeppsid } from "@/models/PpsModels"

export type State = {
  lagerUser: LagerUser
  mitarbeiter: Mitarbeiter,
}

export const state: State = {
  lagerUser: localStorage.getItem("lagerUser") != null && localStorage.getItem("lagerUser") !== undefined ? JSON.parse(localStorage.lagerUser) : new LagerUser(),
  mitarbeiter: sessionStorage.getItem("mitarbeiter") != null && sessionStorage.getItem("mitarbeiter") !== undefined ? JSON.parse(sessionStorage.mitarbeiter) : new Mitarbeiter(),
}

import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Printdata } from "@/models/PrintdataModels"

export type Getters = {
  printdata(state: State): Array<Printdata>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  printdata(state) {
    return state.printdata;
  }
}

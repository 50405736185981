import { MutationTree } from "vuex";
import { State } from "./state";
import { Personalstatus } from "@/models/PersonalModels";


export enum MutationTypes {
  GetPersonalstatusSuccess = "GETPERSONALSTATUSSUCCESS",
}


export type Mutations = {
  [MutationTypes.GetPersonalstatusSuccess](state: State, data: Array<Personalstatus>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.GetPersonalstatusSuccess](state, data) {
    state.personal = data;
  },
  
}
import { ActionContext, ActionTree } from "vuex";
import LagerService from '@/service/LagerService'

import { RootState } from '@/store';

import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";
import { AuthUser, LoginRequest } from "@/models/ArchivModels";

import { Actions as CommonActions, ActionTypes as CommonActionTypes } from "../../Common/actions";
import { Bestandskontrolle, Rohstoffkontrolle } from "@/models/BestandskontrolleModels";
import { faCircleRight } from "@fortawesome/free-solid-svg-icons";



export enum ActionTypes {
  GetBestandskontrolle = "GETBESTANDSKONTROLLE",
  GetBestandskontrolleTage = "GETBESTANDSKONTROLLETAGE"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetBestandskontrolle](context: ActionArguments): Promise<void>,
  [ActionTypes.GetBestandskontrolleTage](context: ActionArguments, tage: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetBestandskontrolle]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.bestandskontrolleLoading = true
    dispatch(CommonActionTypes.SetStatus, status);


    return LagerService.getBestandskontrolle()
      .then(res => {
        const data: Array<Bestandskontrolle> = res.data

        // console.log("response");
        // console.log(data);

        // const splitData = data.reduce((r, a) => {
        //   console.log(r);
        //   console.log(a);
        //   r[a.datumist.toLocaleDateString()] = r[a.datumist.toLocaleDateString()] || [];
        //   r[a.datumist.toLocaleDateString()].push(a);
        //   return r;
        // }, Object.create(null));

        // console.log("######");
        // console.log(splitData);

        // /**
        //  * PRÜFEN ob das funtkioniert
        //  */
        // const result = new Rohstoffkontrolle()
        // result.d1 = splitData[0]
        // result.d2 = splitData[1]

        // let key: keyof typeof result
        // for (key in result) {
        //   result[key].sort((a: Bestandskontrolle, b: Bestandskontrolle) => {
        //     if (a.rohstoffname < b.rohstoffname) {
        //       return -1
        //     }
        //     if ( a.rohstoffname > b.rohstoffname) {
        //       return 1
        //     }
        //     return 0
        //   })
        // }

        status.bestandskontrolleLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        commit(MutationTypes.BestandskontrolleSuccess, data)
        sessionStorage.setItem("bestandskontrolleTage", JSON.stringify(data));
      })
      .catch(error => {
        status.bestandskontrolleLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.bestandskontrolleErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.bestandskontrolleErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.bestandskontrolleErrorMsg = error
        }
        
        sessionStorage.removeItem("bestandskontrolle");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  
  async [ActionTypes.GetBestandskontrolleTage]({ commit, dispatch, rootGetters }, tage) {
    const status = rootGetters.status;
    status.bestandskontrolleLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getBestandskontrolleRohstoffGruppiertTage(tage)
      .then(res => {
        let result: Array<Array<Bestandskontrolle>> = res.data

        result.sort((a: Array<Bestandskontrolle>, b: Array<Bestandskontrolle>) => {
          if (a[0].rohstoffname < b[0].rohstoffname) {
            return -1
          }
          if ( a[0].rohstoffname > b[0].rohstoffname) {
            return 1
          }
          return 0
        })

        status.bestandskontrolleLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        commit(MutationTypes.BestandskontrolleTageSuccess, result)
        sessionStorage.setItem("bestandskontrolleTage", JSON.stringify(result));
      })
      .catch(error => {
        status.bestandskontrolleLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.bestandskontrolleErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.bestandskontrolleErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.bestandskontrolleErrorMsg = error
        }
        
        sessionStorage.removeItem("bestandskontrolleTage");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  }
}

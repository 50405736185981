<template>
  <div class="card status-text-size p-3 border-0 shadow-sm rounded-0" id="planungMaschineHeute">

    <div class="card-header bg-white border-0">
      <div class="row">
        <div class="col-4">
          <select class="form-select form-select-sm rounded-0" v-model="selected"  @change="getPlanungMaschineHeute()">
            <option v-for="(option, index) in maschinenNamen" :key="index" :value="option.value" class="rounded-0">{{ option.text }}</option>
          </select>
        </div>
        <div class="col text-end text-secondary">
          <span class="me-3">{{ updateDatetimeConverter }}</span>
          <span id="sync" v-on:click="getPlanungMaschineHeute()">
            <font-awesome-icon icon="sync" />
          </span>
        </div>
      </div>
    </div>

    <div v-if="planungLoading" class="row text-center">
      <div class="col">
        <LoadingSpinner />
      </div>
    </div>
    <div v-else class="status-text-size text-start mx-3 my-2">
      <div v-if="selected != '' && (planung == null || planung.length == 0)" class="alert alert-info">
        Es wurden keine Planungsdaten für Maschine <strong>{{ selected }}</strong> gefunden.
      </div>
      
      <div v-else-if="selected == '' && (planung == null || planung.length == 0)">
      </div>
      
      <div v-else class="row py-2">
        <div class="col-4">
          <strong>Artikel</strong>
        </div>
        <div class="col-1">
          <strong>Herstellung</strong>
        </div>
        <div class="col-2">
          <strong>Maschinenführer</strong>
        </div>
        <div class="col-1">
          <strong>Land</strong>
        </div>
        <div class="col-1">
          <strong>EAS</strong>
        </div>
        <div class="col-1">
          <strong>KW</strong>
        </div>
        <div class="col-1">
          <strong>Soll</strong>
        </div>
        <div class="col-1">
          <strong>Ist</strong>
        </div>
      </div>
      <PlanungMaschineHeuteRow
        v-for="artikel in planung"
        :key="artikel.id"
        :artikel="artikel"
        class="py-1 striped"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from "vue";
import { useStore } from '@/store'
import { ActionTypes as PlanungActionTypes } from '@/store/modules/Lager/Planung/actions'

import { MaschineSelect } from "@/models/PlanungModels"

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PlanungMaschineHeuteRow from "./PlanungMaschineHeuteRow.vue";

export default defineComponent({
  name: "planungMaschineHeute",
  components: {
    PlanungMaschineHeuteRow,
    LoadingSpinner
  },

  setup () {
    const store = useStore()

    const planung = computed(() => store.getters.planung)
    const planungLoading = computed(() => store.getters.status.planungLoading)
    const produktionsstatus = computed(() => store.getters.produktionsstatus)

    const maschinenNamen = ref<Array<MaschineSelect>>([])
    const selected = ref("")
    const updatePlanungInterval = ref();
    const updateDatetime = ref<Date>(new Date());
    const updateDatetimeConverter = computed(() => {
      return ("0" + (updateDatetime.value.getHours())).slice(-2) + ":" + ("0" + (updateDatetime.value.getMinutes())).slice(-2)
    })

    return {
      store,
      planung,
      planungLoading,
      produktionsstatus,
      maschinenNamen,
      selected,
      updatePlanungInterval,
      updateDatetime,
      updateDatetimeConverter
    }
  },


  methods: {
    clearPlanung() {
      this.store.dispatch(PlanungActionTypes.ClearPlanung, undefined)
    },

    getPlanung() {
      this.store.dispatch(PlanungActionTypes.GetPlanung, this.selected)
        .then(() => {
          this.updateDatetime = new Date();
        })
    },

    upadatePlanungMaschineHeute() {
      this.store.dispatch(PlanungActionTypes.UpdateGetPlanung, this.selected)
        .then(() => {
          this.updateDatetime = new Date();
        })
    },


    // getProduktionsstatus() {
    //   this.store.dispatch(ProduktionstatusActionTypes.GetProduktionsstatus, undefined)
    // },

    getPlanungMaschineHeute() {
      if (this.selected == "") {
        clearInterval(this.updatePlanungInterval)
        this.clearPlanung()
      }
      else {
        this.getPlanung()

        clearInterval(this.updatePlanungInterval)
        this.updatePlanungInterval = setInterval(() => {
          this.upadatePlanungMaschineHeute()
        }, 5 * 60 * 1000)
      }
    },

    
  },

  mounted() {
    this.clearPlanung()
    this.maschinenNamen.push(new MaschineSelect("", "Wählen Sie eine Maschine aus"));
    this.produktionsstatus.forEach(maschine => {
      this.maschinenNamen.push(new MaschineSelect(maschine.maschinename, maschine.maschinename));
    });
  },

  beforeUnmount () {
    clearInterval(this.updatePlanungInterval)
  }
});
</script>

<style scoped>
#sync:hover {
  color: #dc3545;
  cursor: pointer;
}
</style>
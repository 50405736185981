import { MutationTree } from "vuex";
import { State } from "./state";
import { Hersteller, Herstellerartikel, Lieferant, Lieferantartikel, Lieferherstartikel, Materialartikel, Materiallieferartikel } from "@/models/LagerMaterialModels"



export enum MutationTypes {
  getMaterialartikelSuccess = "GETMATERIALARTIKELSUCCESS",
  getMateriallieferantartikelSuccess = "GETMATERIALLIERANTARTIKELSUCCESS",
  getLieferantartikelSuccess = "GETLIEFERANTARTIKELSUCCESS",
  getLieferantenSuccess = "GETLIEFERANTSUCCESS",
  getLieferherstartikelSuccess = "GETLIEFERHERSTARTIKELSUCCESS",
  getHerstellerartikelSuccess = "GETHERSTELLERARTIKELSUCCESS",
  getHerstellerSuccess = "GETHERSTELLERSUCCESS"
}


export type Mutations = {
  [MutationTypes.getMaterialartikelSuccess](state: State, data: Array<Materialartikel>): void
  [MutationTypes.getMateriallieferantartikelSuccess](state: State, data: Array<Materiallieferartikel>): void
  [MutationTypes.getLieferantartikelSuccess](state: State, data: Array<Lieferantartikel>): void
  [MutationTypes.getLieferantenSuccess](state: State, data: Array<Lieferant>): void
  [MutationTypes.getLieferherstartikelSuccess](state: State, data: Array<Lieferherstartikel>): void
  [MutationTypes.getHerstellerartikelSuccess](state: State, data: Array<Herstellerartikel>): void
  [MutationTypes.getHerstellerSuccess](state: State, data: Array<Hersteller>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.getMaterialartikelSuccess](state, data) {
    state.materialartikel = data
  },

  [MutationTypes.getMateriallieferantartikelSuccess](state, data) {
    state.materiallieferantartikel = data
  },

  [MutationTypes.getLieferantartikelSuccess](state, data) {
    state.lieferantartikel = data
  },

  [MutationTypes.getLieferantenSuccess](state, data) {
    state.lieferanten = data
  },

  [MutationTypes.getLieferherstartikelSuccess](state, data) {
    state.lieferherstartikel = data
  },

  [MutationTypes.getHerstellerartikelSuccess](state, data) {
    state.herstellerartikel = data
  },

  [MutationTypes.getHerstellerSuccess](state, data) {
    state.hersteller = data
  }
}
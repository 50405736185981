<template>
  <Sidebar  v-if="!isPublicPage" />

  <div class="d-flex flex-column w-100">
    <Navbar v-if="!isPublicPage" />

    <LoadingSpinner v-if="!isPublicPage && authLoading" :text="'... Sie werden abgemeldet ...'"/>
    <div id="main-content" class="p-3">
      <router-view />
    </div>
  </div>

  <!-- <div id="app">
  </div> -->
</template>

<script lang="ts">
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import { useRoute } from 'vue-router';

import { ActionTypes as ArchivActionTypes } from './store/modules/Archiv/actions';

export default defineComponent({
  name: 'app',
  components: {
    Sidebar,
    Navbar,
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const route = useRoute();

    const authLoading = computed(() => store.getters.authLoading)
    const loggedIn = computed(() => store.getters.status.archivLoggedIn)
    const sidebarCollapsed = computed(() => store.getters.status.sidebarCollapsed);

    const msalConfig = computed(() => store.getters.msalConfig)

    const publicPages = ["login", "logout"]
    const isPublicPage = computed(() => route.name != null && route.name != "" && publicPages.includes(route.name.toString()))

    return {
      store,
      authLoading,
      loggedIn,
      sidebarCollapsed,
      msalConfig,
      isPublicPage
    }
  },

  beforeCreate () {
    if (this.msalConfig == null) {
      this.store.dispatch(ArchivActionTypes.GetAzureLoginConfig, undefined);
    }
  }
})
</script>

<style lang="scss">
@import "./assets/sass/main.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  // flex-direction: column;
}

#main-content {
  height: 100%;
  background: rgb(242, 243, 248);
}

.striped:nth-of-type(even) {
  background: rgba(0, 0, 0, 0.05);
}

.status-text-size {
  font-size: 12px;
}

@media screen and (max-width: 1200px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 12px !important;
  }
}

@media screen and (max-width: 930px) {
  .status-text-size {
    font-size: 11px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 730px) {
  .status-text-size {
    font-size: 9px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 6px !important;
  }
}

@media screen and (max-width: 430px) {
  .status-text-size {
    font-size: 7px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 3px !important;
  }
}
</style>

import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { KeyValuePair, Planung } from "@/models/PlanungModels"

export type Getters = {
  planung(state: State): Array<Planung>,
  palettenProStunde(state: State): Array<KeyValuePair>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  planung(state) {
    return state.planung;
  },

  palettenProStunde(state) {
    return state.palettenProStunde;
  }
}

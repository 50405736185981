import { AuthUser } from "@/models/ArchivModels"
import { Configuration } from "@azure/msal-browser"

import { MitarbeiterArbeitszeit } from "@/models/PersonaldatenModels"

export type State = {
  mitarbeiterArbeitszeiten: Array<MitarbeiterArbeitszeit>
}

export const state: State = {
  mitarbeiterArbeitszeiten: localStorage.getItem("mitarbieterArbeitszeiten") != null && localStorage.getItem("mitarbieterArbeitszeiten") != undefined ? JSON.parse(localStorage.mitarbieterArbeitszeiten) : new Array<MitarbeiterArbeitszeit>(),
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fbffc7b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "produktion" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}
const _hoisted_3 = {
  key: 1,
  class: "w-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_ProduktionContent = _resolveComponent("ProduktionContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loginlagerLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LoadingSpinner)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ProduktionContent)
        ]))
  ]))
}
<template>
  <div class="d-flex justify-content-between align-items-center mb-2">
    <span class="status-text">Aktualisiert um: {{ aktualisiertam }}</span>
    <button type="button" class="btn btn-outline-primary btn-sm" title="Info/Legende" data-bs-toggle="modal" data-bs-target="#infoModal"><font-awesome-icon  icon="info" /> - Legende</button>
  </div>
  
  <ProduktionLagerLayout :aktualisiertam="aktualisiertam" />

  <div
    class="modal fade"
    id="infoModal"
    tabindex="-1"
    aria-labelledby="infoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header py-1">
          <h5 class="modal-title">Legende</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <InfoModelContent />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
// import { useStore } from '@/store'
// import { mapGetters } from 'vuex'
import ProduktionLagerLayout from './ProduktionLagerLayout.vue'
import InfoModelContent from './InfoModelContent.vue'


export default defineComponent({
name: 'ProduktionLagerContent',
components: {
  ProduktionLagerLayout,
  InfoModelContent
},
// computed: mapGetters(['lager']),
props: {
  aktualisiertam: {
    type: String,
    required: true
  }
},
})
</script>


<style scoped>
/* #lagerContent {
background: rgb(242, 243, 248);
} */
.status-text {
  font-size: .8em;
}
</style>
import { GetterTree } from "vuex";
import { State } from "./state";

import { RootState } from "@/store";
import { Produktionsstatus, Maschinenplanung } from "@/models/ProduktionsstatusModels"

export type Getters = {
  produktionsstatus(state: State): Array<Produktionsstatus>,
  maschinenPlanungHeute(state: State): Array<Maschinenplanung>
}

export const getters: GetterTree<State, RootState> & Getters = {
  produktionsstatus(state) {
    return state.produktionsstatus;
  },

  maschinenPlanungHeute(state) {
    return state.maschinenPlanungHeute;
  }
}

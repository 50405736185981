<template>
  <div id="planungPalettenProStunde" class="card status-text-size p-3 border-0 shadow-sm rounded-0">
    <div class="card-header bg-white border-0">
      <p class="text-secondary mb-0">Paletten pro Stunde</p>
    </div>

    <div v-if="planungLoading" class="row text-center">
      <div class="col">
        <LoadingSpinner />
      </div>
    </div>
    <div v-else class="status-text-size text-start mx-3 my-2">
      <div class="row py-2">
        <div class="col">
          <strong>Zeit</strong>
        </div>
        <div class="col">
          <strong>PAL</strong>
        </div>
      </div>

      <PlanungPalettenProStundeRow v-for="(anzahl, index) in palettenProStunde" :key="index" :anzahl="anzahl" class="py-1 striped" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import PlanungPalettenProStundeRow from "./PlanungPalettenProStundeRow.vue";

export default defineComponent({
  name: "planungPalettenProStunde",
  components: {
    PlanungPalettenProStundeRow,
    LoadingSpinner
  },

  setup () {
    const store = useStore()

    const palettenProStunde = computed(() => store.getters.palettenProStunde)
    const planungLoading = computed(() => store.getters.status.planungLoading)

    return {
      store,
      palettenProStunde,
      planungLoading
    }
  }
});
</script>

<style scoped>
#planungPalettenProStunde {
  height: 100%;
}
</style>
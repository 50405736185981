<template>
  <div id="verwaltung" class="contentArea d-flex w-100">
    <div v-if="loginlagerLoading" class="text-center m-5">
      <LoadingSpinner />
    </div>
    <div v-else-if="loggedIn && lagerUser && lagerUser.lagerUser.materialbestellung == 1" class="contentArea d-flex w-100">
      <!-- <VerwaltungSidebar @setContent="setContentArea"/> -->

      <!-- <VerwaltungMitarbeiterContent v-if="content == 'mitarbeiter'" /> -->
      <VerwaltungDownloadContent v-if="content == 'download'"/>
      <div v-else class="m-4 float-center w-100">
        <div class="alert alert-info" role="alert">Bitte wählen Sie eine Seite aus.</div>

      </div> 
    </div>
    <div v-else class="text-center m-5">
      <div class="alert alert-danger" role="alert">Sie haben keinen Zugriff auf diese Daten</div>
    </div>    
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent } from "vue";
import { useStore } from '@/store'
import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'

// import VerwaltungSidebar from '@/components/Verwaltung/Content/VerwaltungSidebar'
import VerwaltungDownloadContent from '@/components/Verwaltung/Content/VerwaltungDownloadContent.vue'
// import VerwaltungMitarbeiterContent from '@/components/Verwaltung/Content/VerwaltungMitarbeiterContent'

export default defineComponent({
  name: 'Verwaltung',
  components: {
    // VerwaltungSidebar,
    VerwaltungDownloadContent,
    LoadingSpinner
    // VerwaltungMitarbeiterContent
  },

  setup () {
    const store = useStore();

    const lagerUser = computed(() => store.getters.lagerUser)
    const loginlagerLoading = computed(() => store.getters.status.loginlagerdbLoading)

    return {
      store,
      lagerUser,
      loginlagerLoading
    }
  },

  methods: {
    loginLagerDB () {
      this.store.dispatch(MitarbeiterActionTypes.LoginLagerDB, undefined)
        .then(() => {
          this.loggedIn = true
        })
    },

    setContentArea(value: string) {
      this.content = value
    }
  },
  data () {
    return {
      loggedIn: false,
      content: 'download'
    }
  },
  mounted () {
    if(!localStorage.getItem('lagerUser')){
      this.loginLagerDB()
    }
    else {
      this.loggedIn = true
    }
  }
})
</script>

<style scoped>
#verwaltung,
.contentArea {
  height: 100vh;
  /* overflow: hidden; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#verwaltung::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#verwaltung {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.backgroundColor {
  background: rgb(242, 243, 248);
}

</style>
<template>
  <div v-if="loginlagerLoading" class="text-center m-5">
    <LoadingSpinner :text="'...Sie werden angemeldet...'" />
  </div>
  
  <!-- <div v-else-if="lager == null || fetchingData" class="text-center m-5"> -->
  <div v-else-if="lagerLoading" class="text-center m-5">
    <LoadingSpinner :text="'...Daten werden geladen...'" />
  </div>

  <div v-else-if="lager == null || lager.length == 0" class="text-center m-5">
    <div class="alert alert-warning" role="alert">
      Es wurden keine Daten gefunden.
    </div>
  </div>

  <div v-else>
    <HerstellungLagerContent :aktualisiertam="aktualisiertAm" />
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'

import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'
import { ActionTypes as LagerActionTypes } from '@/store/modules/Lager/Lager/actions'

import HerstellungLagerContent from '@/components/Lager/Content/HerstellungLagerContent.vue'
import moment from 'moment'


export default defineComponent ({
  name: 'HerstellungLager',
  components: {
    LoadingSpinner,
    HerstellungLagerContent
  },

  setup () {
    const store = useStore();

    const lagerUser = computed(() => store.getters.lagerUser)
    const loginlagerLoading = computed(() => store.getters.status.loginlagerdbLoading)
    const lagerLoading = computed(() => store.getters.status.lagerartikelLoading || store.getters.status.planungLoading || store.getters.status.lagerLoading)

    const lager = computed(() => store.getters.lager)

    const updateStatusInterval = ref();

    return {
      store,
      lagerUser,
      loginlagerLoading,
      lagerLoading,
      lager,
      updateStatusInterval
    }
  },
  methods: {
    loginLagerDB () {
      this.store.dispatch(MitarbeiterActionTypes.LoginLagerDB, undefined)
        .then(() => {
          this.loggedIn = true
        })
    },
  },
  data () {
    return {
      loggedIn: false,
      aktualisiertAm: '',
      // fetchingData: true
    }
  },
  mounted () {
    if(!this.lagerUser){
      this.loginLagerDB()
    }
    else {
      this.loggedIn = true
    }

    this.store.dispatch(LagerActionTypes.GetLagerzustand, undefined)
      .then(() => {
        this.aktualisiertAm =  moment(Date.now()).format('HH:mm')
        this.updateStatusInterval = setInterval(async () => {
          await this.store.dispatch(LagerActionTypes.UpdateGetLagerzustand, undefined)
          this.aktualisiertAm =  moment(Date.now()).format('HH:mm')
        }, 5 * 60 * 1000);
      })
  },

  beforeUnmount () {
    clearInterval(this.updateStatusInterval)
  }
});
</script>

<style>
</style>
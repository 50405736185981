<template>
  <div id="planungPalettenProStundeChartCard" class="card status-text-size text-left">
    <div class="card-header">
      <strong>LA Differenz</strong>
    </div>
      <!-- <BestandskontrolleTageChart :chartdata="datacollection" :options="options" :height="height"/> -->
    <BestandskontrolleTageChart :labels="labels" :values="values" :titel="alkohol[0].rohstoffname"/>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useStore } from '@/store'

import { ActionTypes as BestandskontrolleActionTypes } from '@/store/modules/Lager/Bestandskontrolle/actions'

import { Bestandskontrolle } from "@/models/BestandskontrolleModels"

import BestandskontrolleTageChart from './BestandskontrolleTageChart.vue'

export default defineComponent({
  name: "bestandskontrolleTageChartCard",
  components: {
    BestandskontrolleTageChart,
  },

  setup (props) {
    const store = useStore();

    const bestandskontrolleTage = computed(() => store.getters.bestandskontrolleTage)
    const bestandskontrolleLoading = computed(() => store.getters.status.bestandskontrolleLoading)

    const values = computed(() => props.alkohol.map(l => (l.laist - l.lasoll)))
    const labels = computed(() => {
      let dates = props.alkohol.map(d => d.datum)
      let newDates = new Array<string>()
      
      dates.forEach(d => {
        let date = new Date(d)

        newDates.push(("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + ".")
      })

      return newDates
    })

    return {
      store,
      bestandskontrolleTage,
      bestandskontrolleLoading,
      values,
      labels
    }
  },

  props: {
    alkohol: {
      type: Object as PropType<Array<Bestandskontrolle>>,
      default: new Bestandskontrolle()
    }
  },
})
</script>
<template>
  <div id="home" class="p-3">
    <LoadingSpinner v-if="loginlagerLoading" :text="'... Lager Anmeldung wird überpürft ...'" />

    <div v-else>
      <div class="row">
        <div class="col">
          <h1>EW Dashboard</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { computed, ref, defineComponent } from "vue";
import { useStore } from '@/store'

import { ActionTypes as MitarbeiterActionTypes } from '@/store/modules/Lager/Mitarbeiter/actions'

// import Bestandskontrolle from '@/components/Herstellung/Content/Bestandskontrolle'
// import { mapActions, mapGetters } from 'vuex'
export default defineComponent({
  name: 'home',
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const lagerUser = computed(() => store.getters.lagerUser)
    const loginlagerLoading = computed(() => store.getters.status.loginlagerdbLoading)

    return {
      store,
      lagerUser,
      loginlagerLoading
    }
  },

  methods: {
    loginLagerDB () {
      this.store.dispatch(MitarbeiterActionTypes.LoginLagerDB, undefined)
    },

    // getBestand () {
    //   this.store.dispatch()
    // }
  },

  mounted () {
    if(!localStorage.getItem('lagerUser')){
      this.loginLagerDB()
    }
  }
})
</script>

<style scoped>
/* #home {
  background: rgb(242, 243, 248);
} */
</style>
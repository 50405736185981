<template>
  <div class="row mb-4">
    <div class="col-3 me-4">
      <div class="row" v-for="row in getBlockA" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
        <div class="col">
          <div class="reihe-name">M{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
          <div v-for="platz in row.stellplaetze" :key="platz.id">
            <LagerStellplatz :platz="platz" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-2">
      <div class="mb-3">
        <div class="row" v-for="row in getBlockMB" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
          <div class="col">
            <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}: </div>
            <div v-for="platz in row.stellplaetze" :key="platz.id">
              <LagerStellplatz :platz="platz" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col">
      <div class="row" v-for="row in getBlockMC" :key="row.id" :style="{'background': row.bgColor, 'color': row.textColor}">
        <div class="col">
          <div class="reihe-name">{{row.block}}{{reiheNummer(row)}} | {{getAnzahlPalettenInReihe(row)}}</div>
          <div v-for="platz in row.stellplaetze" :key="platz.id">
            <LagerStellplatz :platz="platz" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from '@/store'
import LagerStellplatz from './LagerStellplatz.vue'
import { Lagerreihe, Listeartikel } from '@/models/LagerModels';

export default defineComponent({
  name: "LagerLayout",
  
  components: {
    LagerStellplatz,
  },
  
  props: {
    aktualisiertam: {
      type: String,
      required: true
    }
  },

  setup () {
    const store = useStore();
    const lager = computed(() => store.getters.lager);
    const lagerartikel = computed(() => store.getters.lagerartikel);

    const fertigwarenStellplaetze = computed(() => store.getters.fertigwarenStellplaetze)
    const lagerpuffer = computed(() => store.getters.lagerpuffer)
    const produktionspuffer = computed(() => store.getters.produktionspuffer)

    return {
      store,
      lager,
      lagerartikel,
      fertigwarenStellplaetze,
      lagerpuffer,
      produktionspuffer
    }
  },

  computed: {
    // ...mapGetters(['lager', 'lagerartikel', 'fertigwarenStellplaetze', 'lagerStatus', 'lagerpuffer', 'produktionspuffer']),
    getBlockA() {
      return this.lager.filter((el: Lagerreihe) => el.block == "A").reverse()
    },
    getBlockMB() {
      return this.lager.filter((el: Lagerreihe) => el.block == "MB").reverse()
    },
    getBlockMC() {
      return this.lager.filter((el: Lagerreihe) => el.block == "MC")
    },
    getAnzahlPalettenBlockA() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('A')).length
    },
    getAnzahlPalettenBlockMB() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('MB')).length
    },
    getAnzahlPalettenBlockMC() {
      return this.lagerartikel.filter((el: Listeartikel) => el.lagerplatzname.includes('MC')).length
    },
  },

  methods: {
    reiheNummer(row: Lagerreihe) {
      return row.reihe < 10 ? `0${row.reihe}` : row.reihe
    },

    getAnzahlPalettenInReihe(row: Lagerreihe) {
      let count = 0
      row.stellplaetze.forEach(el => {
        if (el.artikel != null) {
          count++
        }
      })

      return count < 10 ? `0${count}` : count
    },
  }
});
</script>

<style scoped>
.reihe-name {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 0.7em;
}
</style>
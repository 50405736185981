import { Produktionsstatus, Maschinenplanung } from "@/models/ProduktionsstatusModels"

export type State = {
  produktionsstatus: Array<Produktionsstatus>,
  maschinenPlanungHeute: Array<Maschinenplanung>
}

export const state: State = {
  produktionsstatus: new Array<Produktionsstatus>(),
  maschinenPlanungHeute: new Array<Maschinenplanung>()
}

<template>
  <div id="infoModelContent" class="px-2">

    <div v-if="loading"  class="text-center m-5">
      <LoadingSpinner text="...Daten werden geladen..." />
    </div>

    <div v-else-if="lagerplatzarten != null && lagerplatzarten.length > 0">
      <div class="row">
        <div class="col-1">Farbe</div>
        <div class="col-5">Titel</div>
        <div class="col-6">Info</div>
      </div>

      <hr>

      <div class="row mb-2" v-for="art in lagerplatzarten" :key="art.id">
        <div class="col-1 border border-dark" :style="{'background': '#' + art.bgColor}"></div>
        <div class="col-5">{{art.titel}}</div>
        <div class="col-6">{{art.text}}</div>
      </div>

      <hr>

      <div class="row">
        <div class="col-1 text-danger">X00 | 00</div>
        <div class="col-5">Reihe nicht aktiv</div>
        <div class="col-6"></div>
      </div>
    </div>

    <div v-else>
      <div class="alert alert-info">Es stehen keine Informationen zur Verfügung.</div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store"
import { defineComponent, computed } from "vue"
import { ActionTypes as LagerActionTypes } from "@/store/modules/Lager/Lager/actions"

import LoadingSpinner from "@/components/LoadingSpinner.vue"

export default defineComponent({
  name: 'InfoModelContent',

  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore()

    const lagerplatzarten = computed(() => store.getters.lagerplatzarten)
    const loading = computed(() => store.getters.status.lagerplatzartenLoading)

    return {
      store,
      loading,
      lagerplatzarten
    }
  },

  mounted () {
    if (this.lagerplatzarten == null || this.lagerplatzarten.length == 0) {
      this.store.dispatch(LagerActionTypes.GetLagerplatzartenDaten, undefined)
    }
  }

})
</script>

<style scoped>
.color-info{
  float: left;
  width: 30px;
  height: 27px;
  margin: 1px;
  padding: 1px;
  border: 1px solid black;
  font-size: 0.8em;
  line-height: 0.95em;
  /* color: white; */
}

.reihe-name {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  color: red;
}
</style>
import { ActionContext, ActionTree } from "vuex";
import LagerService from '@/service/LagerService'

import { RootState } from '@/store';

import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";
import { AuthUser, LoginRequest } from "@/models/ArchivModels";

import { Actions as CommonActions, ActionTypes as CommonActionTypes } from "../../Common/actions";
import { Bestandskontrolle, Rohstoffkontrolle } from "@/models/BestandskontrolleModels";
import { Listeartikel } from "@/models/LagerModels";
import { Hersteller, Herstellerartikel, Lieferant, Lieferantartikel, Lieferherstartikel, Materialartikel, Materiallieferartikel } from "@/models/LagerMaterialModels";



export enum ActionTypes {
  fetchMaterialartikel = "FETCHMATERIALARTIKEL",
  fetchMateriallieferantartikel = "FETCHMATERIALLIEFERANTARTIKEL",
  fetchLieferantartikel = "FETCHLIEFERANTARTIKEL",
  fetchLieferanten = "FETCHLIEFERANT",
  fetchLieferherstartikel = "FETCHLIEFERHERSTARTIKEL",
  fetchHerstellerartikel = "FETCHHERSTELLERARTIKEL",
  fetchHersteller = "FETCHHERSTELLER",
  
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.fetchMaterialartikel](context: ActionArguments): Promise<void>,
  [ActionTypes.fetchMateriallieferantartikel](context: ActionArguments): Promise<void>,
  [ActionTypes.fetchLieferantartikel](context: ActionArguments): Promise<void>,
  [ActionTypes.fetchLieferanten](context: ActionArguments): Promise<void>,
  [ActionTypes.fetchLieferherstartikel](context: ActionArguments): Promise<void>,
  [ActionTypes.fetchHerstellerartikel](context: ActionArguments): Promise<void>,
  [ActionTypes.fetchHersteller](context: ActionArguments): Promise<void>,
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.fetchMaterialartikel]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.materialartikelLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getMaterialartikel()
      .then(res => {
        status.materialartikelLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Materialartikel> = res.data
        commit(MutationTypes.getMaterialartikelSuccess, result);
      })
      .catch(error => {
        status.materialartikelLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.materialartikelErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.materialartikelErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.materialartikelErrorMsg = error
        }
        
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.fetchMateriallieferantartikel]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.materiallieferantartikelLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getMateriallieferantartikel()
      .then(res => {
        status.materiallieferantartikelLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Materiallieferartikel> = res.data
        commit(MutationTypes.getMateriallieferantartikelSuccess, result);
      })
      .catch(error => {
        status.materiallieferantartikelLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.materiallieferantartikelErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.materiallieferantartikelErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.materiallieferantartikelErrorMsg = error
        }
        
        sessionStorage.removeItem("materiallieferantartikel");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.fetchLieferantartikel]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.lieferantartikelLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getLieferantartikel()
      .then(res => {
        status.lieferantartikelLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Lieferantartikel> = res.data
        commit(MutationTypes.getLieferantartikelSuccess, result);
      })
      .catch(error => {
        status.lieferantartikelLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.lieferantartikelErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.lieferantartikelErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.lieferantartikelErrorMsg = error
        }
        
        sessionStorage.removeItem("lieferantartikel");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.fetchLieferanten]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.lieferantLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getLieferanten()
      .then(res => {
        status.lieferantLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Lieferant> = res.data
        commit(MutationTypes.getLieferantenSuccess, result);
      })
      .catch(error => {
        status.lieferantLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.lieferantErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.lieferantErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.lieferantErrorMsg = error
        }
        
        sessionStorage.removeItem("lieferanten");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.fetchLieferherstartikel]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.lieferherstartikelLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getLieferherstartikel()
      .then(res => {
        status.lieferherstartikelLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Lieferherstartikel> = res.data
        commit(MutationTypes.getLieferherstartikelSuccess, result);
      })
      .catch(error => {
        status.lieferherstartikelLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.lieferherstartikelErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.lieferherstartikelErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.lieferherstartikelErrorMsg = error
        }
        
        sessionStorage.removeItem("lieferherstartikel");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.fetchHerstellerartikel]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.herstellerartikelLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getHerstellerartikel()
      .then(res => {
        status.herstellerartikelLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Herstellerartikel> = res.data
        commit(MutationTypes.getHerstellerartikelSuccess, result);
      })
      .catch(error => {
        status.herstellerartikelLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.herstellerartikelErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.herstellerartikelErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.herstellerartikelErrorMsg = error
        }
        
        sessionStorage.removeItem("herstellerartikel");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.fetchHersteller]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.herstellerLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getHersteller()
      .then(res => {
        status.herstellerLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Hersteller> = res.data
        commit(MutationTypes.getHerstellerSuccess, result);
      })
      .catch(error => {
        status.herstellerLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.herstellerErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.herstellerErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.herstellerErrorMsg = error
        }
        
        sessionStorage.removeItem("hersteller");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

}

<template>
  <div class="card mb-4 status-text-size">
    <div class="card-header">
      <div class="row">
        <div class="col text-start">
          <strong>Rohstoffkontrolle</strong>
        </div>
      </div>
    </div>

    <div class="card-text">
      <div v-if="bestandskontrolleLoading">
        <LoadingSpinner />
      </div>
      <div class="text-start px-3 py-2" v-if="bestandskontrolle !== null">
        <div id="bestandskontrolle-header" class="row border border-muted py-2">
          <div class="col-2">
            <strong>Rohstoff</strong>
          </div>
          <div class="col-2">
            <strong>Bestandsdatum<br>Tagesende</strong>
          </div>
          <div class="col-2">
            <strong>Messdatum</strong>
          </div>
          <div class="col-1">
            <strong>Liter soll</strong>
          </div>
          <div class="col-1">
            <strong>Liter ist</strong>
          </div>
          <div class="col-1">
            <strong>Differenz</strong>
          </div>
          <div class="col-1">
            <strong>LA soll</strong>
          </div>
          <div class="col-1">
            <strong>LA gemessen</strong>
          </div>
          <div class="col-1">
            <strong>Differenz</strong>
          </div>
        </div>

        <div class="my-3">
          <BestandskontrolleRow
          v-for="rohstoff in bestandskontrolle"
          :key="rohstoff.id"
          :rohstoff="rohstoff"
          class="border-end border-bottom border-start border-muted py-1 striped"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { computed, defineComponent } from "vue";
import { useStore } from '@/store'

import { ActionTypes as BestandskontrolleActionTypes } from '@/store/modules/Lager/Bestandskontrolle/actions'

import BestandskontrolleRow from "@/components/Herstellung/Content/BestandskontrolleRow.vue";

export default defineComponent({
  name: "Bestandskontrolle",
  components: {
    BestandskontrolleRow,
    LoadingSpinner
  },

  setup () {
    const store = useStore();

    const bestandskontrolle = computed(() => store.getters.bestandskontrolle)
    const bestandskontrolleLoading = computed(() => store.getters.status.bestandskontrolleLoading)

    return {
      store,
      bestandskontrolle,
      bestandskontrolleLoading
    }
  },

  methods: {
    getBestand () {
      this.store.dispatch(BestandskontrolleActionTypes.GetBestandskontrolle, undefined)
    }
  },
  data () {
    return {
      rohstoff: {}
    };
  },
  mounted () {
    this.getBestand()
  }
});
</script>

<style lang="scss" scoped>
#bestandskontrolle-header {
  margin-bottom: -1rem;
}

.status-text-size {
  font-size: 12px;
}

@media screen and (max-width: 1200px) {
  .status-text-size {
    font-size: 11px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 11px;
  }
}

@media screen and (max-width: 930px) {
  .status-text-size {
    font-size: 11px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 8px;
  }
}

@media screen and (max-width: 730px) {
  .status-text-size {
    font-size: 9px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 5px;
  }
}

@media screen and (max-width: 430px) {
  .status-text-size {
    font-size: 7px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 3px;
  }
}
</style>
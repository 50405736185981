<template>
  <div class="rounded-0 shadow-sm maschine-leistung-card" :class="getBgColor()">
    <div class="d-flex justify-content-between align-items-center p-1 bg-primary text-white">
      <h5 class="mb-0">{{ maschine.maschinename }}</h5>

      
      <span><span v-if="currentProdAuftrag != undefined">{{ currentProdAuftrag.gesamtanzahlIst }} von {{ currentProdAuftrag.gesamtanzahl }} Paletten</span><span v-else>Kein Auftrag gestartet</span></span>
    </div>

    <LoadingSpinner v-if="thisLoading && loading" :text="'...Daten werden geladen...'" />

    <div v-else class="px-2 pt-1 pb-0">
      <p class="mb-0 text-truncate"><strong v-if="currentProdAuftrag != undefined">{{ currentProdAuftrag.landkz }} | {{ currentProdAuftrag.artikel }}</strong><span v-else>Kein Auftrag gestartet</span></p>
      
      <div v-if="maschine.maschinenr != null">
        <GaugeChart :id="maschine.maschinenr.toString()" :maxLeistung="maschine.flaschenstunde" :sollLeistung="sollLeistung" :istLeistung="maschineLeistung?.w1 || 0" />
      </div>
      <div v-else class="d-flex justify-content-center">
        <h2>Ungültige Maschinen-Nr.</h2>
      </div>
      
      <p class="text-center fs-6 mb-0">Soll-Leistung: <strong>{{  sollLeistung }}</strong></p>

      <div class="px-2 mt-1 text-secondary w-100">
        <div class="d-flex justify-content-between">
          <small> {{ currentProdAuftrag?.id }}</small>
          
          <small>Stand: {{ maschineLeistungDateConverter }}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="progress-container">
    <div class="progress-bar-text" >{{ summeFlaschenIst }} von {{ summeFlaschenTag }}</div>

    <div class="progress rounded-0" role="progressbar" aria-label="Tagesziel" :aria-valuenow="summeFlaschenIst" aria-valuemin="0" :aria-valuemax="summeFlaschenTag">
      <!-- <div class="progress-bar progress-bar-striped bg-success fw-bold px-2 overflow-visible text-dark" :style="'width: ' + summeFlaschenIstProzent + '%'">{{ summeFlaschenIst }} von {{ summeFlaschenTag }}</div> -->
      <div class="progress-bar progress-bar-striped" :class="getProgressBarColor()" :style="'width: ' + summeFlaschenIstProzent + '%'"></div>
    </div>

  </div>

  <div class="w-100 d-flex justify-content-between">
    <small class="text-secondary">{{ produktionStartZeitConverter }}</small>
    <small class="text-secondary">{{ planungEndeUhrzeitConverter }}</small>
  </div>


</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from '@/store'

import { ActionTypes as EnergiezaehlerActionTypes } from '@/store/modules/Energiezaehler/actions';

import { Maschine } from '@/models/MaschinenModels';
import { Maschinenplanung } from '@/models/ProduktionsstatusModels';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import GaugeChart from './GaugeChart.vue';

export default defineComponent({
  name: 'MaschineLeistungCard',

  components: {
    LoadingSpinner,
    GaugeChart
  },

  props: {
    maschine: {
      type: Object as PropType<Maschine>,
      required: true
    },

    maschinenplanung: {
      type: Object as PropType<Maschinenplanung> || undefined,
      default: undefined
    }
  },

  setup(props) {
    const store = useStore()

    const thisLoading = ref(false)
    const loading = computed(() => store.getters.status.energiezaehlerLoading)
    const errorMsg = computed(() => store.getters.status.energiezaehlerErrorMsg)

    const currentProdAuftrag = computed(() => {
      const maschineArtikelNr = props.maschinenplanung?.artikelnr
      const maschineLandNr = props.maschinenplanung?.landnr

      return props.maschinenplanung?.planung.listePlanungResource.find(p => p.artikelnr == maschineArtikelNr && p.landnr == maschineLandNr && p.gesamtanzahlIst < p.gesamtanzahl)
    })

    const produktionStartZeit = computed(() => {
      if (props.maschinenplanung == null || props.maschinenplanung.planung.listePlanungResource.length == 0) {
        return undefined;
      }

      const first = props.maschinenplanung?.planung.listePlanungResource.reduce((prev, curr) => (curr).priority < prev.priority ? curr : prev)

      if (first.startzeit != null) {
        return first.startzeit 
      }
      else {
        let beginngen = new Date();
        beginngen.setHours(6, 0, 0, 0);
        return beginngen;
      }
    })

    const produktionStartZeitConverter = computed(() => {
      if (produktionStartZeit.value == null) {
        return "-";
      }

      const date = new Date(produktionStartZeit.value)
      return ("0" + (date.getHours())).slice(-2) + ":" + ("0" + (date.getMinutes())).slice(-2)
    })

    function getCurrentProdAuftragSollProdzeitInMinuten(): number | undefined {
      if (currentProdAuftrag.value == null) {
        return undefined;
      }

      const sollProduktionszeit = currentProdAuftrag.value.gesamtanzahlIst / currentProdAuftrag.value.palettenfaktor;
      const ganzzahl = Math.floor(sollProduktionszeit);
      const kommazahl = Math.round(sollProduktionszeit - ganzzahl);

      return ganzzahl * 60 + kommazahl * 60;
    }

    const sollUhrzeit = computed(() => {
      if (props.maschinenplanung == null || currentProdAuftrag.value == null || produktionStartZeit.value == null) {
        return undefined;
      }

      const prevProdauftraege = props.maschinenplanung.planung.listePlanungResource.filter(p => currentProdAuftrag.value && p.priority < currentProdAuftrag.value.priority)

      let gesamtProduktionszeit = prevProdauftraege.reduce((acc, curr) => {
        return acc + curr.prodDauerInMinuten
      }, 0)

      const currProdauftragSollProdzeit = getCurrentProdAuftragSollProdzeitInMinuten();

      if (currProdauftragSollProdzeit) {
        gesamtProduktionszeit += currProdauftragSollProdzeit;
      }

      const sollZeit = new Date(produktionStartZeit.value);
      sollZeit.setMinutes(sollZeit.getMinutes() + gesamtProduktionszeit);
      return sollZeit;

    })

    const sollUhrzeitConverter = computed(() => {
      if (sollUhrzeit.value == null) {
        return "-";
      }

      const date = new Date(sollUhrzeit.value)
      return ("0" + (date.getHours())).slice(-2) + ":" + ("0" + (date.getMinutes())).slice(-2) + " Uhr"
    })

    const planungEndeUhrzeit = computed(() => {
      if (props.maschinenplanung == null || produktionStartZeit.value == null) {
        return undefined;
      }

      const gesamtProduktionszeit = props.maschinenplanung.planung.listePlanungResource.reduce((acc, curr) => {
        return acc + curr.prodDauerInMinuten
      }, 0)

      const sollZeit = new Date(produktionStartZeit.value);

      sollZeit.setMinutes(sollZeit.getMinutes() + gesamtProduktionszeit);
      return sollZeit;
    })

    const planungEndeUhrzeitConverter = computed(() => {
      if (planungEndeUhrzeit.value == null) {
        return "-";
      }

      const date = new Date(planungEndeUhrzeit.value)
      return ("0" + (date.getHours())).slice(-2) + ":" + ("0" + (date.getMinutes())).slice(-2)
    })

    function getProgressBarColor() {
      const now = new Date();

      if (sollUhrzeit.value == null) {
        return "bg-secondary opacity-75"
      }
      else if (sollUhrzeit.value < now) {
        return "bg-danger opacity-100"
      }
      else {
        return "bg-success opacity-75"
      }
    }


    const sollLeistung = computed(() => {
      if (currentProdAuftrag.value == null) {
        return undefined;
      }

      return Math.floor(currentProdAuftrag.value?.anzahlflaschen * currentProdAuftrag.value?.anzahlkartons *  currentProdAuftrag.value?.palettenfaktor)
    })

    const maschineLeistung = computed(() => store.getters.maschinenLeistungsWerte.find(m => m.s == props.maschine.maschinenr));

    const maschineLeistungDateConverter = computed(() => {
      if (maschineLeistung.value == null || maschineLeistung.value.mdate == null) {
        return "unbekannt";
      }

      const date = new Date(maschineLeistung.value.mdate)
      return ("0" + (date.getHours())).slice(-2) + ":" + ("0" + (date.getMinutes())).slice(-2)
    })

    const summeFlaschenTag = computed(() => {
      return props.maschinenplanung?.planung.listePlanungResource.reduce((acc, curr) => {
        return acc + curr.anzahlflaschen * curr.anzahlkartons * curr.gesamtanzahl
      }, 0)
    })

    const summeFlaschenIst = computed(() => {
      return props.maschinenplanung?.planung.listePlanungResource.reduce((acc, curr) => {
        return acc + curr.anzahlflaschen * curr.anzahlkartons * curr.gesamtanzahlIst
      }, 0)
    })

    const summeFlaschenIstProzent = computed(() => {
      if (summeFlaschenIst.value == null || summeFlaschenTag.value == null || summeFlaschenTag.value == 0) {
        return 0
      }

      return summeFlaschenIst.value / summeFlaschenTag.value * 100
    })

    function getBgColor () {
      var sollLeistung90Prozent = sollLeistung.value != undefined ? sollLeistung.value * 0.9 : undefined

      if (currentProdAuftrag.value != null && sollLeistung.value != undefined && maschineLeistung.value == undefined) {
        return "bg-danger bg-opacity-50"
      }
      else if (currentProdAuftrag.value == null || sollLeistung.value == undefined || maschineLeistung.value == undefined || maschineLeistung.value.w1 == undefined) {
        return "bg-white"
      }
      else if (maschineLeistung.value != undefined && maschineLeistung.value.w1 >= sollLeistung.value) {
        return "bg-success bg-opacity-50"
      }
      else if (maschineLeistung.value != undefined && sollLeistung90Prozent != undefined && maschineLeistung.value.w1 < sollLeistung.value && maschineLeistung.value.w1 > sollLeistung90Prozent) {
        return "bg-warning bg-opacity-50"
      }
      else if (sollLeistung90Prozent != undefined && maschineLeistung.value.w1 < sollLeistung90Prozent) {
        return "bg-danger bg-opacity-50"
      }
      else {
        return "bg-white"
      }
    }

    function loadMaschinenLeistungsWerte() {
      if (props.maschine.maschinenr != null) {

        thisLoading.value = true
        store.dispatch(EnergiezaehlerActionTypes.GetMaschineLeistung, props.maschine.maschinenr)
        .then(() => {
          thisLoading.value = false
            updateDatetime.value = new Date();
        })
      }
    }

    function updateMaschinenLeistungsWerte() {
      if (props.maschine.maschinenr != null) {
        store.dispatch(EnergiezaehlerActionTypes.GetMaschineLeistung, props.maschine.maschinenr)
        .then(() => {
          updateDatetime.value = new Date();
        })
      }
    }

    const updateStatusInterval = ref();
    const updateDatetime = ref<Date>(new Date());
    const updateDatetimeConverter = computed(() => {
      return ("0" + (updateDatetime.value.getHours())).slice(-2) + ":" + ("0" + (updateDatetime.value.getMinutes())).slice(-2)
    })

    return {
      store,

      currentProdAuftrag,
      maschineLeistungDateConverter,
      sollLeistung,

      produktionStartZeitConverter,
      sollUhrzeitConverter,
      planungEndeUhrzeitConverter,
      getProgressBarColor,

      thisLoading,
      loading,
      errorMsg,
      maschineLeistung,

      summeFlaschenTag,
      summeFlaschenIst,
      summeFlaschenIstProzent,

      getBgColor,
      loadMaschinenLeistungsWerte,
      updateMaschinenLeistungsWerte,

      updateStatusInterval,
      updateDatetime,
      updateDatetimeConverter,
      
    }
  },

  mounted() {
    this.loadMaschinenLeistungsWerte()

    this.updateStatusInterval = setInterval(() => {
      this.updateMaschinenLeistungsWerte();
    }, 1 * 60 * 1000);
  },

  beforeUnmount () {
    clearInterval(this.updateStatusInterval)
  }
})
</script>

<style scoped>
.maschine-leistung-card {
  position: relative;
}

.status-anzeige {
  position: absolute;
  bottom: 0;
}

.progress {
  height: 25px;
}

.progress-bar {
  height: 25px;
}

.progress-bar-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  z-index: 1;
}

.progress-container {
  position: relative;
  width: 100%;
  height: 25px;
}
</style>